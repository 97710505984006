import React, { useEffect, useState } from "react";
import "./HomeAfterLogin.css";
import ReactSpeedometer from "react-d3-speedometer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import { FaArrowRight } from "react-icons/fa6";
import Test from "./Test";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function HomeAfterLogin() {
  const navigate = useNavigate();
  const [saveupto, setSaveUpto] = useState("");
  const [cbil, setCbil] = useState("");
  const [cbilbody, setCbilbody] = useState("");
  const [runningLoan, setRunningLoan] = useState("");
  const [LoanDetail, setLoanDetail] = useState([]);
  const [total, setTotal] = useState("");
  const [score, setScore] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (!token && !isMobileDevice) {
      navigate("/");
    }
  }, [navigate]);

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  const CreditSummary = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    // console.log("Goal Inside Credit summary api",id);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setLoanDetail(response.data.data.loanDetail);
        setSaveUpto(response.data.data.save_upto);
        setCbil(response.data.data.cbil.MsgTitle);
        setCbilbody(response.data.data.cbil.MsgBody);
        setRunningLoan(response.data.data.loanSummary.AllowedRunningLoan);
        setTotal(response.data.data.loanSummary.AllLoanCurrentBalance);
        setScore(response.data.data.cbil.score);
        
      })
      .catch(function (error) {
        console.error(error.response.status);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const moveToSave = () => {
    console.log("callled")
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    console.log("Goal Inside Credit summary api",id);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setLoanDetail(response.data.data.loanDetail);
        setSaveUpto(response.data.data.save_upto);
        setCbil(response.data.data.cbil.MsgTitle);
        setCbilbody(response.data.data.cbil.MsgBody);
        setRunningLoan(response.data.data.loanSummary.AllowedRunningLoan);
        setTotal(response.data.data.loanSummary.AllLoanCurrentBalance);
        setScore(response.data.data.cbil.score);
        navigate("/confirmloan", { state: { LoanDetail: response.data.data.loanDetail } });
      })
      .catch(function (error) {
        console.error(error.response.status);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    CreditSummary();
  }, []);
  const moveToNewpage = () => {
    navigate("/confirmloan", { state: { LoanDetail: LoanDetail } });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
      // console.log("user Login");
    }
  }, []);

  return (
    <>
    {
      cbil=="" ?
    <Skeleton count={8} style={{height:40,marginTop:20}} /> // Five-line loading skeleton
:
<>

      <div className="sections  ">
        <div style={{ marginTop: "27px", backgroundColor: "transparent" }}>
          <div style={{ backgroundColor: "transparent" }}>
            <p
              className="heading11"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "-0.02em",
                color: "#8A8A8A",
                marginBottom: "7px",
                backgroundColor: "transparent",
                marginLeft: "10px",
              }}
            >
              Awesome! here is your
            </p>
            <p
              className="heading2"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "45px",
                letterSpacing: "-0.02em",
                color: "#59266D",
                marginBottom: "25px",
                backgroundColor: "transparent",
              }}
            >
              Credit Summary
            </p>
          </div>

          <div
            className="loan-box"
            style={{
              borderRadius: "10px",
              backgroundColor: "#59266D",
              paddingTop: "10px",
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#FFFFFF",
                backgroundColor: "transparent",
                paddingRight: "50px",
                paddingLeft: "16px",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Ongoing Loans
            </div>
            <div
              className="border-box"
              style={{
                border: "0.5px solid rgba(255, 255, 255, 0.4)",
                backgroundColor: "transparent",
                marginTop: "10px",
              }}
            ></div>
            <div
              className="d-flex inner-box-content"
              style={{ backgroundColor: "transparent" }}
            >
              <div
                className="Outstanding-amount-title"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "20px",
                  paddingLeft: "16px",
                }}
              >
                Outstanding amount :<br />
                <span
                  className="Outstanding-amount"
                  style={{
                    backgroundColor: "transparent",
                    color: "#FFFFFF",
                    paddingTop: "10px",
                  }}
                >
                  ₹{formatNumberWithCommas(total)}
                </span>
              </div>
              <div
                className="inner-box-content1 Outstanding-amount-title"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "20px",
                  paddingLeft: "50px",
                  paddingRight: "70px",
                  paddingBottom: "40px",
                }}
              >
                Number of Running Loans :
                <br />
                <span
                  className="Outstanding-amount"
                  style={{
                    backgroundColor: "transparent",
                    color: "#FFFFFF",
                    paddingTop: "10px",
                    paddingLeft: "125px",
                  }}
                >
                  {formatNumberWithCommas(runningLoan)}
                </span>
              </div>
            </div>
          </div>

          <div
            className="mobile-loan-box"
            style={{
              borderRadius: "10px",
              backgroundColor: "#59266D",
              paddingBottom: "10px",
              marginTop: "10px",
              height:"auto",
            }}
          >
            <div
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#FFFFFF",
                backgroundColor: "transparent",
                paddingRight: "50px",
                paddingLeft: "16px",
                paddingBottom: "5px",
                paddingTop: "10px",
              }}
            >
              Ongoing Loans
            </div>
            <div
              style={{
                border: "0.5px solid rgba(255, 255, 255, 0.4)",
                backgroundColor: "transparent",
                marginTop: "10px",
              }}
            ></div>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  backgroundColor: "transparent",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "20px",
                  paddingLeft: "16px",
                }}
              >
                Outstanding amount :<br />
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.6)",
                  marginTop: "20px",
                  paddingLeft: "50px",
                  paddingBottom: "1px",
                  paddingRight: "16px",
                }}
              >
                Number of Running Loans :
                <br />
              </div>
            </div>
            <div
              // className="d-flex "
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ backgroundColor: "transparent" }}
              >
                <span
                  className="Outstanding-amount"
                  style={{
                    backgroundColor: "transparent",
                    color: "#FFFFFF",
                    paddingLeft: "16px",
                    fontSize: "14px",
                    fontWeight: 700,


                  }}
                >
                  ₹{formatNumberWithCommas(total)}
                </span>
              </div>
              <span
                className="Outstanding-amount"
                style={{
                  backgroundColor: "transparent",
                  color: "#FFFFFF",
                  paddingRight: "16px",
                  backgroundColor: "transparent",
                  color: "#FFFFFF",
                  paddingLeft: "16px",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {runningLoan} 
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "5%",
              marginLeft: "5%",
              marginRight: "5%",
              height:120
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "3%",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "600px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    width: "180px",
                    height: "100px",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <ReactSpeedometer
                    style={{ backgroundColor: "white",fontSize:12 }}
                    value={score}
                    maxValue={900}
                    minValue={0}
                    customSegmentStops={[0, 500, 700, 800, 900]}
                    segmentStopsFontSize="60px"
                    width={180}
                    height={100}
                    needleColor="black"
                    backgroundColor="white"
                    color="white"
                    segmentColors={[
                      "rgba(255, 88, 88, 1)",
                      " rgba(255, 159, 159, 1)",
                      "rgba(255, 199, 2, 1)",
                      "rgba(4, 218, 0, 1)",
                      "rgba(2, 90, 0, 1)",
                    ]}
                    textColor="#8A8A8A"
                    ringWidth={30}
                    needleHeightRatio={0.7}
                    currentValueText={`${score}`}
                  />
                </div>
                <div style={{backgroundColor:'transparent',alignSelf:'center',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <div
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "16.8px",
                    letterSpacing: "-0.02em",
                    textAlign: "center",
                    backgroundColor: "white",
                    color: "rgba(70, 115, 110, 1)",
                  }}
                >
                  Your Credit score is <br />
                </div>
                <span style={{backgroundColor:'transparent',fontSize:30,alignSelf:'center'  ,color: "rgba(70, 115, 110, 1)",}}>{score}</span> 
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        <p
          style={{
            fontSize: "21px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(70, 115, 110, 1)",
          }}
        >
          {cbil}
        </p>
        <p
          // className="content-news"
          style={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#1F1F1F",
            marginTop: "10px",
            marginLeft: "3%",
            marginRight: "3%",
          }}
        >
          {cbilbody}
        </p>
        <p style={{
             fontSize: "12px",
             fontWeight: "500px",
             lineHeight: "20px",
             letterSpacing: "0em",
             textAlign: "center",
             color: "#1F1F1F",
             marginTop: "10px",
             marginLeft: "3%",
             marginRight: "3%",
        }}>Report a issue with the result <Link to="https://consumer.experian.in/ECV-OLN/view/angular/#/" style={{color:'#59266D',fontWeight: "700px",}}>Here</Link></p>

        <p
          style={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "30px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "30px",
          }}
        >
          You can save up to ₹{formatNumberWithCommas(saveupto)}
        </p>

        <div className="container">
          <button
            onClick={moveToNewpage}
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              background: "rgba(89, 38, 109, 1)",
              borderRadius: "10px",
              color: "rgba(255, 255, 255, 1)",
              height:56,
              width:'92%',
           
              border: "none",
              marginTop: "10px",
              // marginBottom: "60px",
              cursor: "pointer",
            }}
          >
            Confirm Loan
          </button>
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={openModal}
          style={{
            border: "none",
            color: "rgb(89, 38, 109)",
            fontWeight: "600",
            outline: "none",
            marginBottom: "30px",
            cursor: "pointer",
          }}
        >
          Change Your Goal
        </button>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-overlay" onClick={closeModal} />
          <div className="modal-content">
            <span
              className="modal-close"
              onClick={closeModal}
              style={{ backgroundColor: "transparent", fontSize: "30px" ,color:'black'}}
            >
              &times;
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",
              }}
            >
              <div
                onClick={() => {
                  localStorage.setItem("id", 1);
                  moveToSave()
                  closeModal();
                }}
                style={{
                  margin: "0 auto",
                  display: "flex",
                }}
              >
                <div
                  className="image2-book"
                  style={{
                    width: "117px",
                    height: "94px",
                    marginTop: "22px",
                    marginBottom: "28px",
                    marginLeft: "2px",
                    backgroundColor: "transparent",
                    marginRight: "30px",
                  }}
                >
                  <img
                    src={lowerEmiIllustration}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    marginRight: "8px",
                    marginBottom: "24px",
                    gap: "5px",
                    backgroundColor: "transparent",
                    width: "203px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "21px",
                        color: "rgba(89, 38, 109, 1)",
                        backgroundColor: "transparent",
                      }}
                    >
                      Lower Your EMIs
                    </div>
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{
                        backgroundColor: "transparent",
                        marginLeft: "20px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
              </div>
             
             
              <div
                style={{ margin: "0 auto", display: "flex" }}
                onClick={() => {
                  localStorage.setItem("id", 3);
                  moveToSave()
                  closeModal();
                }}
              >
                <div
                  className="image2-book"
                  style={{
                    width: "117px",
                    height: "94px",
                    marginTop: "22px",
                    marginBottom: "28px",
                    marginLeft: "2px",
                    backgroundColor: "transparent",
                    marginRight: "30px",
                  }}
                >
                  <img
                    src={TopUPIllustration}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    marginRight: "8px",
                    marginBottom: "24px",
                    gap: "5px",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                    width: "203px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "21px",
                        color: "rgba(89, 38, 109, 1)",
                        backgroundColor: "transparent",
                      }}
                    >
                      Get top-up on loan with same Emi
                    </div>
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" ,alignSelf:'center'}}
                      onClick={() => {
                        localStorage.setItem("id", 3);
                        closeModal();
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Get extra funds easily to chase new goals and opportunity.
                  </div>
                </div>
              </div>
             
              <div
                style={{ margin: "0 auto", display: "flex" }}
                onClick={() => {
                  localStorage.setItem("id", 2);
                  moveToSave()
                  closeModal();
                }}
              >
                <div
                  className="image2-book"
                  style={{
                    width: "117px",
                    height: "94px",
                    marginTop: "22px",
                    marginBottom: "28px",
                    marginLeft: "2px",
                    backgroundColor: "transparent",
                    marginRight: "30px",
                  }}
                >
                  <img
                    src={ConsolidateIllustration}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    marginTop: "32px",
                    marginRight: "8px",
                    marginBottom: "24px",
                    gap: "5px",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                    width: "203px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "21px",
                        color: "rgba(89, 38, 109, 1)",
                        backgroundColor: "transparent",
                      }}
                    >
                      Club Multiple EMIs
                    </div>
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Review and consolidate high-interest loans and overdraft
                    accounts affecting your credit profile.
                  </div>
                </div>
              </div>

              <div
                style={{ margin: "0 auto", display: "flex" }}
              >
                <div
                  className="image2-book"
                  style={{
                    width: "117px",
                    height: "94px",
                    marginTop: "22px",
                    marginBottom: "28px",
                    marginLeft: "2px",
                    backgroundColor: "transparent",
                    marginRight: "30px",
                  }}
                >
                  <img
                    src={ConsolidateIllustration}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                    alt=""
                  />
                </div>
                <Link to="https://minemi.ai/personal-loan/check-eligibility"   style={{
                    marginTop: "32px",
                    marginRight: "8px",
                    marginBottom: "24px",
                    gap: "5px",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "transparent",
                    width: "203px",
                  }}>
                
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      gap:16,
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: "21px",
                        color: "rgba(89, 38, 109, 1)",
                        backgroundColor: "transparent",
                      }}
                    >
                     Discover Your Loan Eligiblity
                    </div>
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent"}}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Explore your loan potential with no impact on your credit score.
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          z-index: 1001;
          width: 90%;
          max-width: 400px; /* Adjust the maximum width as needed */
          max-height: 90%;
          overflow: auto;
        }
        .modal-close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }

        @media (min-width: 768px) {
          .modal-content {
            max-width: 60%;
          }
        }

        @media (min-width: 992px) {
          .modal-content {
            max-width: 50%;
          }
        }

        @media (min-width: 1200px) {
          .modal-content {
            max-width: 40%;
          }
        }
      `}</style>
      </>
    }
    </>
  );
}

export default HomeAfterLogin;
