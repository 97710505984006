import React from 'react';
import  loanaccepted from "../../Assets/Images/creditScore-image/loan accepted.webp";
import home from '../../Assets/Images/creditScore-image/HomeScreen.webp';
import lowchart from '../../Assets/Images/creditScore-image/Low Chart.webp';
import credit from '../../Assets/Images/creditScore-image/Credit Card.webp';
import Authorized from '../../Assets/Images/creditScore-image/Authorized.webp';
import CreditBuilder from '../../Assets/Images/creditScore-image/Credit Builder.webp';
import wallet from '../../Assets/Images/creditScore-image/wallet.webp';
import CompensationAndBenefits from '../../Assets/Images/creditScore-image/Compensation And Benefits.webp';
import Vector from '../../Assets/Images/creditScore-image/Vector.webp'
import Layer2 from '../../Assets/Images/creditScore-image/Layer 2.webp'
import tablerclock from '../../Assets/Images/creditScore-image/tabler_clock.webp'
import stashchartpie from '../../Assets/Images/creditScore-image/stash_chart-pie.webp'
import SearchFile from '../../Assets/Images/creditScore-image/Search File.webp'
import check from '../../Assets/Images/creditScore-image/check.webp'
import "./CreditScore.css"

const CreditScore = () => {
  return (
<>
    {/* 1  */}
     <div className=" Check-credit " >
         <div className=' Check-credit-heading ' style={{backgroundColor:'#FAF6FF'}}>
            <h1>Check your credit score for free!</h1>
            <p style={{fontSize:20,lineHeight:1.3,fontWeight:'400'}}>Discover smart financial solutions tailored to your needs. Whether it's balance transfers, loan consolidation, or flexible top-ups, we've got you covered.</p>
             <button className='button'>
             <a
             style={{color:"white"}}
              href="/login?goal=lower-your-emi"
              onClick={() => {
                localStorage.setItem("id", 1);
                window.scrollTo(0, 0);
              }}
            >Check Credit Score
            </a></button>
         </div>
         <div style={{backgroundColor:'#FAF6FF'}}>
           <img src={home} alt="Company Logo" className="img-fluid"/>
          </div>
     </div>

    {/* 2  */}
      <div className="container-Score" style={{backgroundColor:'#FFFFFF'}} > 
         <div className="col-12"   style={{backgroundColor:'#FFFFFF'}}>
           <h1 className=" Score-heading" style={{backgroundColor:'#FFFFFF'}}>What is a Credit Score?</h1>
           <p className=" Score-paragraph " style={{backgroundColor:'#FFFFFF'}}>A credit score is a three-digit number that represents your creditworthiness. In India, credit scores range from 300 to 900, with higher scores<br /> indicating better creditworthiness. The Credit Information Bureau (India) Limited (CIBIL) is one of the primary credit bureaus in India that<br /> calculates these scores.</p>
          </div>
      </div>
        
     {/* 3  */}
     <div className="container-good-credit" style={{backgroundColor:'#FFFFFF'}}>
    <div className="col-12" style={{backgroundColor:'#FFFFFF'}}>
    <div className='' style={{backgroundColor:'#FFFFFF'}}>
    <h1 className="good-credit-heading" style={{backgroundColor:'#FFFFFF'}}>Why is a Good Credit Score Important?</h1>
      <p className="good-credit-paragraph mt-2" style={{backgroundColor:'#FFFFFF'}}>
        A good credit score, typically above 750, can lead to:
      </p>  
    </div>
      <div className="good-credits mt-4" style={{backgroundColor:'#FFFFFF'}}>
           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
            <img 
              src={loanaccepted} 
              alt="Loan Approval Icon" 
              style={{ maxHeight: '32px', width: '32px',backgroundColor:'white' }} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:"center"}}>
            <p style={{backgroundColor:'#FFFFFF',fontWeight:'400',fontSize:18,lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF',fontWeight:'700',fontSize:18}}>Easier Loan Approvals:</strong> Lenders are more likely to approve loans for individuals with high credit scores.</p>
          </div>
          </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
            <img 
              src={lowchart} 
              alt="Lower Interest Rates Icon" 
              style={{ maxHeight: '32px', width: '32px',backgroundColor:'white' }} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:"center"}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:18,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Lower Interest Rates:</strong> Borrowers with excellent credit scores often receive loans at lower interest rates.</p>
          </div>
          </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
            <img 
              src={CompensationAndBenefits} 
              alt="Higher Credit Limit Icon" 
              style={{ maxHeight: '32px', width: '32px' ,backgroundColor:'white'}} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:"center"}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:18,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Higher Credit Limits:</strong> Credit card issuers may offer higher credit limits to individuals with strong credit histories.</p>
          </div>
           </div>
      </div>
    </div>
  </div>

    {/* 4 */}
   <div className='Credit-History' style={{backgroundColor:'#FAF6FF'}}>
    <div className='credit-heading' style={{backgroundColor:'#FAF6FF'}}>
      <h1>Building a <span style={{color:'#59266D',backgroundColor:'#FAF6FF'}}>Credit Score</span> with No Credit History</h1>
      <p style={{backgroundColor:'#FAF6FF',fontWeight:'400',fontSize:18}}>If you have no credit history, consider the following steps to build your credit score:</p>
    </div>
    <div className='credit-cards' style={{backgroundColor:'#FAF6FF'}}>
                <div style={{ display: 'flex',backgroundColor:'#FAF6FF' }}>
                       <img src={credit} alt="Credit Builder Loan" style={{ maxHeight: '48px', width: '48px', marginRight: '8px' }} />
                    <div style={{backgroundColor:'#FAF6FF'}}>
                        <h1 style={{fontWeight:'700',fontSize:'18px',lineHeight:'28px',backgroundColor:'#FAF6FF'}}>Apply for a Secured Credit Card:</h1>
                          <p style={{fontWeight:'400',fontSize:'16px',lineHeight:'20px',color:'#8A8A8A',backgroundColor:'#FAF6FF'}}>
                             These cards are backed by a fixed deposit and can help establish credit history.
                          </p>
                     </div>
                  </div>
                  <div style={{ display: 'flex',backgroundColor:'#FAF6FF' }}>
                      <img src={Authorized} alt="Credit Builder Loan" style={{ maxHeight: '48px', width: '48px', marginRight: '8px' }} />
                     <div>
                        <h1 style={{fontWeight:'700',fontSize:'18px',lineHeight:'28px',backgroundColor:'#FAF6FF'}}>Become an Authorized User:</h1>
                        <p style={{fontWeight:'400',fontSize:'16px',lineHeight:'20px',color:'#8A8A8A',backgroundColor:'#FAF6FF'}}>
                            Being added to someone else's credit card account can help you build credit.
                        </p>
                      </div>
                  </div>
                  <div style={{ display: 'flex' ,backgroundColor:'#FAF6FF'}}>
                      <img src={CreditBuilder} alt="Credit Builder Loan" style={{ maxHeight: '48px', width: '48px', marginRight: '8px' }} />
                   <div>
                       <h1 style={{fontWeight:'700',fontSize:'18px',lineHeight:'28px',backgroundColor:'#FAF6FF'}}>Take a Credit-Builder Loan:</h1>
                       <p style={{fontWeight:'400',fontSize:'16px',lineHeight:'20px',color:'#8A8A8A',backgroundColor:'#FAF6FF'}}>
                        Some financial institutions offer loans specifically designed to help build credit.
                      </p>
                  </div>
                  </div>
                  <div style={{ display: 'flex',backgroundColor:'#FAF6FF' }}>
                     <img src={wallet} alt="Credit Builder Loan" style={{ maxHeight: '48px', width: '48px', marginRight: '8px' }} />
                   <div>
                     <h1 style={{fontWeight:'700',fontSize:'18px',lineHeight:'28px',backgroundColor:'#FAF6FF'}}>Use Credit Responsibly:</h1>
                      <p style={{fontWeight:'400',fontSize:'16px',lineHeight:'20px',color:'#8A8A8A',backgroundColor:'#FAF6FF'}}>
                      Make small purchases and pay off the balance in full each month to establish a positive payment history.
                      </p>
                   </div>
                  </div>
    </div>
   </div>

    {/* 5 */}
    <div className="container-good-credit" style={{backgroundColor:'#FFFFFF'}}>
    <div className="col-12" style={{backgroundColor:'#FFFFFF'}}>
    <div className=''style={{backgroundColor:'#FFFFFF'}}>
    <h1 className="good-credit-heading" style={{backgroundColor:'#FFFFFF'}}>Factors Affecting Your Credit Score</h1>
      <p className="good-credit-paragraph mt-2" style={{backgroundColor:'#FFFFFF',fontWeight:'400',fontSize:18}}>
      Several factors influence your credit score:
      </p>  
    </div>
      <div className="good-credits mt-3" style={{backgroundColor:'#FFFFFF'}}>
           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div  style={{backgroundColor:'#FFFFFF'}}>
          <img 
              src={Vector} 
              alt="Loan Approval Icon" 
              style={{ maxHeight: '32px', width: '32px', marginRight: '8px',backgroundColor:'white' }} 
            />

          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:"center"}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:16,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Payment History:</strong> Timely payments boost your score, while missed or late payments can significantly lower it.</p>
          </div>
          </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
          <img 
              src={Layer2} 
              alt="Lower Interest Rates Icon" 
              style={{ maxHeight: '32px', width: '32px', marginRight: '8px',backgroundColor:'#FFFFFF' }} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:'center'}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:16,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Credit Utilization Ratio:</strong> This is the ratio of your current credit card balances to your credit limits. Maintaining a low ratio (preferably below 30%) is beneficial.</p>
          </div>
          </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
          <img 
              src={tablerclock} 
              alt="Higher Credit Limit Icon" 
              style={{ maxHeight: '32px', width: '32px', marginRight: '8px',backgroundColor:'#FFFFFF' }} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:'center'}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:16,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Length of Credit History:</strong> A longer credit history provides more data on your financial behavior, which can positively impact your score.</p>
          </div>
           </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
          <img 
              src={stashchartpie} 
              alt="Lower Interest Rates Icon" 
              style={{ maxHeight: '32px', width: '32px', marginRight: '8px' ,backgroundColor:'#FFFFFF'}} 
            />
          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:'center'}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:18,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>Credit Mix:</strong> A diverse mix of credit accounts, such as credit cards, mortgages, and personal loans, can favorably affect your score.</p>
          </div>
           </div>

           <div className="good-credit-card" style={{backgroundColor:'#FFFFFF'}}>
          <div style={{backgroundColor:'#FFFFFF'}}>
          <img  
              src={SearchFile} 
              alt="Lower Interest Rates Icon" 
              style={{ maxHeight: '32px', width: '32px', marginRight: '8px',backgroundColor:'#FFFFFF' }} 
            />

          </div>
          <div style={{backgroundColor:'#FFFFFF',alignSelf:'center'}}>
            <p style={{backgroundColor:'#FFFFFF',fontSize:18,fontWeight:'400',lineHeight:1.2}}><strong style={{backgroundColor:'#FFFFFF'}}>New Credit Inquiries:</strong> Frequent applications for new credit can be seen as risky behavior and may lower your score.</p>
          </div>
           </div>

      </div>
    </div>
  </div>

   {/* 6 */}
   <div className="container-fluid Improve"  style={{backgroundColor:'#FAF6FF'}}>
      <div className="Improve-Score" style={{backgroundColor:'#FAF6FF'}}>
        <h1 style={{backgroundColor:'#FAF6FF',fontWeight:'700'}}>Tips to Improve and Maintain Your Credit Score</h1>
      </div>
      <div>
        <ul className="list-unstyled" style={{backgroundColor:'#FAF6FF'}}>
          <li className="d-flex  mb-4" style={{backgroundColor:'#FAF6FF'}}>
            <img src={check} alt="Checkmark" className="check-icon me-2"  style={{backgroundColor:'#FAF6FF'}}/>
            <p className="mb-0 Improve-Score-card" style={{backgroundColor:'#FAF6FF'}}>
              <span style={{fontWeight: '700',fontSize:18,backgroundColor:'#FAF6FF' }}>Pay Bills on Time:</span> Consistently paying your bills by the due date is crucial.
            </p>
          </li>
          <li className="d-flex  mb-4" style={{backgroundColor:'#FAF6FF'}}>
            <img src={check} alt="Checkmark" className="check-icon me-2" style={{backgroundColor:'#FAF6FF'}} />
            <p className="mb-0 Improve-Score-card" style={{backgroundColor:'#FAF6FF'}}>
              <span style={{ fontWeight: '700',fontSize:18,backgroundColor:'#FAF6FF'  }}>Reduce Outstanding Debt:</span> Aim to pay off existing debts to lower your credit utilization ratio.
            </p>
          </li>
          <li className="d-flex  mb-4"style={{backgroundColor:'#FAF6FF'}}>
            <img src={check} alt="Checkmark" className="check-icon me-2"style={{backgroundColor:'#FAF6FF'}} />
            <p className="mb-0 Improve-Score-card"style={{backgroundColor:'#FAF6FF'}}>
              <span style={{ fontWeight: '700',fontSize:18,backgroundColor:'#FAF6FF'  }}>Limit New Credit Applications:</span> Apply for new credit only when necessary to avoid multiple hard inquiries.
            </p>
          </li>
          <li className="d-flex  mb-4" style={{backgroundColor:'#FAF6FF'}}>
            <img src={check} alt="Checkmark" className="check-icon me-2"style={{backgroundColor:'#FAF6FF'}} />
            <p className="mb-0 Improve-Score-card"style={{backgroundColor:'#FAF6FF'}}>
              <span style={{ fontWeight: '700',fontSize:18,backgroundColor:'#FAF6FF'  }}>Maintain Old Credit Accounts:</span> Keeping older accounts open can lengthen your credit history, which is beneficial.
            </p>
          </li>
          <li className="d-flex  mb-4"style={{backgroundColor:'#FAF6FF'}}>
            <img src={check} alt="Checkmark" className="check-icon me-2"style={{backgroundColor:'#FAF6FF'}} />
            <p className="mb-0 Improve-Score-card"style={{backgroundColor:'#FAF6FF'}}>
              <span style={{fontWeight: '700',fontSize:18,backgroundColor:'#FAF6FF'  }}>Monitor Your Credit Report:</span> Regularly review your credit report to identify and dispute any inaccuracies.
            </p>
          </li>
        </ul>
      </div>
    </div>
     
</>
  );
}

export default CreditScore;