import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const TypesOfGoldLoan = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Explore Different Types of Gold Loans | Compare & Apply",
      pageDescription:
        "Learn about secured, unsecured, overdraft-based, and EMI-based gold loans. Choose the best gold loan type to match your financial requirements.",
      pageKeywords:
        "Types of gold loans, secured gold loans, unsecured gold loans, overdraft gold loans, gold loan eligibility, gold loan benefits, gold loan features",
      canonicalUrl: "https://minemi.ai/loanpage/gold-loan/types-of-gold-loan",
    });
  }, [setMetadata]);
  return (
    <>
      <Helmet>
        <title>Explore Different Types of Gold Loans | Compare & Apply</title>
        <meta
          name="description"
          content="Learn about secured, unsecured, overdraft-based, and EMI-based gold loans. Choose the best gold loan type to match your financial requirements."
        />
        <meta
          name="keywords"
          content="Types of gold loans, secured gold loans, unsecured gold loans, overdraft gold loans, gold loan eligibility, gold loan benefits, gold loan features"
        />
        {/* <link rel="icon" type="image/png" href="../mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/gold-loan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Gold Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Types of Gold Loans
            </Link>{" "}
          </p>
        </div>

        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Explore the Different Types of Gold Loans for Your Financial Needs
        </h1>

        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "24px",
          }}
        >
          Compare loan options based on flexibility, repayment, and features to
          find the perfect gold loan for your unique requirements.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
        <div
          style={{
            marginTop: 40,
          }}
        >
          <h3 style={{ color: "#1F1F1F", fontSize: 24 }}> Introduction</h3>
        </div>

        <div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px ",
            }}
          >
            Gold loans are a versatile financial product, catering to varied
            needs with multiple repayment and borrowing options. Understanding
            the types of gold loans available is essential for selecting the
            right one. From secured loans backed by gold jewelry to flexible
            overdraft facilities, there’s a solution for everyone.
          </p>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Whether you are looking for short-term liquidity, business funding,
            or personal needs, this guide highlights the types of gold loans
            available and their unique benefits.
          </p>
        </div>

        <div
          style={{
            marginTop: 20,
          }}
        >
          <h3 style={{ color: "#1F1F1F", fontSize: 24 }}>
            Types of Gold Loans
          </h3>
        </div>

        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 18,
            }}
          >
            1. Overdraft Gold Loans
          </h3>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Overview:{" "}
                </span>
                Borrow against your gold as needed, paying interest only on the
                utilized amount.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Who Should Opt For This?:{" "}
                </span>
                Perfect for business owners managing cash flow fluctuations.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Benefits:{" "}
                </span>
                <ul className="pt-2">
                  <li className="pt-2">Interest savings</li>
                  <li className="pt-2">Flexible withdrawal and repayment</li>
                </ul>
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#1F1F1F",
              fontWeight: 500,
              fontSize: 16,
              fontFamily: "Archivo",
            }}
          >
            For More Information{" "}
            <span
              onClick={() => {
                navigate(
                  "/loanpage/gold-loan/types-of-gold-loangold-loan-overdraft"
                );
              }}
              style={{
                color: "#1675E0",
                fontWeight: 500,
                fontSize: 16,
                fontFamily: "Archivo",
                cursor: "pointer",
              }}
            >
              Click Here
            </span>{" "}
          </p>
        </div>
        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 18,
            }}
          >
            2. EMI-Based Gold Loans
          </h3>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Overview:{" "}
                </span>
                Borrowers repay the loan in fixed monthly installments, covering
                both principal and interest.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Who Should Opt For This?:{" "}
                </span>
                Ideal for salaried professionals with consistent income.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Benefits:{" "}
                </span>
                <ul className="pt-2">
                  <li className="pt-2">Predictable repayment schedule</li>
                  <li className="pt-2">Convenient budgeting</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 18,
            }}
          >
            3. Bullet Repayment Gold Loans
          </h3>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Overview:{" "}
                </span>
                Repay the entire principal and interest at the end of the
                tenure.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Who Should Opt For This?:{" "}
                </span>
                Best for individuals expecting a lump sum at a later date.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Benefits:{" "}
                </span>
                <ul className="pt-2">
                  <li className="pt-2">No monthly repayment burden</li>
                  <li className="pt-2">Ideal for short-term loans</li>
                </ul>
              </div>
            </div>
          </div>
          <p
            style={{
              color: "#1F1F1F",
              fontWeight: 500,
              fontSize: 16,
              fontFamily: "Archivo",
            }}
          >
            For More Information{" "}
            <span
              onClick={() => {
                navigate(
                  "/loanpage/gold-loan/types-of-gold-loan/gold-loan-bullet-repayment"
                );
              }}
              style={{
                color: "#1675E0",
                fontWeight: 500,
                fontSize: 16,
                fontFamily: "Archivo",
                cursor: "pointer",
              }}
            >
              Click Here
            </span>{" "}
          </p>
        </div>

        {/* minemi tip section */}
        {/* <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
            <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="d-flex gap-2 align-items-center "
              >
                <img
                  src="../images/tongue-out.png"
                  alt="minemi tip image"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "transparent",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  Use Minemi’s home loan eligibility calculator to determine
                  your maximum borrowing capacity.
                </Typography>
              </div>
            </div>
          </div>
        </div> */}

        {/* table data */}
        <div id="types-of-bt">
          <div id="eligibility-criteria">
            <div
              className=" pb-2 "
              style={{
                marginTop: 40,
              }}
            >
              <h3 style={{ color: "#1F1F1F", fontSize: 24 }}>
                Comparison Table: Types of Gold Loans
              </h3>
            </div>
            <div
              className=" pb-2 "
              style={{
                color: "#1F1F1F",
              }}
            ></div>
          </div>

          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Loan Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Collateral
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Interest Rate
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Repayment Flexibility
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Best For
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Secured Gold Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Yes
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Low
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High loan amount needs
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Unsecured Gold Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    No
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Moderate
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    No collateral availability
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Overdraft Gold Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Yes
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Low-to-Moderate
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Business owners
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    EMI-Based Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Yes
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Moderate
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Low
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried professionals
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Bullet Repayment
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Yes
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Low-to-Moderate
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Seasonal cash flows
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              Can I switch from one gold loan type to another?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              No, you need to decide the type of gold loan at the time of
              application.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Which gold loan type has the lowest interest rate?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Secured gold loans generally offer the lowest interest rates.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can I avail a bullet repayment gold loan for a long tenure?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Bullet repayment loans are generally available for short tenures,
              up to 12 months.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 64,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  backgroundColor: "#553285",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypesOfGoldLoan;
