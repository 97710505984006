import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import HowItWorks from "./HowItWorks";
import OurServices from "./OurServices";
import DownloadApp from "./DownloadApp";
import { useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    token == null && navigate("/");
    // localStorage.clear();
  }, []);

  return (
    <>
      {/* <Helmet>
        <title>
          Minemi.ai: Check Loan Eligibility, Compare Rates & Save on EMIs
        </title>
        <meta
          name="description"
          content="Check loan eligibility based on your credit score, compare interest rates, and find the best loans with affordable EMIs. Start saving with Minemi.ai today!"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet> */}
      <HeroSection />
      <HowItWorks />
      <OurServices />
      <DownloadApp />
    </>
  );
};

export default Home;
