import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Bill from "../../Assets/Images/Bill Get.webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import { useNavigate } from "react-router-dom";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper"; // Assuming Item is a Paper component
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../MetadataContext";

const Carloan = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMediumScreen = useMediaQuery("(max-width:756px)");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  const [manageData, setManageData] = useState([]);
  const blogs = [
    {
      id: 1,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/CarLoanBalanceTransfer-1737198077001.webp",
      title: "Car Loan Balance Transfer EMI Calculator",
      writer: "Vikalp Shukla",
      description:
        "Quickly calculate a loan payment and see a payoff schedule.",
      link: "https://minemi.ai/calculators/car-loan-balance-transfer-calculator",
    },
    {
      id: 2,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar1-1736255187108.webp",
      title: "10 Things to Know Before Applying for a Car Loan",
      writer: "Vikalp Shukla",
      description:
        "A guide to understanding car loan basics, from interest rates to hidden charges.",
      naviLink:
        "/loanpage/carloan/10-things-to-know-before-applying-for-a-car-loan",
    },
    {
      id: 3,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar2-1737198460334.webp",
      title:
        "Should You Choose a Fixed or Floating Interest Rate for Your Car Loan?",
      writer: "Aditi Malhotra",
      description:
        "An in-depth comparison to help buyers decide between these options.",
      naviLink:
        "/loanpage/carloan/should-you-choose-a-fixed-or-floating-interest-rate-for-your-car-loan",
    },
    {
      id: 4,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar3-1737199027443.webp",
      title:
        "Down Payment Strategies: How Much Should You Pay Upfront for Your Car Loan?",
      writer: "Vikalp Shukla",
      description: "Tips on maximizing savings and reducing loan EMIs.",
      naviLink: "/loanpage/carloan/down-payment-strategies",
    },
    {
      id: 5,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar4-1737198825700.webp",
      title: "Top 5 Factors That Affect Your Car Loan Eligibility",
      writer: "Aditi Malhotra",
      description:
        "An informative article on what lenders consider before approving a car loan.",
      naviLink:
        "/loanpage/carloan/top-5-factors-that-affect-your-car-loan-eligibility",
    },
    {
      id: 6,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar5-1737199137791.webp",
      title: "Comparing Car Loan Offers: How to Choose the Best Deal",
      writer: "Vikalp Shukla",
      description:
        "A comprehensive guide to comparing interest rates, fees, and repayment terms.",
      naviLink: "/loanpage/carloan/comparing-car-loan-offers",
    },
    {
      id: 7,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar6-1737199255986.webp",
      title: "Understanding the Impact of Credit Scores on Car Loan Approval",
      writer: "Aditi Malhotra",
      description:
        "A detailed explanation of how credit scores influence loan terms.",
      naviLink:
        "/loanpage/carloan/understanding-the-impact-of-credit-scores-on-car-loan-approval",
    },
    {
      id: 8,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Ar7-1736255451633.webp",
      title: "Pre-Owned vs. New Car Loans: Which Option is Right for You?",
      writer: "Vikalp Shukla",
      description: "A comparison of loan options for new and used car buyers.",
      naviLink: "/loanpage/carloan/pre-owned-vs-new-car-loans",
    },
  ];
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.latestnews);

        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.manageloan);
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Affordable Car Loans & Finance Deals | Minemi",
      pageDescription:
        "Minemi brings you the best car loan deals with low-interest rates. Compare top banks in India and find the perfect car finance option today",
      pageKeywords:
        "best car loan deals, car loan offers, top banks in India, car loan rates, affordable car loans, car loan comparison, low interest car loans, vehicle loan options, car finance deals, car loan eligibility",
      canonicalUrl: "https://minemi.ai/loanpage/carloan",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Affordable Car Loans & Finance Deals | Minemi</title>
        <meta
          name="description"
          content="Minemi brings you the best car loan deals with low-interest rates. Compare top banks in India and find the perfect car finance option today"
        />
        <meta
          name="keywords"
          content="best car loan deals, car loan offers, top banks in India, car loan rates, affordable car loans, car loan comparison, low interest car loans, vehicle loan options, car finance deals, car loan eligibility"
        />
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Car Loan
            </Link>
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Get the Best Car Loan Deals from Top Banks in India{" "}
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 24,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "30px",
          }}
        >
          Compare car loan offers, check your eligibility, and calculate EMIs to
          get the most suitable loan for buying your dream car or a used car.
        </p>
      </div>
      <div
        className="make-wrap"
        style={{
          marginTop: 56,
          marginLeft: "4%",
          marginRight: "4%",
          display: "flex",
          flexDirection: "row",
          justifyContent: isMediumScreen ? "center" : "flex-start",
          gap: 16,
        }}
      >
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 10,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/loanpage/carloan/car-loan-eligibility-criteria");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Car Loan Check Eligibility
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Borrow limits by credit score and income.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={money}
                alt="Check Eligibility"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Check your eligibility for car loans instantly with top lenders.
            </p>
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/loanpage/carloan/car-loan-compare-offers");
          }}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Compare Car Loan Offers
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Compare car loan offers from top banks
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Ratio}
                alt="Compare loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Compare rates and processing fees to find the best deal.
            </p>
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/loanpage/carloan/car-loan-top-up");
          }}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Car Loan <br className="d-none d-sm-block" /> Top-Up
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Increase your car loan amount with ease.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Group}
                alt="Increase Car Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Explore top-up options on your current car loan from leading
              lenders.
            </p>
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/loanpage/carloan/used-car-loan");
          }}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Used Car Loans
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Get the best deals on used car loans.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={creditScore}
                alt="Best Deals"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Used car loan? Compare top banks for competitive rates.
            </p>
          </p>
        </div>
        <div
          onClick={() => {
            navigate("/loanpage/carloan/car-loan-status-tracking");
          }}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Loan Status Tracking
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Track the status of your car loan application.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Vector}
                alt="Track Status"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Check your car loan application status with our easy tracker.
            </p>
          </p>
        </div>
        <div
          onClick={() => {
            window.location.href =
              "https://minemi.ai/calculators/car-loan-emi-calculator";
          }}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Car Loan <br className="d-none d-sm-block" /> EMI Calculator
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Calculate your monthly EMI.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Bill}
                alt="Emi"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              {" "}
              Use our car loan calculator to estimate monthly repayments.
            </p>
          </p>
        </div>
      </div>
      <div
        style={{
          maginTop: 64,
          marginLeft: "4%",
          marginRight: "4%",
          marginTop: 32,
        }}
      >
        <h2
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1.2,
          }}
        >
          Qualify for a Car Loan
        </h2>
      </div>
      <div
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
        className="loan-list-container px-0"
      >
        {blogs?.map((blog) => (
          <div
            className="loan-container"
            onClick={() => navigate(blog.naviLink)}
            key={blog.id}
          >
            <a href={blog.link}>
              <img
                src={blog.image}
                alt={blog.heading}
                loading="lazy"
                className="loan-image d-none d-md-block"
              />
              <h3
                className="loan-heading"
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 600,
                  fontSize: 18,
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              >
                {blog.title}
              </h3>
              <p
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  color: "#8a8a8a",
                  fontSize: 14,
                }}
                className="mt-2 mb-1"
              >
                by {blog.writer}
              </p>
              <div
                className="loan-subheading"
                dangerouslySetInnerHTML={{
                  __html: blog.description,
                }}
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#B1B1B1",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              />
            </a>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          Manage your loan
        </h3>
        <div style={{ marginTop: 40, marginBottom: 32 }}>
          <Grid container spacing={5}>
            {manageData
              .filter((d) => d.category === "Car Loan")
              .slice(0, 1) // Adjust slice range based on your needs
              .map((d) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Item style={{ border: 0, boxShadow: "0px 0px" }}>
                      <Link
                        to={`/loanpage/carloan/manageloan_detail/${d.mslug}`}
                        state={{
                          mslug: d?.mslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                          }}
                        >
                          <div style={{ height: "auto" }}>
                            <img
                              src={d.image}
                              alt={d?.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: isVerySmallScreen ? "100%" : "400px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            <p
                              className="manage_latest_link"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d?.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d?.author}
                            </p>

                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: 1.3,
                                color: "grey",
                                display: "-webkit-box",
                                WebkitLineClamp: 2, // Limit to 2 lines
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden", // Hide overflowing text
                                textOverflow: "ellipsis",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: d?.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </Link>
                    </Item>
                  </Grid>
                );
              })}

            <Grid item xs={12} md={6}>
              <Item
                style={{
                  border: 0,
                  boxShadow: "0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 32,
                  backgroundColor: "#F6F6F6",
                }}
              >
                {manageData
                  .filter((d) => d.category === "Car Loan")
                  .slice(1)
                  .map((d) => (
                    <div key={d.id}>
                      {d.category === "Car Loan" && (
                        <Link
                          to={`/loanpage/carloan/manageloan_detail/${d.mslug}`}
                          state={{
                            mslug: d.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 12,
                            }}
                          >
                            <div
                              style={{
                                // width: "100%",
                                minWidth: isVerySmallScreen
                                  ? 80
                                  : isMediumScreen
                                  ? 120
                                  : 150,
                                maxWidth: 190,
                              }}
                            >
                              <img
                                src={d.image}
                                alt={d.title}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  // maxWidth: "250px",
                                  height: isVerySmallScreen
                                    ? "50px"
                                    : isMediumScreen
                                    ? "80px"
                                    : "110px",
                                  objectFit: "cover",

                                  borderRadius: "5px",
                                  objectPosition: "center",
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 3,
                              }}
                            >
                              <p
                                className="manage_latest_link m-0 pt-0 pt-sm-3"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d.author}
                              </p>
                              {/* <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    d?.description && d.description.length > 90
                                      ? `${d.description.slice(0, 90)}...`
                                      : d?.description || "",
                                }}
                              ></p> */}
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 40 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          The Latest
        </h3>
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
          {newsData
            .filter((item) => item.category === "Car Loan")
            .map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                <Item style={{ boxShadow: "0px 0px", marginBottom: 24 }}>
                  <Link
                    to={`/loanpage/carloan/news_detail/${item.nslug}`}
                    state={{
                      nslug: item.nslug,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 12,
                        alignItems: "start",
                      }}
                    >
                      <div
                        style={{
                          // width: "100%",
                          minWidth: isVerySmallScreen
                            ? 80
                            : isMediumScreen
                            ? 120
                            : 150,
                          maxWidth: 190,
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.title}
                          loading="lazy"
                          style={{
                            width: "100%",
                            // maxWidth: "250px",
                            height: isVerySmallScreen
                              ? "50px"
                              : isMediumScreen
                              ? "80px"
                              : "110px",
                            objectFit: "cover",

                            borderRadius: "5px",
                            objectPosition: "center",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                          alignSelf: "center",
                        }}
                      >
                        <p
                          style={{ fontSize: 18, fontWeight: 400 }}
                          className="manage_latest_link m-0"
                        >
                          {item.title}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "grey",
                          }}
                          className="m-0"
                        >
                          {" "}
                          by {item.author}
                        </p>
                        {/* <p
                          style={{
                            fontFamily: "Archivo",
                            fontWeight: 400,
                            fontSize: 14,
                            marginBottom: 0,
                            color: "grey",
                          }}
                          className="m-0"
                          dangerouslySetInnerHTML={{
                            __html:
                              item.description.length > 100
                                ? `${item.description.slice(0, 100)}...`
                                : item.description,
                          }}
                        ></p> */}
                      </div>
                    </div>
                  </Link>
                </Item>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default Carloan;
