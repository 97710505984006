import React from "react";
// import { Sliders, Percent, Zap, HeadphonesIcon } from "your-icon-library";
import {
  LuSlidersVertical,
  LuPercent,
  LuZap,
  LuHeadphones,
} from "react-icons/lu";
import FeatureCard from "./FeatureCard";
function FeaturesSection() {
  return (
    <section className="py-16 bg-white w-100">
      <h2
        className="text-3xl font-bold text-center mb-12  bg-white"
        style={{
          fontSize: "30px",
          lineHeight: "36px",
          fontWeight: "700",
          color: "#59266D",
        }}
      >
        Why MinEMI is Your Trusted EMI Partner
      </h2>
      <div className="container-fluid mx-auto px-4 bg-white ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12  bg-white">
          <FeatureCard
            icon={
              <LuSlidersVertical
                className="w-10 h-10"
                style={{ backgroundColor: "transparent" }}
              />
            }
            title="Customized EMI Plans"
            description="Tailored options to fit your budget and financial goals."
          />
          <FeatureCard
            icon={
              <LuPercent
                className="w-10 h-10"
                style={{ backgroundColor: "transparent" }}
              />
            }
            title="Lower Interest Rates"
            description="We help you find competitive rates to save more on repayments."
          />
          <FeatureCard
            icon={
              <LuZap
                className="w-10 h-10"
                style={{ backgroundColor: "transparent" }}
              />
            }
            title="Fast and Hassle-Free Process"
            description="Get recommendations and solutions without lengthy paperwork."
          />
          <FeatureCard
            icon={
              <LuHeadphones
                className="w-10 h-10"
                style={{ backgroundColor: "transparent" }}
              />
            }
            title="Expert Support 24/7"
            description="Our team is here anytime to guide you through lowering your EMI."
          />
        </div>
      </div>
      <div className="text-center  bg-white">
        <button className="bg-[#59266d] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors">
          Check Your Savings
        </button>
      </div>
    </section>
  );
}

export default FeaturesSection;
