import React, { useState, useEffect, useContext } from "react";
import illustration from "../../../Assets/Images/Illustration22.webp";
import { LuDot } from "react-icons/lu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import "./GoldLoanEligibilityCriteria.css";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Link } from "react-router-dom";
import toungeOut from "../../../Assets/Images/tongue-out.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

// import Breadcrumbs from "./Breadcrumbs";

const GoldLoanEligibilityCriteria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState(0);
  const [foir, setFOIR] = useState(0);
  const navigate = useNavigate();

  const calculateLoanEligibility = () => {
    // Parse inputs
    const income = parseFloat(monthlyIncome);
    const obligations = parseFloat(existingObligations);
    const tenure = parseInt(loanTenure, 10);
    const interestRate = parseFloat(annualInterestRate);

    if (!income || !obligations || !tenure || !interestRate) {
      alert("Please fill in all fields with valid numbers.");
      return;
    }

    // Calculate FOIR
    let calculatedFOIR = obligations / income; // Use `let` if the value might change
    setFOIR(calculatedFOIR);

    if (calculatedFOIR >= 0.5) {
      alert("Loan eligibility is low due to high FOIR.");
      setEligibility(0);
      return;
    }

    // Calculate Net Income Available for EMI
    const netIncomeAvailable = income - obligations;

    // Maximum EMI Allowed
    const maxEMI = income * (1 - calculatedFOIR);

    // Monthly Interest Rate
    const monthlyInterestRate = interestRate / 12 / 100;

    // Loan Principal Eligibility
    const calculatedEligibility =
      (maxEMI * (Math.pow(1 + monthlyInterestRate, tenure) - 1)) /
      (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenure));

    setEligibility(calculatedEligibility);
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Gold Loan Check Eligibility",
      pageDescription:
        "Gold loans offer quick access to funds by using your gold's value. Understanding your eligibility can ease the loan approval process and maximize your gold's benefits.",
      pageKeywords:
        "gold loan eligibility, check gold loan eligibility, gold loan approval, gold loan criteria, quick gold loans, gold loan benefits, gold value loan, gold-backed loans, loan against gold, gold loan process",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/gold-loan-eligibility-criteria",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Gold Loan Check Eligibility</title>
        <meta
          name="description"
          content="Gold loans offer quick access to funds by using your gold's value. Understanding your eligibility can ease the loan approval process and maximize your gold's benefits."
        />
        <meta
          name="keywords"
          content="gold loan eligibility, check gold loan eligibility, gold loan approval, gold loan criteria, quick gold loans, gold loan benefits, gold value loan, gold-backed loans, loan against gold, gold loan process"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              to={"/loanpage/gold-loan"}
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Gold Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Gold Loan Check Eligibility
            </Link>
          </p>
        </div>
        <h1
          className="loanstatus-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Gold Loan Check Eligibility: Unlock the Value of Your Gold
        </h1>
        <p
          className="loanstatus-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Gold loans offer a quick and hassle-free way to access funds by
          leveraging the value of your gold. Understanding your eligibility
          ensures a smoother loan approval process and helps you maximize the
          benefits of your gold assets.
        </p>
      </div>
      <div
        className="tracking-status"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
      >
        {/* eligibility content */}
        <h2
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction
        </h2>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Understanding your eligibility is the first step in getting a car
          loan. By checking your eligibility, you can determine the loan amount
          you qualify for, the terms, and the interest rates that will be
          offered. Car loan eligibility criteria differ from lender to lender
          and can be based on various factors, including your income, age,
          employment, and credit score.
        </div>

        <div
          style={{
            marginTop: 30,
          }}
        >
          <h3
            style={{
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            How to Check Gold Loan Eligibility
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Checking your gold loan eligibility involves assessing key factors,
            such as the purity and weight of the gold, the applicant’s financial
            profile, and lender-specific requirements. Here’s what you need to
            know:
          </div>
          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ol>
              <li className="mt-1">
                <strong>Loan Amount Eligibility: </strong>Determined by the
                weight and purity of your gold.
              </li>
              <li className="mt-1">
                <strong>Income Assessment: </strong>Most lenders do not require
                proof of income but assess repayment capacity.
              </li>
              <li className="mt-1">
                <strong>Credit Score: </strong>A good credit score enhances loan
                approval chances.
              </li>
              <li className="mt-1">
                <strong>Eligibility by Age: </strong>Typically, applicants must
                be aged between 18 and 70 years.
              </li>
            </ol>
          </div>
        </div>

        {/* <div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            1. FOIR Based Eligibility
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>FOIR</strong>, or{" "}
              <strong>Fixed Obligation to Income Ratio</strong>, is a measure
              used by banks to assess how much of your monthly income goes
              toward paying off existing debts. This ratio helps the bank
              understand whether you have sufficient income left to manage the
              new car loan EMI without overburdening your finances.
            </p>
          </div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Formula for FOIR:
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              FOIR=Total Monthly Liabilities (EMIs)Gross Monthly Income×100\text
              &#123;FOIR&#125; = \frac &#123;\text &#123; Total Monthly
              Liabilities (EMIs)&#125;&#125; &#123;\text &#123;Gross Monthly
              Income&#125;&#125; \times 100{" "}
            </p>
          </div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            1. FOIR Based Eligibility
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>FOIR</strong>, or{" "}
              <strong>Fixed Obligation to Income Ratio</strong>, is a measure
              used by banks to assess how much of your monthly income goes
              toward paying off existing debts. This ratio helps the bank
              understand whether you have sufficient income left to manage the
              new car loan EMI without overburdening your finances.
            </p>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}
                >
                  Stay Informed:{" "}
                </span>
                Knowing the status of your loan ensures you're up to date with
                every stage of your loan process.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}
                >
                  Avoid Missed Payments:{" "}
                </span>
                Timely updates can help you avoid missing EMI payments, which
                can hurt your credit score.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}
                >
                  Monitor Loan Disbursement:{" "}
                </span>
                Stay on track with when the loan will be disbursed to you for
                purchasing your car.
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Track Car Loan Status?
          <p style={{ fontSize: "16px", paddingTop: 10 }}>
            Tracking your car loan status is simple and can be done through
            several channels:
          </p>
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                Online Portals:{" "}
              </span>
              Many banks offer customer portals where you can track your loan
              status by logging into your account.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                Bank Mobile Apps:{" "}
              </span>
              Most financial institutions provide dedicated mobile apps for
              their customers to check the status of their loan applications.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                Customer Service:{" "}
              </span>
              You can always call the customer support team to check your car
              loan status.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
                SMS Updates:{" "}
              </span>
              Some banks send SMS alerts with the status updates of your loan
              application, approval, and disbursement.
            </div>
          </div>
        </div> */}

        <div id="table-data" className="mt-5">
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for Gold Loans
          </p>

          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul>
              <li className="mt-1">
                <strong>Loan Amount Eligibility: </strong>Determined by the
                weight and purity of your gold.
              </li>
              <li className="mt-1">
                <strong>Income Assessment: </strong>Most lenders do not require
                proof of income but assess repayment capacity.
              </li>
              <li className="mt-1">
                <strong>Credit Score: </strong>A good credit score enhances loan
                approval chances.
              </li>
              <li className="mt-1">
                <strong>Eligibility by Age: </strong>Typically, applicants must
                be aged between 18 and 70 years.
              </li>
            </ul>
          </div>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Parameter
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Impact on Loan Eligibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Minimum: 18 years; Maximum: 70 years
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Older applicants may need a co-applicant.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Gold Purity
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Minimum 18 karats
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Higher purity (22K and above) fetches a better loan amount.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Gold Type
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Ornaments, coins (up to 50 grams per RBI rules); bars not
                    accepted
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Limited types of gold are eligible for loans.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Loan-to-Value Ratio (LTV)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 75% of the gold’s market value, as per RBI guidelines
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Lower LTV may be offered based on risk assessment.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Not mandatory but 700+ is preferred
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                      borderBottomRightRadius: 18,
                    }}
                  >
                    Higher credit scores ensure better terms.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Income Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Not mandatory for most lenders
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                      borderBottomRightRadius: 18,
                    }}
                  >
                    Proof of stable income improves loan amount eligibility.
                  </td>
                </tr>
                {/* <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 }}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 ,borderBottomLeftRadius:24 }}>Tenure



</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          1 to 5 years

</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,borderBottomRightRadius:24  }}>	
	
          Flexible tenure options available.</td>
        </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="make-it-center increase-margin "
          style={{
            marginTop: "2%",
            marginBottom: "2%",
            padding: 24,
            background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
            borderRadius: 24,
          }}
        >
          <div
            style={{
              marginLeft: "4%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              gap: 24,
              borderRadius: 24,
              alignSelf: "center",
            }}
          >
            <p
              className="eligiblity-criteria"
              style={{
                fontFamily: "Archivo",
                color: "#59266D",
                fontSize: 40,
                backgroundColor: "transparent",
              }}
            >
              <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                Check Your Loan Eligibility Without{" "}
                <br className="brake-remove" /> Impacting Your Credit Score
              </i>
            </p>
            <a
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="eligiblity-buttons"
              style={{
                backgroundColor: "#553285",
                color: "#FFFFFF",
                width: "39%",
                borderRadius: 8,
                fontFamily: "Archivo",
                fontWeight: 500,
                fontSize: 18,
                paddingTop: 8,
                paddingBottom: 8,
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Check Eligibility
            </a>
          </div>
          <div
            style={{
              width: 200,
              height: 195,
              backgroundColor: "transparent",
              marginRight: "4%",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src={illustration}
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              loading="lazy"
              alt="eigibility"
            />
          </div>
        </div>

        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          Eligibility Criteria by Lender
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          HDFC Bank
        </p>

        <div
          className="mt-2"
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          <ul>
            <li className="mt-1">
              <strong>Age Requirement: </strong>21-65 years
            </li>
            <li className="mt-1">
              <strong>Gold Purity: </strong>22K or higher ornaments accepted.
            </li>
            <li className="mt-1">
              <strong>Documents Required: </strong>ID proof, address proof, and
              basic gold appraisal.
            </li>
          </ul>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          AXIS Bank
        </p>

        <div
          className="mt-2"
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          <ul>
            <li className="mt-1">
              <strong>Age Requirement: </strong>18-70 years
            </li>
            <li className="mt-1">
              <strong>Gold Accepted: </strong>Jewelry only (coins and bars not
              accepted).
            </li>
            <li className="mt-1">
              <strong>Income Proof: </strong>Generally not required.
            </li>
          </ul>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          SBI Bank
        </p>

        <div
          className="mt-2"
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          <ul>
            <li className="mt-1">
              <strong>Age Requirement: </strong>18-65 years
            </li>
            <li className="mt-1">
              <strong>Maximum Loan Amount: </strong>Up to ₹50 lakh.
            </li>
            <li className="mt-1">
              <strong>Repayment Tenure: </strong>Flexible options of up to 36
              months.
            </li>
          </ul>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          ICICI Bank
        </p>

        <div
          className="mt-2"
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          <ul>
            <li className="mt-1">
              <strong>Age Requirement: </strong>21-65 years
            </li>
            <li className="mt-1">
              <strong>LTV Ratio: </strong>Up to 75% of the gold’s value.
            </li>
            <li className="mt-1">
              <strong>Additional Perks: </strong>Pre-approved offers for
              existing customers.
            </li>
          </ul>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Understanding Eligibility for Gold Loans{" "}
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          1. Gold Purity and Value:
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Lenders assess the purity of your gold using a karat meter and
          calculate the loan amount based on the prevailing market rate.
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 16,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Example:
        </p>

        <div
          className="mt-2"
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          <ul>
            <li className="mt-1">
              <strong>Gold weight: </strong>100 grams
            </li>
            <li className="mt-1">
              <strong>Purity: </strong>22 karats
            </li>
            <li className="mt-1">
              <strong>Market rate (per gram): </strong>₹5,500
            </li>
            <li className="mt-1">
              <strong>Loan amount: </strong>₹5,500 × 100 × 75% = ₹4,12,500
            </li>
          </ul>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          2. Income Stability:
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          While most gold loans don’t require income proof, demonstrating stable
          income may allow you to negotiate better interest rates or repayment
          terms.
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          3. Repayment Capacity:
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Some lenders may check your repayment history, particularly if you’re
          applying for a high loan amount.
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 18,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          4. Credit History:
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Though not mandatory, a higher credit score improves your bargaining
          power for better interest rates and lower processing fees.
        </p>

        {/* minemi tip section */}
        <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
            <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="d-flex gap-2 align-items-center "
              >
                <img
                  src={toungeOut}
                  alt="minemi tip image"
                  loading="lazy"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "transparent",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  Unlock your gold’s full potential with MinEmi’s Gold Loan
                  Eligibility Checker. Assess your eligibility without impacting
                  your credit score and get tailored loan options instantly.
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Improve Your Gold Loan Eligibility
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Use High-Purity Gold: &nbsp;
              </span>
              Ensure your gold ornaments or coins are at least 22K to maximize
              the loan amount.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Provide Collateral in Good Condition: &nbsp;
              </span>
              Avoid damaged or broken jewelry to ensure smooth appraisal.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                {" "}
                Maintain a Good Credit Score:&nbsp;
              </span>
              While not mandatory, a score above 700 can help secure better
              interest rates.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Reduce Existing Debt: &nbsp;
              </span>
              Pay off any high-interest obligations to improve your eligibility.
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              Can I apply for a gold loan without a credit score?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, most lenders prioritize gold value over credit history.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              What is the minimum weight of gold required for a loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Typically, lenders require a minimum of 10 grams of gold.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Are there any restrictions on the type of gold?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, only gold jewelry and specific coins (up to 50 grams) are
              eligible.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can I get a gold loan if I am self-employed?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, gold loans are available to salaried, self-employed, and
              unemployed individuals alike.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default GoldLoanEligibilityCriteria;
