import React, { useState, useEffect, useContext } from "react";
import illustration from "../../../Assets/Images/Illustration22.webp";
import axis from "../../../Assets/Images/axis.webp";
import sbi from "../../../Assets/Images/sbi.webp";
import icici from "../../../Assets/Images/icici.webp";
import indus from "../../../Assets/Images/indusind.webp";
import tongueOut from "../../../Assets/Images/tongue-out.png";
import { LuDot } from "react-icons/lu";
import img from "../../../Assets/Images/image 48.webp";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import "./CarLoanEligibilityCriteria.css";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../../MetadataContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { Accordion } from "react-bootstrap";
// import Breadcrumbs from "./Breadcrumbs";

const CarLoanEligibilityCriteria = () => {
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState("");
  const [isMoreCriteria, setIsMoreCriteria] = useState(false);

  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery("(max-width:1080px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const calculateLoanEligibility = () => {
    const P = monthlyIncome;
    const r = annualInterestRate;
    const n = loanTenure;

    // Parse inputs
    const foir = 0.5;
    const affordableEMI = P * foir - existingObligations;
    if (affordableEMI <= 0) {
      setEligibility("Not Eligible for Loan");
      return;
    }

    const monthlyRate = r / 12 / 100;

    const loanEligibility =
      (affordableEMI * (Math.pow(1 + monthlyRate, n) - 1)) /
      (monthlyRate * Math.pow(1 + monthlyRate, n));

    setEligibility(
      isNaN(loanEligibility) || loanEligibility <= 0
        ? "Not Eligible for Loan"
        : Math.min(Math.round(loanEligibility), 5000000)
    );
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN");
  };

  const handleAmountChange = (e, setter) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = Number(value); // Convert to number
      if (numericValue <= 5000000) {
        // Restrict to maximum 50,00,000
        setter(numericValue);
      }
    }
  };

  const mainContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "30px" : "90px",
  };
  const tableContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "20px" : "55px",
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Car Loan Check Eligibility",
      pageDescription:
        "Find out how much you can borrow from top lenders without affecting your credit score. Use our car loan calculator for personalized results.",
      pageKeywords:
        "car loan eligibility, check car loan eligibility, car loan requirements, loan eligibility check, vehicle loan approval, car loan qualification, car finance eligibility, easy car loan approval, loan eligibility criteria, car loan eligibility calculator",
      canonicalUrl:
        "https://minemi.ai/loanpage/carloan/car-loan-eligibility-criteria",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Car Loan Check Eligibility</title>
        <meta
          name="description"
          content="Find out how much you can borrow from top lenders without affecting your credit score. Use our car loan calculator for personalized results."
        />
        <meta
          name="keywords"
          content="car loan eligibility, check car loan eligibility, car loan requirements, loan eligibility check, vehicle loan approval, car loan qualification, car finance eligibility, easy car loan approval, loan eligibility criteria, car loan eligibility calculator"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/carloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Car Loan
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Car Loan Check Eligibility
            </Link>{" "}
          </p>
        </div>
        <h1
          className="loanstatus-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Car Loan Check Eligibility
        </h1>
        <p
          className="loanstatus-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "20px",
          }}
        >
          See how much you can borrow from top lenders without hurting your
          credit score. Use our car loan calculator to get personalized results
          based on your income and credit history.{" "}
        </p>
      </div>
      <div
        className="tracking-status"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
      >
        {/* eligibility content */}
        <h2
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Introduction:
        </h2>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Understanding your eligibility is the first step in getting a car
          loan. By checking your eligibility, you can determine the loan amount
          you qualify for, the terms, and the interest rates that will be
          offered. Car loan eligibility criteria differ from lender to lender
          and can be based on various factors, including your income, age,
          employment, and credit score.
        </div>

        <div id="types-of-car-loan-eligibility">
          <h3
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Types of Car Loan Eligibility Criteria
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Banks and financial institutions use different methods to assess
            your eligibility for a car loan. The two most commonly used methods
            are:
          </div>
          <div className="mt-2">
            <ol>
              <li className="pb-2">
                <strong>FOIR-Based Eligibility</strong>
              </li>
              <li>
                <strong>Multiplier-Based Eligibility</strong>
              </li>
            </ol>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            1. FOIR Based Eligibility
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              <strong>FOIR</strong>, or{" "}
              <strong>Fixed Obligation to Income Ratio</strong>, is a measure
              used by banks to assess how much of your monthly income goes
              toward paying off existing debts. This ratio helps the bank
              understand whether you have sufficient income left to manage the
              new car loan EMI without overburdening your finances.
            </p>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Formula for FOIR:
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              FOIR=Total Monthly Liabilities (EMIs)Gross Monthly Income×100\text
              &#123;FOIR&#125; = \frac &#123;\text &#123; Total Monthly
              Liabilities (EMIs)&#125;&#125; &#123;\text &#123;Gross Monthly
              Income&#125;&#125; \times 100{" "}
            </p>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Example:
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              Let’s say your monthly income is ₹50,000, and you have EMIs of
              ₹15,000 for a personal loan. The calculation for your FOIR would
              be:
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              FOIR=15,00050,000×100=30%\text &#123;FOIR &#125; = \frac
              &#123;15,000 &#125; &#123;50,000 &#125; \times 100 = 30\%
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              This means 30% of your income goes toward your current
              liabilities, which is generally considered good. The ideal FOIR
              should be 40% or lower for loan approval. A higher FOIR indicates
              that you may have limited capacity to take on a new loan.
            </p>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 18,
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              What Banks Look For:
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Ideal FOIR:{" "}
                </span>
                40%–50% is considered safe.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Maximum FOIR:{" "}
                </span>
                Banks may accept up to 60%, but additional documents may be
                required.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Impact on Loan Eligibility:{" "}
                </span>
                A lower FOIR increases the chances of securing a higher loan
                amount with favorable terms.
              </div>
            </div>
          </div>
        </div>

        {/* check eligibility */}
        <div
          className="make-it-center increase-margin "
          style={{
            marginTop: "2%",
            marginBottom: "2%",
            padding: 24,
            background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
            borderRadius: 24,
          }}
        >
          <div
            style={{
              marginLeft: "4%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              gap: 24,
              borderRadius: 24,
              alignSelf: "center",
            }}
          >
            <p
              className="eligiblity-criteria"
              style={{
                fontFamily: "Archivo",
                color: "#59266D",
                fontSize: 40,
                backgroundColor: "transparent",
              }}
            >
              <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                Check Your Loan Eligibility Without{" "}
                <br className="brake-remove" /> Impacting Your Credit Score
              </i>
            </p>
            <a
              href="https://minemi.ai/car-loan/check-eligibility/"
              className="eligiblity-buttons"
              style={{
                backgroundColor: "#553285",
                color: "#FFFFFF",
                width: "39%",
                borderRadius: 8,
                fontFamily: "Archivo",
                fontWeight: 500,
                fontSize: 18,
                paddingTop: 8,
                paddingBottom: 8,
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Check Eligibility
            </a>
          </div>
          <div
            style={{
              width: 200,
              height: 195,
              backgroundColor: "transparent",
              marginRight: "4%",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration22-1737194188768.webp"
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              alt="eligibility"
            />
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            2. Multiplier-Based Eligibility
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              The <strong>Multiplier-Based Eligibility</strong> method is
              commonly used in India to determine the loan amount you can
              qualify for based on your monthly income. Banks typically offer a
              car loan amount by multiplying your monthly income by a fixed
              multiplier. This approach is simpler and directly related to your
              income.
            </p>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            How It Works:
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              The bank uses a multiplier to calculate your loan eligibility. The
              multiplier is often between 1.5x to 3x of your monthly income. The
              exact multiplier depends on the lender and other factors like your
              credit score and employment stability.
            </p>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            Example:
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              Let’s assume the bank applies a 2.5 multiplier. If your monthly
              income is ₹50,000, then:
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              Loan Eligibility=50,000×2.5=₹1,25,000\text &123;Loan
              Eligibility&125; = 50,000 \times 2.5 = ₹1,25,000
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
                lineHeight: "24px",
              }}
            >
              This means the bank will offer you a loan amount of ₹1,25,000,
              based on the multiplier.
            </p>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 18,
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              Factors Affecting the Multiplier:
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Income Level:{" "}
                </span>
                40%–50% is considered safe.A higher monthly income generally
                results in a higher loan eligibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Credit Score:{" "}
                </span>
                A higher credit score increases your loan eligibility and may
                allow a higher multiplier.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Job Stability:{" "}
                </span>
                Lenders often favor salaried individuals with stable employment.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Car Type:{" "}
                </span>
                The type of car (new or used) can affect the loan amount, as new
                cars are considered lower-risk assets.
              </div>
            </div>
          </div>
        </div>

        <div id="table-data" className="mt-5">
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for Car Loans{" "}
          </p>
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: 16,
              lineHeight: "20px",
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Eligibility criteria can vary depending on the bank or financial
            institution, but some common factors include:
          </p>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Eligibility Criteria
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    What Banks Look For
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Impact on Loan Eligibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Applicants aged 21 to 60 years are eligible
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Applicants outside this range may face challenges in
                    approval.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Steady monthly income (₹15,000 – ₹25,000 for salaried)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Higher income increases loan eligibility and the loan
                    amount.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Employment
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried or self-employed individuals
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Stable job/business increases the chances of approval.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Typically, a score of 700 or higher is preferred
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    A higher credit score lowers interest rates and improves
                    eligibility.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Existing Liabilities (FOIR)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Lower existing loan obligations
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                      borderBottomRightRadius: 18,
                    }}
                  >
                    A low FOIR (below 50%) increases chances of loan approval.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      borderBottomLeftRadius: 24,
                    }}
                  >
                    Car Type
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    New cars generally have higher loan eligibility
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    Used car loans may have slightly lower eligibility.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h3
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          How to Improve Your Car Loan Eligibility
          <p
            style={{
              marginTop: 15,
              fontSize: "16px",
              lineHeight: "20px",
              fontFamily: "'Archivo',sans-serif",
            }}
          >
            If your eligibility doesn’t meet the required criteria, you can take
            steps to improve your chances of securing a loan. Here are some
            tips:
          </p>
        </h3>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                Increase Your Income:{" "}
              </span>
              If possible, increase your income through side jobs or additional
              work to improve your loan eligibility.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                Improve Your Credit Score:{" "}
              </span>
              Check your credit report, resolve any discrepancies, and pay off
              existing loans to improve your score.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                Lower Your Existing Liabilities:{" "}
              </span>
              Reduce your current debts to lower your FOIR and free up capacity
              for your car loan EMI.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                Provide a Higher Down Payment:{" "}
              </span>
              Offering a larger down payment reduces the loan amount and makes
              you a more attractive borrower.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                Apply with a Co-Applicant:{" "}
              </span>
              Adding a co-applicant with a good credit score and stable income
              can improve your eligibility.
            </div>
          </div>
        </div>

        {/* minemi tip section */}
        <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
            <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="d-flex gap-2 align-items-center "
              >
                <img
                  src={tongueOut}
                  alt="minemi tip image"
                  loading="lazy"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "transparent",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  "Want to improve your chances of approval? Pay off existing
                  loans to reduce your FOIR and increase your down payment to
                  reduce the loan amount."
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "3%",
          }}
        >
          Eligibility Criteria by Lender
        </p>

        <div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 24,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 14,
              marginLeft: "1%",
              paddingBottom: 0,
            }}
          >
            Eligibility Criteria
          </p>
          <div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/image%2048-1736403678444.webp"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    alt="hdfc"
                    loading="lazy"
                  />
                </div>
                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      {" "}
                      Up to 5 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/carloan/car-loan-eligibility-criteria/hdfc-car-loan-eligibility"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                HDFC Loan Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/axis-1736403836726.webp"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    alt="axis"
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      720
                    </p>
                  </div>

                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹20,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/carloan/car-loan-eligibility-criteria/axis-car-loan-eligibility"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                AXIS Loan Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/sbi-1736403898225.webp"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                    alt="sbi"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      up to 5 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/carloan/car-loan-eligibility-criteria/sbi-car-loan-eligibility"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                SBI Loan Eligibility
              </div>
            </div>
            <div
              className="main-box"
              style={{
                backgroundColor: "#FFFFFF",
                // borderBottom: "1px solid #D9D9D9",
                paddingBottom: 15,
              }}
            >
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/icici-1736403726941.webp"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    alt="icici"
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      680
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 6 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/carloan/car-loan-eligibility-criteria/icici-car-loan-eligibility"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                ICICI Loan Eligibility
              </div>
            </div>
            <div
              className="main-box"
              style={{
                backgroundColor: "#FFFFFF",
                // borderBottom: "1px solid #D9D9D9",
                paddingBottom: 15,
              }}
            >
              {/* <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={indus}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>

                 <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      680
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 6 years
                    </p>
                  </div>
                </div> 
              </div> */}
              {/* <div
                onClick={() => navigate("/indusind-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                IndusInd Loan Eligibility
              </div> */}
            </div>
            {/* <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: 5,
                fontFamily: "Archivo",
                fontWeight: 600,
                color: "#1F1F1F",
                fontSize: 18,
              }}
            >
              Load More..
            </div> */}
          </div>
        </div>

        <h3
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Use Our Eligibility Calculator to Get Personalized Results
        </h3>
        <div
          style={{
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          If you don’t meet the eligibility criteria or want to increase your
          chances of approval, here are some tips:
        </div>
        <div
          className="main-inner-box-eligiblity increase-margin "
          style={{
            marginTop: "2%",
            backgroundColor: "white",
            borderRadius: 24,
            border: "2px solid #846CA6",
            borderRight: 0,
          }}
        >
          <div
            className=" loan-criteria-box "
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // flexWrap: "wrap",
              backgroundColor: "white",
              borderRadius: 24,
              gap: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                backgroundColor: "white",
                margin: 32,
                width: "40%",
              }}
              className="box-eligiblity-input-field"
            >
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  ₹
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Monthly Income"
                  style={{
                    width: "100%",
                    border: "0px solid white",
                    padding: "0 0 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={formatCurrency(monthlyIncome)} // Display value with commas
                  onChange={(e) => handleAmountChange(e, setMonthlyIncome)}
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  ₹
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Existing Monthly Obligations"
                  style={{
                    width: "100%",
                    border: "0px solid white",
                    padding: "0 0 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={formatCurrency(existingObligations)} // Display value with commas
                  onChange={(e) =>
                    handleAmountChange(e, setExistingObligations)
                  }
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#FFFFFF",
                  position: "relative",
                  padding: "15px",
                }}
              >
                <input
                  className="input-fiedl-inner"
                  placeholder="Desired Loan Tenure"
                  style={{
                    width: "90%",
                    border: "0px solid white",
                    padding: "0 15px 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={loanTenure}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      !isNaN(value) &&
                      Number.isInteger(+value) &&
                      value >= 0
                    ) {
                      setLoanTenure(value);
                    }
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  months
                </span>
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#FFFFFF",
                  position: "relative",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  %
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Interest Rate"
                  style={{
                    width: "90%",
                    border: "0px solid white",
                    padding: "0 0 0 20px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={annualInterestRate}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value >= 0 && value <= 99) {
                      setAnnualInterestRate(value);
                    } else if (value > 99) {
                      setAnnualInterestRate(99); // Optionally set it to 99 if the user exceeds the limit
                    } else if (value === 0) {
                      setAnnualInterestRate(0); // Optionally set it to 99 if the user exceeds the limit
                    }
                  }}
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#59266D",
                  color: "white",
                  height: 48,
                  fontFamily: "Archivo",
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "20px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => calculateLoanEligibility()}
              >
                Submit
              </div>
            </div>
            <div
              className="box-eligiblity-loan-amount"
              style={{
                width: "50%",
                padding: 62,
                backgroundColor: "#846CA6",
                margin: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    fontFamily: "Archivo",
                    fontWeight: 700,
                    fontSize: 32,
                  }}
                >
                  Eligible Loan Amount
                </div>
                <div
                  className="eligible-loan-amount-box-button"
                  style={{
                    width: 400,
                    height: 80,
                    borderRadius: 16,
                    color: "#59266D",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontWeight: 700,
                    fontSize: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isNaN(eligibility) || eligibility === "₹0" ? (
                    <span
                      style={{
                        fontSize: "24px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Not Eligible for Loan
                    </span>
                  ) : (
                    currencyFormatter.format(eligibility)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Check Your Eligibility Now
        </h3>
        <div
          style={{
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Ensure a smooth application process with MinEmi! Check your
          eligibility for a car loan today, and make informed decisions without
          impacting your credit score.
        </div>

        <div id="faq-section">
          <h3
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              backgroundColor="#F6F6F6"
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: 12,
                  backgroundColor: "#F6F6F6",
                  fontWeight: 700,
                }}
              >
                What is the ideal FOIR for a car loan?
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
                The ideal FOIR for car loan eligibility is 40-50%. A higher FOIR
                may affect your chances of approval.
              </AccordionDetails>
            </Accordion>
            <Accordion
              backgroundColor="#F6F6F6"
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: 12,
                  fontWeight: 700,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can a lower income affect my car loan eligibility?
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
                Yes, a lower income can reduce the loan amount you’re eligible
                for. However, increasing your down payment and improving your
                credit score can help.
              </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: 12,
                  fontWeight: 700,
                }}
              >
                What documents do I need to provide for a car loan?
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
                Documents typically required include proof of identity, income,
                address, and car-related documents (invoice, RC).
              </AccordionDetails>
              <AccordionActions></AccordionActions>
            </Accordion>
            <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  backgroundColor: "#F6F6F6",
                  borderRadius: 12,
                  fontWeight: 700,
                }}
              >
                How can I check my car loan eligibility?
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
                You can use the car loan eligibility calculators on various bank
                websites or consult with a financial advisor for a detailed
                evaluation.
              </AccordionDetails>
              <AccordionActions></AccordionActions>
            </Accordion>
          </div>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: "4%",
            marginBottom: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <a
              href="https://minemi.ai/car-loan/check-eligibility"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 24,
                backgroundColor: "transparent",
                marginTop: "3%",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  backgroundColor: "#553285",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 44,
                  paddingRight: 44,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                 Apply Now
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarLoanEligibilityCriteria;
