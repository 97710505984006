import React, { useState, useEffect } from "react";
import { FaLinkedin, FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import "./FooterUpdated.css";
import Logo from "../../Assets/Images/Logo.webp";
import facebook from "../../Assets/Images/facebook.webp";
import instagram from "../../Assets/Images/instagram.webp";
import feedback from "../../Assets/Images/feedback.webp";
import x from "../../Assets/Images/x.webp";
import "./Footer.css";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BorderAllTwoTone } from "@mui/icons-material";

const FooterUpdated = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  // const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState("");
  const [indicator, setIndicator] = useState(false);
  const location = useLocation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleChange = (e) => {
    setError(false);
    const inputText = e.target.value;
    setMessage(inputText);
    if (inputText.length <= 1000) {
      setMessage(inputText);
    }
  };
  const Contactus = () => {
    if (message === "") {
      setError(true);
    } else {
      setIndicator(true);
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SECRET_KEY}/submit/contactForm`,
        data: { message: message },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          toast(response.data.message);
          setIndicator(false);
          setMessage("");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const [token, setToken] = useState(null);

  const fetchToken = () => {
    const savedToken = localStorage.getItem("token");
    // console.log("token in footer", savedToken);
    setToken(savedToken);
  };

  useEffect(() => {
    CompanyDetail();
    fetchToken();
  }, []);
  // if (token) {
  //   return null;
  // }
  // console.log(location.pathname);
  const pathsToExclude = ["/login", "/", "/otp", "/faq"];

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3 style={{ color: "black" }}>
            <a style={{ color: "black" }} href="https://minemi.ai/calculators/">
              Financial Calculators
            </a>
          </h3>
          <ul>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/personal-loan-emi-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Personal Loan EMI Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/home-loan-emi-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Home Loan EMI Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/home-loan-emi-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Car Loan EMI Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/personal-loan-eligibility-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Personal Loan Eligibility Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/car-loan-eligibility-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Car Loan Eligibility Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/debt-consolidation-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Debt Consolidation Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/refinancing-personal-loan/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Personal Loan Refinance Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/refinancing-car-loan/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Car Loan Refinance Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/personal-loan-balance-transfer-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Personal Loan Balance Transfer EMI Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/home-loan-balance-transfer-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Home Loan Balance Transfer EMI Calculator
              </a>
            </li>
            <li className="p-0 m-0">
              <a
                href="https://minemi.ai/calculators/car-loan-balance-transfer-calculator/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Car Loan Balance Transfer EMI Calculator
              </a>
            </li>

            {/* <li className="p-0 m-0">
              <a href="/calculators/emi">EMI Calculator</a>
            </li>
            <li>
              <a href="/tools/credit-score">Credit Score Improvement</a>
            </li> */}
          </ul>
        </div>

        <div className="footer-section">
          <h3 style={{ color: "black" }}>Smart Finance Planner</h3>
          <ul>
            <li>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Personal Loan Eligibility Check
              </a>
            </li>
            <li>
              <a
                href="https://minemi.ai/home-loan/check-eligibility/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Home Loan Eligibility Check
              </a>
            </li>
            <li>
              <a
                href="https://minemi.ai/car-loan/check-eligibility/"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Car Loan Eligibility Check
              </a>
            </li>
            <li>
              <a
                href="/login?goal=lower-your-emi"
                // target="_blank"
                onClick={() => {
                  console.log("Link clicked");
                  localStorage.setItem("id", 1);
                  window.scrollTo(0, 0);
                }}
                className="menu-link menu-link_us-s"
              >
                Lower My EMI
              </a>
            </li>
            <li>
              <a
                href="/login?goal=loan-consolidate"
                // target="_blank"
                onClick={() => {
                  console.log("Link clicked");
                  localStorage.setItem("id", 2);
                  window.scrollTo(0, 0);
                }}
                className="menu-link menu-link_us-s"
              >
                Consolidate My EMI
              </a>
            </li>
            <li>
              <a
                href="/login?goal=loan-top-up"
                // target="_blank"
                onClick={() => {
                  console.log("Link clicked");
                  localStorage.setItem("id", 3);
                  window.scrollTo(0, 0);
                }}
                className="menu-link menu-link_us-s"
              >
                Apply For Top Up
              </a>
            </li>
            <li>
              <a
                href="/credit-score"
                // target="_blank"
                className="menu-link menu-link_us-s"
              >
                Check Credit Score
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3 style={{ color: "black" }}>Quick Links</h3>
          <ul>
            <li>
              <Link
                to="/aboutus"
                onClick={scrollToTop}
                className="menu-link menu-link_us-s"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                onClick={scrollToTop}
                className="menu-link menu-link_us-s"
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                to="/privacypolicy"
                onClick={scrollToTop}
                className="menu-link menu-link_us-s"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms&condition"
                onClick={scrollToTop}
                className="menu-link menu-link_us-s"
              >
                Terms and Conditions
              </Link>
            </li>
            {/* <li>
              <a href="/grievance-redressal">Grievance Redressal</a>
            </li>
            <li>
              <a href="/sitemap">Sitemap</a>
            </li> */}
          </ul>
        </div>

        <div className="footer-section">
          <h3 style={{ color: "black" }}>Contact Us</h3>
          <ul>
            <li>
              <Link
                className="menu-link subitem-1-item"
                style={{ display: "flex" }}
              >
                <span>
                  <FiPhone
                    width="18px"
                    height="18px"
                    style={{ alignItems: "center" }}
                  />
                </span>
                <span className="menu-link_us-s" style={{ paddingLeft: "8px" }}>
                  {htmlContent.contact_no_text}
                </span>
              </Link>
            </li>
            <li>
              <Link className="menu-link " style={{ display: "flex" }}>
                <span style={{ alignItems: "center", alignSelf: "center" }}>
                  <MdOutlineEmail
                    width="18px"
                    height="14px"
                    style={{ alignItems: "center", alignSelf: "center" }}
                  />
                </span>
                <span
                  className="email-1 menu-link_us-s"
                  style={{ paddingLeft: "8px" }}
                >
                  {htmlContent.email_text}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/GrievanceAddressal"
                onClick={scrollToTop}
                className="menu-link "
                style={{ display: "flex" }}
              >
                <span
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    width: 20,
                    height: 20,
                  }}
                >
                  <img
                    src={feedback}
                    alt="grievance addressal"
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 0.5,
                    }}
                  />
                </span>
                <span
                  className="email-1 menu-link_us-s"
                  style={{ paddingLeft: "8px" }}
                >
                  Grievance Addressal
                </span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3 style={{ color: "black" }}>Minemi App</h3>
          <div className="app-coming-soon">
            <p style={{ backgroundColor: "transparent" }}>
              Stay tuned for the Minemi app – launching soon!
            </p>
          </div>

          <h3 style={{ color: "black" }} className="mt-5 pt-2">
            Social Media
          </h3>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/minemi/">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/minemi_app/">
              <FaInstagram />
            </a>
            {/* <Link to={htmlContent.twitter_link} target="_blank">
              <FaTwitter />
            </Link> */}
            <a href="https://www.facebook.com/profile.php?id=61566050084012">
              <FaFacebook />
            </a>
          </div>
        </div>

        {/* <div className="footer-section">
          <h3 style={{ color: "black" }}>Certifications</h3>
          <div className="certifications">
            <img src="/images/ssl-secure.png" alt="Secured by SSL" />
            <img src="/images/pci-dss.png" alt="PCI DSS Compliant" />
          </div>
        </div> */}
      </div>

      {/* <div className="extended-content">
        <div className="extended-content-container">
          <h2 style={{ color: "black" }}>About Minemi</h2>
          <p>
            Minemi is your trusted partner for managing loans effectively. We
            specialize in balance transfers, loan consolidation, and top-up
            loans, helping you save on EMIs and achieve financial freedom.
          </p>

          <h2 style={{ color: "black" }}>Personal Loans</h2>
          <p>
            Find the best personal loan options based on your credit score.
            Minemi ensures you get the lowest interest rates with minimal
            effort.
          </p>

          <h2 style={{ color: "black" }}>Balance Transfer</h2>
          <p>
            Save money by transferring high-interest loans to lenders offering
            better terms. Check your eligibility instantly and start saving
            today.
          </p>

          <h2 style={{ color: "black" }}>Top-Up Loans</h2>
          <p>
            Get additional funds on your existing loans with Minemi's top-up
            loan solutions. Enjoy longer tenures and lower interest rates.
          </p>

          <h2 style={{ color: "black" }}>Credit Improvement Tools</h2>
          <p>
            Use Minemi's tools to track your credit score and improve your
            financial profile. Take control of your credit journey with
            actionable insights.
          </p>

          <h2 style={{ color: "black" }}>Check Credit Score</h2>
          <p>
            Use Minemi's tools to fetch and understand your credit score and see
            how you can improve your credit profile.
          </p>
        </div>
      </div> */}
      <div className="d-flex justify-content-center">
        <p>&copy; 2025 MinEmi. All rights reserved.</p>
        <br />
        <br />
      </div>
      <div className="horizontal_line" />
      <div className="extended-content">
        <div className="extended-content-container mt-3">
          <h3>About Minemi</h3>
          <p className="mt-0">
            Minemi simplifies your financial journey by offering smart solutions
            for balance transfers, personal loans, top-up loans, loan
            consolidation, and credit card recommendations. Our platform is
            designed to help you lower EMIs, save money, and improve your credit
            profile—all in one place.
          </p>

          <h3>Personal Loans: Easy, Quick, and Hassle-Free</h3>
          <p>
            Looking for personal loans with the lowest interest rates? Minemi
            helps you check your personal loan eligibility instantly and
            compares loan offers from top banks. Save time and money with
            transparent insights and smart recommendations tailored to your
            needs.
          </p>

          <h3>Home Loan Eligibility Check</h3>
          <p>
            Dreaming of owning a home? Minemi’s home loan eligibility check tool
            gives you a clear picture of how much you can borrow. Compare
            interest rates, understand your loan-to-value ratio, and make
            informed decisions with ease.
          </p>

          <h3>Car Loans: Get the Best Deals</h3>
          <p>
            Minemi makes buying your dream car simple with its car loan
            eligibility check. Discover the best car loan options with
            competitive interest rates and flexible terms—all customized to your
            credit profile.
          </p>

          <h3>Lower Your EMI with Balance Transfer</h3>
          <p>
            Paying a high EMI? Minemi’s balance transfer tool helps you find
            better interest rates and calculate how much you can save. Switch to
            a lender with better terms and reduce your monthly burden in
            minutes.
          </p>

          <h3>Top-Up Loans for Every Occasion</h3>
          <p>
            Need additional funds on your current loan? Use Minemi’s top-up loan
            calculator to find the best options without starting a new loan.
            Whether it’s for education, home renovation, or emergencies, we’ve
            got you covered.
          </p>

          <h3>Loan Consolidation: Simplify Your Payments</h3>
          <p>
            Managing multiple loans can be expensive and stressful. Minemi
            offers loan consolidation tools to combine your loans into a single
            EMI. Lower your overall interest rates and simplify your finances.
          </p>

          <h3>Credit Card Recommendations: Get the Right Card</h3>
          <p>
            Choosing the right credit card is easy with Minemi. Use our credit
            card recommendation tool to find cards with maximum rewards,
            cashback, and the lowest fees. Compare top credit cards from leading
            banks in India and make an informed choice.
          </p>

          <h3>Check & Improve Your Credit Score</h3>
          <p>
            Keep track of your financial health with Minemi’s credit score check
            tool. Identify ways to improve your score and increase your chances
            of loan approvals. Whether it’s reducing inquiries or clearing
            debts, Minemi provides actionable insights.
          </p>

          <h3>Why Choose Minemi?</h3>
          <ul>
            <li className="mb-1">
              <strong>Compare Top Lenders:</strong> Find the best loan offers
              from leading banks.
            </li>
            <li className="mb-1">
              <strong>Save Money:</strong> Lower your EMIs and interest rates
              with smart tools.
            </li>
            <li className="mb-1">
              <strong>Boost Credit:</strong> Improve your financial profile with
              expert recommendations.
            </li>
            <li className="mb-1">
              <strong>Comprehensive Solutions:</strong> From loans to credit
              cards, Minemi covers it all.
            </li>
          </ul>
        </div>
      </div>
      <br />
      <div className=" footer-second-section d-flex justify-content-center">
        <p style={{ fontSize: "12px", color: "" }} className="deslaimer">
          Disclaimer: Minemi is a platform that connects borrowers with lenders.
          We do not directly lend money or make credit decisions. Interest
          rates, terms, and conditions are set by our lending partners and may
          vary based on your credit profile and other factors. Please review all
          terms and conditions carefully before accepting any loan offer.
        </p>
      </div>
      <br />
      <br />
    </footer>
  );
};

export default FooterUpdated;
