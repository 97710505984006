import React, { useEffect, useState, useContext } from "react";
// import illustration from "../../Assets/Images/Illustration22.webp";
import illustration from "../../../Assets/Images/Illustration.webp";
import toungeOut from "../../../Assets/Images/tongue-out.webp";
import { LuDot } from "react-icons/lu";
import "../EligiblityCriteria.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Breadcrumbs from "../Breadcrumbs";
import axios from "axios";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const AXISInterestRate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Compare Axis Bank Personal Loan Eligibility Instantly",
      pageDescription:
        "Compare Axis Bank personal loan eligibility criteria instantly. Explore competitive loan offers, flexible EMIs, and easy online applications to find your best fit. Apply today!",
      pageKeywords:
        "Axis Bank Personal Loan, Compare Personal Loan Eligibility, Axis Loan Eligibility Check, Personal Loan Online Application, Instant Loan Approval, Axis Bank Loan Calculator, Personal Loan EMI Calculator, Online Loan Eligibility Check, Compare Loan Offers, Axis Bank Loan Services, Loan Eligibility Criteria, Best Personal Loan Rates, Flexible EMIs, Instant Personal Loan Eligibility Check",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalLoan/badcredit/axis-bank-personal-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Compare Axis Bank Personal Loan Eligibility Instantly</title>
        <meta
          name="description"
          content="Compare Axis Bank personal loan eligibility criteria instantly. Explore competitive loan offers, flexible EMIs, and easy online applications to find your best fit. Apply today!"
        />

        <meta
          name="keywords"
          content="Axis Bank Personal Loan, Compare Personal Loan Eligibility, Axis Loan Eligibility Check, Personal Loan Online Application, Instant Loan Approval, Axis Bank Loan Calculator, Personal Loan EMI Calculator, Online Loan Eligibility Check, Compare Loan Offers, Axis Bank Loan Services, Loan Eligibility Criteria, Best Personal Loan Rates, Flexible EMIs, Instant Personal Loan Eligibility Check"
        />
        {/* <meta
          property="og:url"
          content="https://minemi.ai/loans/personal-loan/check-eligibility"
        /> */}
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            <Link
              to={"/loanpage/personalloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/personalLoan/badcredit"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan Interest Rates
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              AXIS Bank Personal Loan Eligibility
            </Link>{" "}
          </p>
        </div>
        <h1
          className="loanstatus-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",

            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Compare Axis Bank Personal Loan Eligibility
        </h1>
        <p
          className="loanstatus-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "24px",

            color: "#74589B",
            backgroundColor: "#E7E4F9",
          }}
        >
          Axis Bank provides personal loans tailored to meet a wide range of
          financial needs with competitive interest rates, flexible tenures, and
          fast processing. Here is a comprehensive guide to Axis Bank’s personal
          loan eligibility criteria and related details.
        </p>
      </div>
      <div
        className="tracking-status"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
      >
        <h2
          style={{
            fontFamily: "Archivo",
            marginTop: 30,
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Eligibility Criteria for Axis Bank Personal Loans
        </h2>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Parameter
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Eligibility Requirement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  - Salaried: 21–60 years <br /> - Self-Employed: 25–65 years
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  - Salaried: Minimum monthly income of ₹15,000 <br /> -
                  Self-Employed: Minimum annual income as per ITR
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Type
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Salaried individuals, self-employed professionals, or business
                  owners
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Work Experience
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Salaried: Minimum 1 year with at least 6 months in the current
                  organization
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    border: "1px solid #ddd",
                    borderBottomLeftRadius: 18,
                  }}
                >
                  Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  700 or above preferred
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="make-it-center increase-margin "
          style={{
            marginTop: "4%",
            marginBottom: "2%",
            padding: 24,
            background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
            borderRadius: 24,
          }}
        >
          <div
            style={{
              marginLeft: "4%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              gap: 24,
              borderRadius: 24,
              alignSelf: "center",
            }}
          >
            <p
              className="eligiblity-criteria"
              style={{
                fontFamily: "Archivo",
                color: "#59266D",
                fontSize: 40,
                backgroundColor: "transparent",
              }}
            >
              <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                Check Your Loan Eligibility Without{" "}
                <br className="brake-remove" /> Impacting Your Credit Score
              </i>
            </p>
            <a
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="eligiblity-buttons"
              style={{
                backgroundColor: "#553285",
                color: "#FFFFFF",
                width: "39%",
                borderRadius: 8,
                fontFamily: "Archivo",
                fontWeight: 500,
                fontSize: 18,
                paddingTop: 8,
                paddingBottom: 8,
                textAlign: "center",
              }}
            >
              Check Eligibility
            </a>
          </div>
          <div
            style={{
              width: 200,
              height: 195,
              backgroundColor: "transparent",
              marginRight: "4%",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              loading="lazy"
              alt="Check Eligibility"
            />
          </div>
        </div>

        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "4%",
          }}
        >
          Features That Enhance Your Eligibility
        </p>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Feature
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Impact on Eligibility
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  High Credit Score (750+)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Improves approval chances and offers lower interest rates.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Minimal Existing Debts
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Applicants with fewer ongoing obligations have better chances
                  of approval.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Regular and Stable Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Demonstrates repayment capacity, boosting eligibility.
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Pre-Existing Relationship with Axis Bank
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Existing customers may enjoy expedited processing and
                  preferential terms.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          How to Check Your Axis Bank Personal Loan Eligibility
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Steps to Check Eligibility Online
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              Visit the{" "}
              <a
                underline="hover"
                href={"https://www.axisbank.com/"}
                target="_blank"
              >
                Axis Bank Official Website.
              </a>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              Navigate to the “Personal Loans” section.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              Enter your details in the eligibility calculator, such as income,
              obligations, and loan amount.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              Instantly view your eligibility and the maximum loan amount you
              can avail.
            </div>
          </div>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          <span
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Offline Method:
          </span>{" "}
          You can visit any Axis Bank branch with your required documents, and
          the bank representatives will guide you through the process.
        </p>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Eligibility Criteria by Applicant Type
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Applicant Type
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Key Criteria
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Salaried Individuals
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Steady income proof, employment in a reputable organization,
                  and credit score above 700.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Self-Employed
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Stable business turnover and operations with ITR filed for at
                  least 2 years.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Documents Required for Axis Bank Personal Loan Eligibility
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Category
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Examples
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Identity Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Aadhaar, PAN Card, Passport, or Voter ID.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Address Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Utility Bills, Rent Agreement, Aadhaar Card, or Passport.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Salaried: Salary slips for the last 3 months, Form 16
                  <br /> - Self-Employed: ITR for 2 years.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Offer Letter, Appointment Letter, or Employer Certificate.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Bank Statement
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Last 6 months’ bank statements.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Photographs
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Recent passport-sized photographs.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Improve Your Axis Bank Personal Loan Eligibility
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Improvement Strategy
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Maintain a High Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Ensure timely repayment of bills and EMIs to improve your
                  creditworthiness.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Reduce Existing Liabilities
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Paying off smaller debts lowers your FOIR, enhancing loan
                  eligibility.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Apply with a Co-Applicant
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  A co-applicant with a strong financial profile can increase
                  approval chances and loan amount.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Opt for a Suitable Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Choose an amount aligned with your repayment capacity to
                  improve eligibility.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* minemi tip section */}
        <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div className="px-4 py-4" style={{ backgroundColor: "#e7e4f9" }}>
            <div
              className="px-3 py-2"
              style={{ backgroundColor: "#e7e4f9", padding: "30px " }}
            >
              <div
                className="d-flex gap-2 align-items-center"
                style={{
                  backgroundColor: "transparent",
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  gap: "20px",
                }}
              >
                <img
                  src={toungeOut}
                  alt="minemi tip image"
                  loading="lazy"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "#e7e4f9",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",

                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  👉 "Bundle your existing debts into one manageable payment
                  plan using Axis Bank's personal loan services. MinEmi’s EMI
                  Monitoring Tool can help you track and optimize your repayment
                  schedule for better credit health."
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Benefits of Axis Bank Personal Loans
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Benefit
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Attractive Interest Rates
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Starting at 10.49% p.a.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  High Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Borrow up to ₹40 lakhs based on your eligibility.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Flexible Tenure Options
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Repayment tenure ranging from 12 to 60 months.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Quick Processing
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Loan approval and disbursal within 48 hours for eligible
                  applicants.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Minimal Paperwork
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Minimal Paperwork Simple documentation and streamlined
                  application process.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Snapshot of Key Axis Loan Parameters
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Parameter
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age Limit
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Salaried: 21–60 years
                  <br />
                  Self-Employed: 25–65 years
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Minimum Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  ₹15,000 per month for salaried applicants.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Maximum Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  ₹40 lakhs.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Repayment Tenure
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  12 to 60 months.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Interest Rate
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Starting at 10.49% p.a.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Processing Fee
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Up to 2% of the loan amount + applicable taxes.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p
          style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
          className="mt-4"
        >
          For more information please check{" "}
          <a
            href={
              "https://www.axisbank.com/retail/calculators/personal-loan-eligibility-calculator"
            }
            target="_blank"
          >
            AXIS Bank Eligibility
          </a>
        </p>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              What is the minimum income requirement to apply for an Axis Bank
              personal loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              For salaried individuals, the minimum monthly income is ₹15,000.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Can self-employed professionals apply for Axis Bank personal
              loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, self-employed professionals can apply, provided they meet the
              income and business stability requirements.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How much loan amount can I get from Axis Bank?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              You can avail of a personal loan of up to ₹40 lakhs, subject to
              eligibility.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Does Axis Bank offer pre-approved personal loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, pre-approved loans are available for select existing
              customers based on their banking relationship and credit profile.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Is there a processing fee for Axis Bank personal loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, the processing fee is up to 2% of the loan amount plus
              applicable taxes.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can I check my eligibility online without applying for a loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, you can use the Axis Bank eligibility calculator available on
              their website to check your eligibility instantly.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
        </div>

        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: "4%",
            marginBottom: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 24,
                backgroundColor: "transparent",
                marginTop: "3%",
              }}
            >
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#553285",
                  color: "#FFFFFF",
                  width: "18%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AXISInterestRate;
