import React from "react";

function FlowchartStep({ icon, step, text }) {
  return (
    <div
      className="flex items-center space-x-4 group "
      style={{ backgroundColor: "#ebe8fb" }}
    >
      <div className="w-12 h-12 rounded-full bg-gradient-to-br from-purple-400 to-purple-600 flex items-center justify-center text-white transition-transform group-hover:scale-110">
        {icon}
      </div>
      <div style={{ backgroundColor: "#ebe8fb" }}>
        <span
          className="font-bold "
          style={{
            backgroundColor: "#ebe8fb",
            color: "#5b2371",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          Step {step}:
        </span>{" "}
        <span
          style={{
            backgroundColor: "#ebe8fb",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            color: "#57406f",
          }}
        >
          {text}
        </span>{" "}
      </div>
    </div>
  );
}

export default FlowchartStep;
