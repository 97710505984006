import React from "react";
import { LuCircleCheckBig } from "react-icons/lu";

const HeroSection = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "linear-gradient(to bottom, #f7f6fe,#ece9fc)",
      }}
    >
      <section
        className="bg-gradient-to-b from-white to-[#EBE8FC] py-10"
        style={{ background: "linear-gradient(to bottom, #f7f6fe,#ece9fc)" }}
      >
        <div className="container mx-auto px-4">
          <div
            className="max-w-4xl mx-auto"
            style={{
              background: "linear-gradient(to bottom, #f7f6fe,#ece9fc)",
            }}
          >
            <div
              className="text-center pb-8"
              style={{ backgroundColor: "transparent" }}
            >
              <h1
                className="text-4xl md:text-5xl font-bold mb-4 "
                style={{ backgroundColor: "transparent", color: "#59256d" }}
              >
                Lower Your EMI, Save Thousands with Balance Transfer
              </h1>
              <p
                className="text-xl text-center"
                style={{
                  backgroundColor: "transparent",
                  color: "#5a266d",
                  fontWeight: "lighter",
                }}
              >
                Take control of your finances and save more each month! With
                MinEMI, finding lower EMIs has never been easier or more
                hassle-free.
              </p>
            </div>
            <div
              className="flex flex-col md:flex-row items-center justify-between gap-8"
              style={{
                backgroundColor: "transparent",
              }}
            >
              <ul
                className="space-y-4 flex-1"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <li className="flex" style={{ backgroundColor: "transparent" }}>
                  <LuCircleCheckBig
                    className="text-green-500 mr-2 flex-shrink-0"
                    size={20}
                    style={{ backgroundColor: "transparent" }}
                  />
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "#5a266d",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Save up to ₹10,000/month
                  </span>
                </li>
                <li
                  className="flex "
                  style={{ backgroundColor: "transparent" }}
                >
                  <LuCircleCheckBig
                    className="text-green-500 mr-2 flex-shrink-0"
                    size={20}
                    style={{ backgroundColor: "transparent" }}
                  />
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "#5a266d",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    Exclusive rates from 8.5% p.a. Lower interest rates
                    guaranteed
                  </span>
                </li>
                <li
                  className="flex "
                  style={{ backgroundColor: "transparent" }}
                >
                  <LuCircleCheckBig
                    className="text-green-500 mr-2 flex-shrink-0"
                    size={20}
                    style={{ backgroundColor: "transparent" }}
                  />
                  <span
                    style={{
                      backgroundColor: "transparent",
                      color: "#5a266d",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                  >
                    100% Free Service. We earn from banks, not you
                  </span>
                </li>
              </ul>
              <div
                className="flex-1 flex justify-center"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-removebg-preview%20(1)-JLvin2VB9En5WecewdjEnKHJ7bRmcY.png"
                  alt="Money Savings Bag with Coins"
                  width={200}
                  height={200}
                  priority
                  className="md:ml-8 object-contain w-[160px] h-[160px] md:w-[200px] md:h-[200px]"
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <div
              className="text-center pt-8"
              style={{ backgroundColor: "transparent" }}
            >
              <button className="bg-[#59266d] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors">
                Get Started Now
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
