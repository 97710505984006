import React, { useState, useEffect } from "react";
import logo from "../../Assets/Images/Logo.webp";
import mlogo from "../../Assets/Images/mLogo.webp";
import task from "../../Assets/Images/task.webp";
import rightArrow from "../../Assets/Images/rightArrow.webp";
import leftArrow from "../../Assets/Images/leftArrow.webp";
import LowerEmi from "../../Assets/Images/lowerEmi.webp";
import { BsList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiReceiveMoney } from "react-icons/gi";
import profilephoto from "../../Assets/Images/avtar.webp";
import { BiHomeAlt, BiSolidCreditCardAlt } from "react-icons/bi";
import "./NavBar.css";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { HashLink as RouterHashLink } from "react-router-hash-link";
import loans from "../../Assets/Images/Loans.webp";
import activeloan from "../../Assets/Images/ActiveLoan.webp";
import ActiveTask from "../../Assets/Images/ActiveTask.webp";
import consolidates from "../../Assets/Images/consolidates.webp";
import Activeconsolidates from "../../Assets/Images/activeconsolidate.webp";
import Inactivecredit from "../../Assets/Images/InactiveCredit.webp";
import Credits from "../../Assets/Images/creditScore.webp";
import InactiveTop from "../../Assets/Images/inactivetopup.webp";
import activeTop from "../../Assets/Images/activeTopup.webp";
import inactivelower from "../../Assets/Images/inactiverlower.webp";
import activelower from "../../Assets/Images/activelower.webp";
import activeblog from "../../Assets/Images/activeBlog.webp";
import inactiveblog from "../../Assets/Images/inactiveblog.webp";
import deleteprofile from "../../Assets/Images/user.webp";
import turnoff from "../../Assets/Images/turn-off.webp";
import { FaAngleUp } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import candidate from "../../Assets/Images/Personal.webp";
import Activecandidate from "../../Assets/Images/Personal.webp";

// import candidate from "../../Assets/Images/candidate.webp"
// import Activecandidate from "../../Assets/Images/candidate (1).webp"

const NavBar = () => {
  const [progress, setprogress] = useState(0);
  const [status, setStatus] = useState(false);
  const [home, setHome] = useState(true);
  const [creditCard, setCreditCard] = useState(false);
  const [loan, setLoan] = useState(false);
  const [statusD, setStatusD] = useState(false);
  const [blog, setBlog] = useState(false);
  const [homeD, setHomeD] = useState(true);
  const [loanD, setLoanD] = useState(false);
  const [Lower, setLower] = useState(false);
  const [Consolidates, setConsolidate] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [credit, setCredit] = useState(false);
  const token = localStorage.getItem("token");
  const [LoanDetail, setLoanDetail] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const FirstName = localStorage.getItem("FirstName");
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownService, setDropdownService] = useState(false);
  const [isDropdownCredit, setDropdownCredit] = useState(false);
  const [item, setItem] = useState("");
  const [Eligiblity, setEligiblity] = useState(false);
  const viewProfileHandler = () => {
    setprogress(30);
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/detail`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        navigate("/updateprofile", {
          state: {
            name1: response.data.data.user.name,
            pan1: response.data.data.user.pan,
            dob1: response.data.data.user.dob,
            gender1: response.data.data.user.gender,
            avatar1: response.data.data.user.avatar,
          },
        });
        localStorage.setItem("name", response.data.data.user.name);
        localStorage.setItem("profile", response.data.data.user.avatar);
        setprogress(100);
      })
      .catch(function (error) {
        console.error(error);
        setprogress(100);
      });
  };
  const CreditSummary = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setLoanDetail(response.data.data.loanDetail);
        navigate("/confirmloan", {
          state: { LoanDetail: response.data.data.loanDetail },
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const LogoutHandler = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/logout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("FirstName");
        setIsOpen(!isOpen);
        navigate("/login");
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
      <LoadingBar color=" #59266d" progress={progress} />
      <nav className="navBar">
        <div className="navLeft">
          <div className="logoContainer">
            <Link to="/" style={{ backgroundColor: "transparent" }}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="navRight">
          <div className="navLinks" style={{ backgroundColor: "white" }}>
            <ul style={{ border: "1px soild red", marginBottom: 0 }}>
              {token == null ? (
                <>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onMouseEnter={() => {
                        setDropdownService(false);
                        setDropdownOpen(false);
                        setDropdownCredit(false);
                      }}
                      onClick={() => {
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                        setCreditCard(false);
                        setDropdownOpen(false);
                        setDropdownService(false);
                        setDropdownCredit(false);
                        setCredit(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onClick={() => {
                        localStorage.setItem("id", 1);
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                        setCreditCard(false);
                        setCredit(false);
                      }}
                      onMouseEnter={() => {
                        setDropdownService(false);
                        setDropdownOpen(false);
                        setDropdownCredit(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              )}

              {/* Credit Score */}

              <div style={{ position: "relative" }}>
                <li
                  style={{
                    fontWeight: credit ? "600" : "300",
                    fontSize: 16,
                    color: credit ? "#59266D" : "#1F1F1F",
                  }}
                  onMouseEnter={() => {
                    setDropdownCredit(false);
                    setDropdownService(false);
                    setDropdownOpen(false);
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(false);
                    setCreditCard(false);
                    setCredit(true);
                    setStatusD(false);
                    navigate("/credit-score");
                    setDropdownCredit(false);
                    setDropdownService(false);
                    setDropdownOpen(false);
                  }}
                >
                  Credit Score
                </li>
              </div>

              {/* Credit Card */}

              {/* <RouterHashLink to="/creditcard"> */}

              {/* <div style={{ position: "relative" }}>
                <li
                  style={{
                    fontWeight: creditCard ? "600" : "300",
                    fontSize: 16,
                    color: creditCard ? "#59266D" : "#1F1F1F",
                  }}
                  onMouseEnter={() => {
                    setDropdownCredit(true);
                    setDropdownService(false);
                    setDropdownOpen(false);
                  }}
                  onClick={() => {
                    navigate("/creditcard");
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(false);
                    setCreditCard(true);
                    setCredit(false);
                    setStatusD(false);
                    setDropdownCredit(false);
                    setDropdownService(false);
                    setDropdownOpen(false);
                  }}
                >
                  Credit Card
                </li>
               
                {isDropdownCredit && (
                  <ul
                    style={{
                      width: 289,
                      position: "absolute",
                      top: 50,
                      left: 0,
                      backgroundColor: "#F6F6F6",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: 16,
                      paddingLeft: 0,
                      paddingLeft: 9,
                      textAlign: "left",
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                    }}
                    onMouseEnter={() => setDropdownCredit(true)}
                    onMouseLeave={() => setDropdownCredit(false)}
                  >
                    <Link
                      className="drop-link"
                      to="http://creditcard.awd.world"
                      style={{ color: "black" }}
                    >
                      <li
                        style={{
                          cursor: "pointer",
                          padding: "8px 0",
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#F6F6F6",
                          gap: 4,
                          alignContent: "center",
                        }}
                      >
                        <div style={{ alignSelf: "center" }}>
                          Credit Card Recommendations
                        </div>
                        <IoIosArrowForward
                          style={{ alignSelf: "center", fontSize: 16 }}
                        />
                      </li>
                    </Link>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/creditcard/credit-card-eligibility");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        Credit Card Check Eligibility
                      </div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate(
                          "/creditcard/credit-card-rewards-and-cashback"
                        );
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        Credit Card Rewards and Cashback
                      </div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/creditcard/travel-and-airline-credit-cards");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        Travel and Airline Credit Cards
                      </div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/creditcard/loan-status-tracking");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        Loan Status Tracking
                      </div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>

                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/creditcard/balance-transfer-credit-cards");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>
                        Balance Transfer Credit Cards
                      </div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                  </ul>
                )}
              </div> */}

              <div style={{ position: "relative" }}>
                {/* <RouterHashLink to="/#DownloadApp"> */}
                <li
                  style={{
                    fontWeight: loanD ? "600" : "300",
                    fontSize: 16,
                    color: loanD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(true);
                    setStatusD(false);
                    setBlog(false);
                    setCredit(false);
                    setCreditCard(false);
                    navigate("/loanpage");
                  }}
                  onMouseEnter={() => {
                    setDropdownOpen(true);
                    setDropdownService(false);
                    setDropdownCredit(false);
                  }}
                >
                  Loans
                </li>

                {/* </RouterHashLink> */}
                {isDropdownOpen && (
                  <ul
                    style={{
                      position: "absolute",
                      top: 50,
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: 16,
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      width: 200,
                      zIndex: 1000,
                      backgroundColor: "#F6F6F6",
                    }}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "transparent",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/personalloan");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}> Personal Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/homeloan");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>Home Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/carloan");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}> Car Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/digital-loan");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>Digital Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/gold-loan");
                        setDropdownOpen(false);
                      }}
                    >
                      <div style={{ alignSelf: "center" }}>Gold Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                  </ul>
                )}
              </div>
              {/* <div style={{ position: "relative" }}>
                <RouterHashLink to="/#DownloadApp">
                <li
                  style={{
                    fontWeight: statusD ? "600" : "300",
                    fontSize: 16,
                    color: statusD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setStatusD(true);
                    setBlog(false);
                    setCredit(false)
                    setCreditCard(false);
                    navigate("/loanpage");
                  }}
                  onMouseEnter={() => {
                    setDropdownOpen(true);
                    setDropdownService(false);
                    setDropdownCredit(false);

                  }}
                >
                  Services
                </li>

                </RouterHashLink>
              
              </div> */}
              <Link
                to="/blog"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setDropdownOpen(false);
                  setDropdownService(false);
                  setDropdownCredit(false);
                }}
                onMouseEnter={() => {
                  setDropdownService(false);
                  setDropdownOpen(false);
                  setDropdownCredit(false);
                }}
              >
                <li
                  style={{
                    fontWeight: blog ? "600" : "300",
                    fontSize: 16,
                    color: blog ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(true);
                    setCreditCard(false);
                    setStatusD(false);
                    setDropdownCredit(false);
                    setDropdownService(false);
                    setDropdownOpen(false);
                    setCredit(false);
                  }}
                >
                  Blogs
                </li>
              </Link>
            </ul>
          </div>
          <div
            className="navStatus"
            onClick={() => {
              setDropdownOpen(false);
              setDropdownService(false);
              setDropdownCredit(false);
            }}
          >
            {token == null ? (
              <RouterHashLink to="/login">
                <button
                  className="loginDesktop"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => {
                    setDropdownOpen(false);
                    setDropdownService(false);
                    setDropdownOpen(false);
                    setDropdownCredit(false);
                  }}
                >
                  Log in
                  <img src={rightArrow} alt="" />
                </button>
              </RouterHashLink>
            ) : (
              <button
                className="loginDesktop"
                style={{ cursor: "pointer" }}
                onClick={() => LogoutHandler()}
                onMouseEnter={() => {
                  setDropdownOpen(false);
                  setDropdownService(false);
                  setDropdownOpen(false);
                  setDropdownCredit(false);
                }}
              >
                Log out
                <img src={rightArrow} alt="" />
              </button>
            )}
          </div>
        </div>
      </nav>
      <nav className="mobileNavBar">
        <div className="mLogo">
          <Link to="/dashboard" style={{ backgroundColor: "transparent" }}>
            <img src={mlogo} alt="" />
          </Link>
        </div>
        {/* {["/otp", "/goal"].includes(location.pathname) ? null : ( */}
        <BsList
          onClick={toggleSidebar}
          size={24}
          color="#1F1F1F"
          style={{ backgroundColor: "#ffff", cursor: "pointer" }}
        />
        {/* )} */}
      </nav>
      {isOpen && (
        <div
          className={`sidebar ${isOpen ? "open" : ""}`}
          style={{ overflowY: "scroll" }}
        >
          <div style={{ backgroundColor: "white" }}>
            <div style={{ backgroundColor: "white" }}>
              <div style={{ height: "72px", backgroundColor: "white" }}></div>
              <div className="menu">
                <RxCross2
                  onClick={toggleSidebar}
                  size={16}
                  color="#1F1F1F"
                  style={{ backgroundColor: "#ffff" }}
                />
                <div className="frame">
                  <div className="menuLogin">
                    <div
                      className="menuArrow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      {" "}
                      {token ? (
                        <img src={leftArrow} alt="" />
                      ) : (
                        <img src={leftArrow} alt="" />
                      )}
                    </div>
                    {token != null ? (
                      <Link
                        to="/Dashboard"
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Dashboard
                        </div>{" "}
                      </Link>
                    ) : (
                      <a
                        href="/login"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Log in
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {token && (
                <div
                  onClick={() => {
                    viewProfileHandler();
                    setIsOpen(!isOpen);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "transparent",
                      // backgroundColor: "#846CA6",
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/profile-1737532702169.webp"
                      alt=""
                      width="64"
                      height="64"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto 0",
                      marginLeft: "10px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "13.06px",
                        textAlign: "left",
                        paddingBottom: "9px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {" "}
                      👋 Hi!
                    </div>

                    {FirstName == "null" ? null : (
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          lineHeight: "17.41px",
                          textAlign: "left",
                          backgroundColor: "transparent",
                        }}
                      >
                        {FirstName}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="menuLinks" style={{ backgroundColor: "white" }}>
                <ul style={{ backgroundColor: "white" }}>
                  {token === null ? (
                    <Link
                      to="/"
                      style={{
                        backgroundColor: "white",
                        textDecoration: "none",
                      }}
                    >
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          backgroundColor: "white",
                          cursor: "pointer",
                          color: home ? "#59266D" : "#6b6565",
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                      >
                        <BiHomeAlt
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                            color: home ? "rgb(89, 38, 109)" : "#6b6565",
                          }}
                        />
                        Home
                      </li>
                    </Link>
                  ) : (
                    <Link to="/dashboard" style={{ backgroundColor: "white" }}>
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                      >
                        <BiHomeAlt
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                            color: home ? "rgb(89, 38, 109)" : "",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight: home ? "700" : "500",
                            color: home ? "#59266D" : "rgba(31, 31, 31, 1)",
                          }}
                        >
                          Home
                        </span>
                      </li>
                    </Link>
                  )}

                  {/* {token === null ? (
                 null
                  ) : (
                    <Link to="/loanoverview" style={{ backgroundColor: "white" }}>
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 14,
                          backgroundColor: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setHome(false);
                          setLoan(true);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                        }}
                      >
                        <GiReceiveMoney
                          style={{
                            backgroundColor: "transparent",
                            fontSize: "20px",
                            color: loan ? "rgb(89, 38, 109)" : "",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight: loan ? "700" : "500",
                            color: loan ? "#59266D" : "rgba(31, 31, 31, 1)",
                          }}
                        >
                         My Loans
                        </span>
                      </li>
                    </Link>
                  )} */}

                  {token == null ? (
                    <Link
                      className="slider-drop-link"
                      to="/loanpage"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(true);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(false);
                            setEligiblity(false);
                          }}
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight: isDropdownOpen || loan ? "700" : "500",
                            color:
                              isDropdownOpen || loan ? "#59266D" : "#6b6565",
                          }}
                        >
                          <img
                            src={isDropdownOpen || loan ? activeloan : loans}
                            alt=""
                            height="20.9"
                            weight="20.9"
                            style={{ backgroundColor: "white" }}
                          />
                          Loans
                        </li>
                        <FaAngleUp
                          className="slider-drop-link"
                          onClick={() => setDropdownOpen(!isDropdownOpen)}
                          style={{
                            backgroundColor: "transparent",
                            color:
                              isDropdownOpen || loan ? "#553285" : "#A5A5A5",
                            fontSize: 12,
                            transform: isDropdownOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.2s",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      {isDropdownOpen && (
                        <ul
                          style={{
                            padding: "0",
                            marginTop: "5px",
                            listStyleType: "none",
                            marginLeft: "12%",
                            gap: 2,
                            backgroundColor: "white",
                          }}
                        >
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor: "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                              backgroundColor:
                                item === "personal" ? "e5e3f2" : "white",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("personal");
                            }}
                          >
                            <Link
                              to="/loanpage/personalloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "personal" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Personal Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "home" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("home");
                            }}
                          >
                            <Link
                              to="/loanpage/homeloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "home" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Home Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "car" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("car");
                            }}
                          >
                            <Link
                              to="/loanpage/carloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "car" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Car Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "digital" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("digital");
                            }}
                          >
                            <Link
                              to="/loanpage/digital-loan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "digital" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Digital Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "gold" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("gold");
                            }}
                          >
                            <Link
                              to="/loanpage/gold-loan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "gold" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Gold Loan
                            </Link>
                          </li>
                        </ul>
                      )}
                    </Link>
                  ) : (
                    <Link
                      className="slider-drop-link"
                      to="/loanoverview"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(true);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(false);
                            setEligiblity(false);
                          }}
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight: loan ? "700" : "500",
                            color: loan ? "#59266D" : "#6b6565",
                          }}
                        >
                          <img
                            src={loan ? activeloan : loans}
                            alt=""
                            height="20.9"
                            weight="20.9"
                            style={{ backgroundColor: "white" }}
                          />
                          Loans
                        </li>
                      </div>
                    </Link>
                  )}
                  {/* {token == null ? (
                    <Link
                      className="slider-drop-link"
                      to="/creditcard"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(false);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(true);
                            setEligiblity(false);
                          }}
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            backgroundColor: "white",
                            fontWeight:
                              isDropdownCredit || creditCard ? "700" : "500",
                            color:
                              isDropdownCredit || creditCard
                                ? "#59266D"
                                : "#6b6565",
                          }}
                        >
                          <BiSolidCreditCardAlt
                            style={{ backgroundColor: "white", fontSize: 20 }}
                          />
                          Credit Card
                        </li>
                        <FaAngleUp
                          className="slider-drop-link"
                          onClick={() => setDropdownCredit(!isDropdownCredit)}
                          style={{
                            backgroundColor: "transparent",
                            color:
                              isDropdownCredit || creditCard
                                ? "#553285"
                                : "#6b6565",
                            fontSize: 12,
                            transform: isDropdownCredit
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.2s",
                            alignSelf: "center",
                          }}
                        />
                      </div>

                      {isDropdownCredit && (
                        <ul
                          style={{
                            padding: "0",
                            marginTop: "5px",
                            listStyleType: "none",
                            marginLeft: "5%",
                            gap: 2,
                            backgroundColor: "white",
                          }}
                        >
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "recommend" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("recommend");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              to="https://creditcard.awd.world/"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "recommend" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                               Credit Card Recommendations
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "benefit" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("benefit");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "benefit" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Compare Rewards and Benefits
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "cashback" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("cashback");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "cashback" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Credit Card Rewards and Cashback
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "travel" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("travel");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "travel" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                               Travel and Airline Credit Cards
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "transfer" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("transfer");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "transfer" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: "14px",
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Balance Transfer Credit Cards
                            </Link>
                          </li>
                        </ul>
                      )}
                    </Link>
                  ) : null} */}

                  {token == null ? (
                    <a
                      href="/login?goal=lower-your-emi"
                      onClick={() => {
                        localStorage.setItem("id", 1);
                        window.scrollTo(0, 0);
                      }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontWeight: Lower ? "700" : "500",
                          fontSize: 14,
                          color: Lower
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Lower ? activelower : inactivelower}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Lower my EMI
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 1);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Lower ? "700" : "500",
                          color: Lower
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Lower ? activelower : LowerEmi}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Lower my EMI
                      </li>
                    </Link>
                  )}

                  {token == null ? (
                    <a
                      href="/login?goal=loan-consolidate"
                      style={{ backgroundColor: "transparent" }}
                      onClick={() => {
                        localStorage.setItem("id", 2);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Consolidates ? "700" : "500",
                          color: Consolidates
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Consolidates ? Activeconsolidates : consolidates}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Consolidate High Interest Debt
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 2);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: Consolidates ? "700" : "500",
                          color: Consolidates
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={Consolidates ? Activeconsolidates : consolidates}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Consolidate High Interest Debt
                      </li>
                    </Link>
                  )}
                  {token == null ? (
                    <a
                      href="/login?goal=loan-top-up"
                      style={{ backgroundColor: "transparent" }}
                      onClick={() => {
                        localStorage.setItem("id", 3);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: topUp ? "700" : "500",
                          color: topUp
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={topUp ? activeTop : InactiveTop}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Transfer Loan With a Top-Up
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 3);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: topUp ? "700" : "500",
                          color: topUp
                            ? "rgb(89, 38, 109)"
                            : "rgba(31, 31, 31, 1)",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={topUp ? activeTop : InactiveTop}
                          alt=""
                          style={{ backgroundColor: "transparent" }}
                        />
                        Transfer Loan With a Top-Up
                      </li>
                    </Link>
                  )}

                  <a
                    href="https://minemi.ai/personal-loan/check-eligibility"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <li
                      onClick={() => {
                        setStatus(false);
                        setLoan(false);
                        setHome(false);
                        setIsOpen(!isOpen);
                        setLower(false);
                        setConsolidate(false);
                        setTopup(false);
                        setCredit(false);
                        setBlog(false);
                        setCreditCard(false);
                        setEligiblity(true);
                      }}
                      style={{
                        fontWeight: Eligiblity ? "700" : "500",
                        color: Eligiblity ? "#59266D" : "#6b6565",
                        backgroundColor: "white",
                        fontSize: 14,
                      }}
                    >
                      <img
                        src={Eligiblity ? Activecandidate : candidate}
                        alt=""
                        style={{ backgroundColor: "white" }}
                      />
                      Loan Eligibility
                    </li>
                  </a>

                  {token == null ? (
                    <Link
                      to="/login"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setStatus(true);
                          setLoan(false);
                          setHome(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontWeight: status ? "700" : "500",
                          color: status ? "#59266D" : "#6b6565",
                          backgroundColor: "white",
                          fontSize: 14,
                        }}
                      >
                        <img
                          src={status ? ActiveTask : task}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Application Status
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/applicationstatus"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <li
                        onClick={() => {
                          setStatus(true);
                          setLoan(false);
                          setHome(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontWeight: status ? "700" : "500",
                          color: status ? "#59266D" : "#6b6565",
                          backgroundColor: "white",
                          fontSize: 14,
                        }}
                      >
                        <img
                          src={status ? ActiveTask : task}
                          alt=""
                          style={{ backgroundColor: "white" }}
                        />
                        Application Status
                      </li>
                    </Link>
                  )}
                  <Link to="/blog" style={{ backgroundColor: "transparent" }}>
                    <li
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setLower(false);
                        setConsolidate(false);
                        setTopup(false);
                        setHome(false);
                        setStatus(false);
                        setLoan(false);
                        setCredit(false);
                        setBlog(true);
                        setCreditCard(false);
                        setEligiblity(false);
                      }}
                      style={{
                        fontWeight: blog ? "700" : "500",
                        fontSize: 14,
                        color: blog ? "#59266D" : "#6b6565",

                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={!blog ? inactiveblog : activeblog}
                        alt=""
                        style={{ backgroundColor: "white" }}
                      />
                      Blogs
                    </li>
                  </Link>

                  <li
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setLower(false);
                      setConsolidate(false);
                      setTopup(false);
                      setHome(false);
                      setStatus(false);
                      setLoan(false);
                      setCredit(true);
                      setBlog(false);
                      setCreditCard(false);
                      setEligiblity(false);
                      navigate("/credit-score");
                    }}
                    style={{
                      fontSize: 14,
                      fontWeight: credit ? "700" : "500",
                      color: credit ? "rgb(89, 38, 109)" : "#6b6565",
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      src={credit ? Credits : Inactivecredit}
                      alt=""
                      style={{ backgroundColor: "transparent" }}
                    />
                    Check Credit Score
                  </li>

                  {token === null ? null : (
                    <li
                      onClick={() => {
                        // localStorage.removeItem("token");
                        LogoutHandler();
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "rgba(31, 31, 31, 1)",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={turnoff}
                        alt=""
                        style={{ backgroundColor: "transparent" }}
                      />
                      Logout
                    </li>
                  )}

                  {token === null ? null : (
                    <li
                      onClick={() => {
                        toggleSidebar();
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "700",
                        color: "red",
                        backgroundColor: "white",
                      }}
                    >
                      <img
                        src={deleteprofile}
                        alt=""
                        style={{ backgroundColor: "transparent" }}
                      />
                      Delete Account
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default NavBar;
