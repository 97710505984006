import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import "./Login.css";
import LoginImg from "../../Assets/Images/login.webp";
import campaignOne from "../../Assets/Images/campaing-one.webp";
import { SignUpschemas } from "../../Schemas";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Yup from "yup";

const Login = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[A-Za-z]*$/, "First name can only contain letters")

      .required("First name is required"),

    lastname: Yup.string()
      .matches(/^[A-Za-z]*$/, "Last name can only contain letters")
      .required("Last name is required"),

    number: Yup.string()
      .matches(
        /^\+91\d{10}$/,
        "Mobile number must be in the format +91XXXXXXXXXX"
      )
      .required("Mobile number is required"),

    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const navigate = useNavigate();
  const [progress, setprogress] = useState(0);
  const [button, setButton] = useState(false);
  const location = useLocation();

  const initialValues = {
    name: "",
    lastname: "",
    email: "",
    number: "",
  };
  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        // console.log(values);
      },
    });
  const handleLogin = () => {
    if (
      values.name != "" &&
      values.email != "" &&
      values.lastname != "" &&
      values.number != ""
    ) {
      handleLogin1();
    } else {
      // console.log("error");
    }
  };
  const handleLogin1 = () => {
    setButton(true);

    const length = 16;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let sessionToken = "";
    for (let i = 0; i < length; i++) {
      sessionToken += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    console.log(sessionToken);
    localStorage.setItem("FirstName", values.name);
    const name = localStorage.getItem("FirstName");
    setprogress(30);
    localStorage.setItem("fname", values.name);
    localStorage.setItem("lname", values.lastname);
    localStorage.setItem("mobile", values.number.substring(3));
    localStorage.setItem("email", values.email);
    setprogress(50);

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/login`,
      data: {
        fname: values.name,
        mobile: values.number.substring(3),
        email: values.email,
        lname: values.lastname,
        sessionToken: sessionToken,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        toast(response.data.msg);
        if (response.data.status == 1) {
          localStorage.setItem(
            "stageone",
            response.data.data.expData.stgOneHitId
          );
          localStorage.setItem(
            "stagetwo",
            response.data.data.expData.stgTwoHitId
          );
          setprogress(100);
          navigate("/otp", { state: { from: response.data.data.token.token } });
        } else {
          // console.log(errors);
          setprogress(100);
        }
      })
      .catch(function (error) {
        console.error(error);
        setprogress(100);
        setButton(false);
      });
  };

  useEffect(() => {
    const preventDefault = (event) => {
      event.preventDefault();
    };

    document.body.addEventListener("contextmenu", preventDefault);
    document.body.addEventListener("copy", preventDefault);

    return () => {
      document.body.removeEventListener("contextmenu", preventDefault);
      document.body.removeEventListener("copy", preventDefault);
    };
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   {
  //     token == null && navigate("/login");
  //   }
  // }, []);
  const pathsToExclude = ["/adcampaign"];

  return (
    <div>
      <ToastContainer />
      <LoadingBar color=" #59266d" progress={progress} />
      <div className="loginContainer">
        {pathsToExclude.includes(location.pathname) ? (
          <div className="LoginImage">
            <img src={campaignOne} alt="image" />
          </div>
        ) : (
          <div className="LoginImage">
            <img src={LoginImg} alt="image" />
          </div>
        )}
        <div className="FieldContainer">
          <div
            style={{
              fontWeight: 600,
              fontSize: 14,
              color: "rgba(138, 138, 138, 1)",
              textAlign: "center",
            }}
          >
            {" "}
            Please enter mobile number which is linked to your bank account
          </div>
          <div className="fields">
            <form onSubmit={handleSubmit}>
              <div className="inputField" style={{ marginBottom: "20px" }}>
                First name
                <input
                  style={{ paddingLeft: "20px" }}
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name ? (
                  <p className="form-error" style={{ color: "red" }}>
                    {errors.name}
                  </p>
                ) : null}
              </div>
              <div className="inputField" style={{ marginBottom: "20px" }}>
                Last name
                <input
                  style={{ paddingLeft: "20px" }}
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastname && touched.lastname ? (
                  <p className="form-error" style={{ color: "red" }}>
                    {errors.lastname}
                  </p>
                ) : null}
              </div>
              <div
                className="inputField"
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="number">Mobile Number</label>
                <div></div>
                <input
                  style={{ paddingLeft: "20px" }}
                  type="text"
                  name="number"
                  id="number"
                  placeholder="+91"
                  value={
                    values.number && values.number.startsWith("+91")
                      ? values.number
                      : "+91"
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.number && touched.number ? (
                  <p className="form-error" style={{ color: "red" }}>
                    {errors.number}
                  </p>
                ) : null}
              </div>
              <div className="inputField" style={{ marginBottom: "20px" }}>
                Email
                <input
                  style={{ paddingLeft: "20px" }}
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="form-error" style={{ color: "red" }}>
                    {errors.email}
                  </p>
                ) : null}
              </div>
              <button
                type="submit"
                style={{ cursor: "pointer", opacity: button ? "40%" : "" }}
                onClick={handleLogin}
                disabled={button}
              >
                Get OTP
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
