import React from "react";
import Aboutus from "../../Assets/Images/Aboutus.webp";
import aboutus1 from "../../Assets/Images/clubemi.webp";
import aboutus2 from "../../Assets/Images/top.webp";
import about from "../../Assets/Images/aboutusimg.webp";

import credit from "../../Assets/Images/OurServiceLower.webp";
import "./AboutUs.css";
import { Helmet } from "react-helmet";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>About Us | Simplifying Loan Management with Tech</title>
        <meta
          name="description"
          content="Learn how Minemi leverages technology to simplify loan management. Loan eligibility, EMI tracking, and balance transfers."
        />
      </Helmet>
      <div
        className=""
        style={{ marginBottom: "10%", marginLeft: "4%", marginRight: "4%" }}
      >
        <div
          className="margin-About"
          style={{ margin: "0 auto", marginBottom: "20px" }}
        >
          <p
            style={{
              fontSize: "21px",
              fontWeight: "700",
              lineHeight: "23px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(89, 38, 109, 1)",
              marginTop: "70px",
            }}
          >
            About Us
          </p>

          <div
            className="About-heading"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "21px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingTop: "10px",
            }}
          >
            Empowering Financial Freedom At Minemi.ai, we believe in empowering
            individuals to achieve financial freedom easily through
            do-it-yourself digital journeys powered by technology. Founded by a
            team of seasoned banking professionals with deep roots in the
            fintech industry, our mission is to simplify the complex world of
            loans and make credit easily accessible for end users.
          </div>
          <div
            className="about-content"
            style={{
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "21px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              marginTop: "20px",
            }}
          >
            Our mission is to revolutionize the way people manage and optimize
            their credit. By providing clear, algorithm-based recommendations,
            we help you manage your finances on your own. Whether it's securing
            new credit, transferring existing credit, getting top-ups, or
            comparing credit card options, Minemi.ai is the go-to place for
            managing credit.
          </div>
        </div>

        <div
          className="about-box "
          style={{
            marginTop: "70px",
            width: "100%",
            height: "464px",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            className="img-container"
            src={Aboutus}
            alt="About us"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              objectFit: "center",
              objectPosition: "center",
            }}
          />
        </div>

        <div
          className="d-flex about-flex margin-About"
          style={{
            // margin: "0 auto",
            marginTop: "4%",
            gap: "80px",
            marginLeft: "60px",
          }}
        >
          <div>
            <p
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
              }}
            >
              Services
            </p>
            <p
              className="service-about"
              style={{
                fontSize: "55px",
                fontWeight: "500",
                lineHeight: "66px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(31, 31, 31, 1)",
                paddingTop: "10px",
              }}
            >
              Services that save
              <br />
              you time and money
            </p>
          </div>
          <div>
            <p
              className="about-service"
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "21px",
                letterSpacing: "0em",
                textAlign: "left",
                paddingTop: "45px",
              }}
            >
              Our Approach We are dedicated to resolving financial problems by
              helping you
              <br /> get the best credit products available. Our platform
              simplifies the lending <br /> process, making it super easy for
              you to access and manage credit.
            </p>
          </div>
        </div>

        <div className="row about-row">
          <div className="about-column col-xl-4 col-lg-4 col-md-12">
            <div
              className="image-container"
              style={{
                background: "rgba(255, 255, 255, 1)",
                width: "400px",
                height: "430px",
                padding: "16px, 16px, 28px, 16px",
                borderRadius: "20px",
              }}
            >
              <img
                className="aboutus-image"
                src={credit}
                alt="About"
                style={{
                  width: "368px",
                  height: "288px",
                  borderRadius: "15px",
                  marginTop: "20px",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              />
              <div
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "35px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Lower your EMI
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "5px",
                  }}
                >
                  Monitor your existing EMIs and receive tailored
                  recommendations for better credit solutions.
                </p>
              </div>
            </div>
          </div>
          <div className=" about-column col-xl-4 col-lg-4 col-md-12">
            <div
              className="image-container"
              style={{
                background: "rgba(255, 255, 255, 1)",
                width: "400px",
                height: "430px",
                padding: "16px, 16px, 28px, 16px",
                borderRadius: "20px",
              }}
            >
              <img
                className="aboutus-image"
                src={aboutus1}
                alt="About us"
                style={{
                  width: "368px",
                  height: "288px",
                  borderRadius: "15px",
                  marginTop: "20px",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              />
              <div
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "35px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Club your EMIs <br /> into one
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "5px",
                  }}
                >
                  Combine multiple credits into one manageable payment.
                </p>
              </div>
            </div>
          </div>
          <div className=" about-column col-xl-4  col-lg-4 col-md-12">
            <div
              className="image-container"
              style={{
                background: "rgba(255, 255, 255, 1)",
                width: "400px",
                height: "430px",
                padding: "16px, 16px, 28px, 16px",
                borderRadius: "20px",
              }}
            >
              <img
                className="aboutus-image"
                src={aboutus2}
                alt="About us"
                style={{
                  width: "368px",
                  height: "288px",
                  borderRadius: "15px",
                  marginTop: "20px",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              />
              <div
                style={{
                  background: "transparent",
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    lineHeight: "35px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Top-up my running loan
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    backgroundColor: "transparent",
                    paddingTop: "5px",
                  }}
                >
                  Access additional funds on favorable terms.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5%",
          }}
        >
          <div>
            <div
              className="  whatwedo"
              style={{
                fontSize: "21px",
                fontWeight: "700",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
              }}
            >
              What We Do{" "}
            </div>
            <div style={{ marginTop: "1%" }}>
              <p
                className="mim-ai"
                style={{ fontSize: 14, lineHeight: 1.24, fontWeight: 400 }}
              >
                {" "}
                Minemi.ai offers a comprehensive suite of tools designed to
                enhance your financial well-being: -
              </p>
              <p style={{ marginTop: 10 }}></p>
              <div className="about-points">
                <p style={{ fontSize: 16, fontWeight: 600, color: "black" }}>
                  1.Credit Score Simplification:{" "}
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  {" "}
                  Helping you understand your credit report beyond just the
                  score and providing optimized EMI solutions on your terms.{" "}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  2.Credit Management:{" "}
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  Monitor your existing EMIs and receive tailored
                  recommendations for better credit solutions.
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  3.Credit Consolidation:
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  Combine multiple credits into one manageable payment.
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  4.Top-Up Options:
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  Access additional funds on favorable terms.
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  5.Credit Card Recommendations:
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  Find the best credit card offers based on your financial
                  profile.
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginTop: 10,
                  }}
                >
                  6.Credit Comparison:
                </p>
                <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
                  Compare various credit products to find the best fit for your
                  needs.
                </p>
              </div>
            </div>
          </div>
          <div className="img-bg" style={{ height: 350, weight: 180 }}>
            <img
              src={about}
              alt="About us"
              style={{ width: "100%", height: "100%", borderRadius: 20 }}
            />
          </div>
        </div>
        <div
          className="  whatwedo"
          style={{
            fontSize: "21px",
            fontWeight: "700",
            lineHeight: "23px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "rgba(89, 38, 109, 1)",
          }}
        >
          {" "}
          Our Values{" "}
        </div>
        <p style={{ marginTop: 10 }}></p>
        <div className="about-points">
          <p style={{ fontSize: 16, fontWeight: 600, color: "black" }}>
            1.Transparency:{" "}
          </p>
          <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
            {" "}
            We are committed to providing clear and straightforward information,
            ensuring you understand every aspect of your financial decisions{" "}
          </p>
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "black",
              marginTop: 10,
            }}
          >
            2.Customer Empowerment:{" "}
          </p>
          <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
            {" "}
            Our goal is to equip you with the knowledge and tools needed to take
            control of your finances.
          </p>
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "black",
              marginTop: 10,
            }}
          >
            3.Innovation:
          </p>
          <p style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}>
            We leverage cutting-edge technology to deliver the most accurate and
            helpful financial advice.
          </p>
        </div>
        <div
          className="  whatwedo"
          style={{
            fontSize: "21px",
            fontWeight: "700",
            lineHeight: "23px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "5%",
          }}
        >
          {" "}
          Our Story{" "}
        </div>
        <p
          className="about-points"
          style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}
        >
          Minemi.ai was born out of a desire to make financial services more
          accessible and transparent for everyone. Our founders, who have
          extensive experience in the banking and fintech sectors, saw a need
          for a platform that could simplify credit management and provide
          valuable insights. With this vision, Minemi.ai was created to bridge
          the gap between complex financial products and the everyday consumer.
        </p>
        <div
          className="  whatwedo"
          style={{
            fontSize: "21px",
            fontWeight: "700",
            lineHeight: "23px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "5%",
          }}
        >
          {" "}
          Our Approach{" "}
        </div>
        <p
          className="about-points"
          style={{ marginTop: "0.7%", fontWeight: 400, fontSize: 14 }}
        >
          We are dedicated to resolving financial problems by helping you get
          the best credit products available. Our platform simplifies the
          lending process, making it super easy for you to access and manage
          credit.
        </p>
      </div>
    </>
  );
}

export default AboutUs;
