import { useState } from "react";
// import { ChevronDown, ChevronUp, HelpCircle } from "lucide-react";
import FAQItem from "./FAQItem";
import { FaRegQuestionCircle } from "react-icons/fa";
const faqs = [
  {
    question: "What is a balance transfer, and how does it work?",
    answer:
      "A balance transfer allows you to switch your existing loan to a lower rate with another lender.",
  },
  {
    question: "How much can I save with MinEMI?",
    answer:
      "You can save up to ₹10,000/month depending on your loan amount and tenure.",
  },
  {
    question: "Does MinEMI charge any fees?",
    answer: "No. MinEMI earns from banks, not from customers.",
  },
  {
    question: "What documents are needed for a balance transfer?",
    answer:
      "Basic loan documents like sanction letter, repayment history, etc.",
  },
];

export default function FAQ() {
  return (
    <section className="py-16 bg-white w-100">
      <div className="flex items-center justify-center mb-4  gap-2 bg-white">
        <FaRegQuestionCircle
          style={{
            color: "#59266D",
            fontSize: "30px",
            fontWeight: "400",
            backgroundColor: "white",
          }}
        />
        <h2 className="text-3xl md:text-4xl font-bold text-center bg-white text-[#59266d]">
          Got Questions? We've Got You Covered.
        </h2>
      </div>
      <p
        className="text-xl text-center bg-white text-gray-600 mb-12"
        style={{
          color: "#4B5563",
          fontSize: "20px",
          lineHeight: "28px",
          fontWeight: "400",
        }}
      >
        Here are answers to the most common questions about lowering your EMI
        with Minemi.
      </p>
      <div className="container-fluid mx-auto px-4  bg-white">
        <div className="max-w-3xl mx-auto  bg-white">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
      <div className="text-center mt-12  bg-white">
        <button className="bg-[#59266d] text-white  px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors">
          Still Have Questions? Talk to Us!
        </button>
      </div>
    </section>
  );
}
