import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./PersonalLoanNewsDetails.css"; // Importing the CSS for styling
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet

function PlManageLoanDetails() {
  const { nslug } = useParams();
  const [newsDetails, setNewsDetails] = useState();
  const [news, setNews] = useState([]);
  const location = useLocation();
  // const { nslug } = location.state || {};

  const newsHandler = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    // console.log("nslug", nslug);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news_detail?nslug=${nslug}`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log("data", response.data.data.detail[0]);
        setNews(response.data.data.detail);
        setNewsDetails(response.data.data.detail[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const BlogDetailHandler = () => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/news_details`,
      data: { nslug: nslug },
    };
    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.detail[0]);
        setNewsDetails(response.data.data.detail[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    // BlogDetailHandler();
    newsHandler();
  }, []);

  useEffect(() => {
    // BlogDetailHandler();
    newsHandler();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="news-detail-container">
      <Helmet>
        <title>{newsDetails?.title}</title>
        <meta name="description" content={newsDetails?.meta_description} />
        <meta name="keywords" content={`news, ${newsDetails?.meta_tag}`} />
        {/* <link rel="icon" type="image/png" href="./mLogo.webp" /> */}
      </Helmet>

      {/* Main news content */}
      <div className="news-main-content">
        <h1 className="news-title">{newsDetails?.title}</h1>
        <p className="published-date">by {newsDetails?.author}</p>
        <p className="published-date">
          Published on: {formatDate(newsDetails?.created_at)}
        </p>
        <img
          src={
            newsDetails
              ? newsDetails?.image
              : "https://via.placeholder.com/820x340"
          }
          alt="Blog Banner"
          loading="lazy"
          className="news-banner"
        />
        <div className="news-description">
          <div dangerouslySetInnerHTML={{ __html: newsDetails?.description }} />
          {/* <p>{newsDetails?.description}</p> */}
        </div>
      </div>

      {/* Related blogs on the side */}
      <div className="related-blogs">
        <h3 className="related-title">Related Blogs</h3>
        {news.slice(0, 3)?.map((news) => (
          <div className="related-news-item" key={news.id}>
            <img
              src={news.image}
              alt={news.title}
              loading="lazy"
              className="related-news-image"
            />
            <p className="related-news-title">{news.title}</p>
            <div
              style={{ backgroundColor: "#FFFFFF" }}
              className="related-news-discription"
              dangerouslySetInnerHTML={{ __html: news.description }}
            />

            {/* <p className="related-news-discription">{news.description}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlManageLoanDetails;
