import React, { useEffect, useState, useContext } from "react";
// import illustration from "../../Assets/Images/Illustration22.webp";
import illustration from "../../../Assets/Images/Illustration.webp";
import toungeOut from "../../../Assets/Images/tongue-out.webp";
import { LuDot } from "react-icons/lu";
import "../EligiblityCriteria.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Breadcrumbs from "../Breadcrumbs";
import axios from "axios";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const SBIInterestRate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check Your SBI Bank Personal Loan Eligibility Instantly",
      pageDescription:
        "Easily check your SBI Bank personal loan eligibility online. Enjoy quick approvals, low interest rates, flexible repayment options, and instant application processing.",
      pageKeywords:
        "SBI Bank Personal Loan, Personal Loan Eligibility, SBI Loan Eligibility Calculator, Instant Loan Approval, SBI Personal Loan Online Application, Personal Loan EMI Calculator, Check SBI Loan Eligibility, Quick Personal Loan Approval, SBI Loan Interest Rates, Online Loan Eligibility Check, SBI Bank Loan Offers, Flexible Loan Options, Best Personal Loan Schemes, Loan Eligibility Criteria, Apply for SBI Personal Loan",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalLoan/badcredit/sbi-personal-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Check Your SBI Bank Personal Loan Eligibility Instantly</title>
        <meta
          name="description"
          content="Easily check your SBI Bank personal loan eligibility online. Enjoy quick approvals, low interest rates, flexible repayment options, and instant application processing."
        />
        <meta
          name="keywords"
          content="SBI Bank Personal Loan, Personal Loan Eligibility, SBI Loan Eligibility Calculator, Instant Loan Approval, SBI Personal Loan Online Application, Personal Loan EMI Calculator, Check SBI Loan Eligibility, Quick Personal Loan Approval, SBI Loan Interest Rates, Online Loan Eligibility Check, SBI Bank Loan Offers, Flexible Loan Options, Best Personal Loan Schemes, Loan Eligibility Criteria, Apply for SBI Personal Loan"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            <Link
              to={"/loanpage/personalloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/personalLoan/badcredit"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan Interest Rates
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              SBI Bank Personal Loan Eligibility
            </Link>{" "}
          </p>
        </div>
        <h1
          className="loanstatus-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          SBI Bank Personal Loan Eligibility
        </h1>
        <p
          className="loanstatus-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "24px",

            color: "#74589B",
            backgroundColor: "#E7E4F9",
          }}
        >
          Personal loans from SBI are among the most popular in India, catering
          to diverse financial needs with competitive interest rates, flexible
          tenures, and minimal documentation. This guide provides all the
          information you need to determine your eligibility and maximize your
          chances of approval.
        </p>
      </div>
      <div
        className="tracking-status"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
      >
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Eligibility Criteria for SBI Personal Loans
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
            color: "#1F1F1F",
            lineHeight: "20px",
          }}
        >
          The eligibility for an SBI personal loan depends on several parameters
          such as age, income, employment status, and credit history. Below is a
          detailed overview:
        </p>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Parameter
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Eligibility Requirement
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  - Salaried: 21–58 years <br />- Pensioners: Up to 76 years
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Minimum monthly income of ₹15,000 (may vary by applicant type
                  and location)
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Type
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Employment Type Salaried, self-employed, or pensioners
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Stability
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  At least 1 year in current employment or 2 years in total (for
                  salaried applicants)
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    border: "1px solid #ddd",
                    borderBottomLeftRadius: 18,
                  }}
                >
                  Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  700 or above preferred
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    border: "1px solid #ddd",
                    borderBottomLeftRadius: 18,
                  }}
                >
                  Pension Disbursal
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Pension Disbursal Pension must be credited into an SBI account
                  (for pensioners)
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="make-it-center increase-margin "
          style={{
            marginTop: "4%",
            marginBottom: "2%",
            padding: 24,
            background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
            borderRadius: 24,
          }}
        >
          <div
            style={{
              marginLeft: "4%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              gap: 24,
              borderRadius: 24,
              alignSelf: "center",
            }}
          >
            <p
              className="eligiblity-criteria"
              style={{
                fontFamily: "Archivo",
                color: "#59266D",
                fontSize: 40,
                backgroundColor: "transparent",
              }}
            >
              <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                Check Your Loan Eligibility Without{" "}
                <br className="brake-remove" /> Impacting Your Credit Score
              </i>
            </p>
            <a
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="eligiblity-buttons"
              style={{
                backgroundColor: "#553285",
                color: "#FFFFFF",
                width: "39%",
                borderRadius: 8,
                fontFamily: "Archivo",
                fontWeight: 500,
                fontSize: 18,
                paddingTop: 8,
                paddingBottom: 8,
                textAlign: "center",
              }}
            >
              Check Eligibility
            </a>
          </div>
          <div
            style={{
              width: 200,
              height: 195,
              backgroundColor: "transparent",
              marginRight: "4%",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              loading="lazy"
              alt="Check Eligibility"
            />
          </div>
        </div>

        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "4%",
          }}
        >
          Features That Enhance Your Eligibility
        </p>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Feature
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Impact on Eligibility
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  High Credit Score (700+)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Increases the likelihood of approval and may fetch lower
                  interest rates.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Stable Employment
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Applicants with consistent job history are seen as lower-risk
                  borrowers.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Low Debt-to-Income Ratio
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Reduces the risk for lenders, making you more eligible for
                  larger loan amounts.
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Co-Applicant with Good Credit
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Enhances eligibility and may allow access to higher loan
                  limits or better terms.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Check Your SBI Personal Loan Eligibility
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Steps to Check Eligibility Online
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Visit the{" "}
              <a href={"https://sbi.co.in/"} target="_blank">
                SBI Official Website
              </a>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Navigate to the “Loans” section and select “Personal Loans.”
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Use the Personal Loan Eligibility Calculator.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <LuDot />
              </span>
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Enter details such as income, existing EMIs, and loan requirements
              to get an instant eligibility report.
            </div>
          </div>
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          <span
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Offline Method:
          </span>{" "}
          Visit the nearest SBI branch with the necessary documents. Bank
          officials will assist in evaluating your eligibility.
        </p>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Eligibility Criteria by Applicant Type
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Applicant Type
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Key Criteria
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Salaried Individuals
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Regular income proof, employment with a reputed organization,
                  credit score above 700.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Self-Employed
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Minimum annual income as per ITR filings, stable business
                  operation for at least 2 years.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Pensioners
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Pension credited through SBI account, age below 76 years.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Documents Required for SBI Personal Loan Eligibility
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          To ensure smooth processing, gather the following documents before
          applying:
        </div>
        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Category
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Examples
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Identity Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Aadhaar, PAN Card, Passport, Voter ID.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Address Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Utility Bills, Rent Agreement, Aadhaar Card.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Latest Salary Slips, Form 16, or Income Tax Returns.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Proof
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Offer Letter, Appointment Letter, or Employer Certificate.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Photographs
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Recent passport-sized photographs.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Improve Your SBI Personal Loan Eligibility
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Improvement Strategy
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Enhance Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Maintain timely payments, reduce credit utilization, and
                  correct errors in your credit report.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Reduce Existing Debt
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Pay off smaller loans or EMIs to lower your debt-to-income
                  ratio.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Apply with a Co-Applicant
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Including a financially stable co-applicant can improve your
                  eligibility.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Choose a Lower Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Ensure that your requested loan aligns with your repayment
                  capacity and income level.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* minemi tip section */}
        <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div className="px-4 py-4" style={{ backgroundColor: "#e7e4f9" }}>
            <div
              className="px-3 py-2"
              style={{ backgroundColor: "#e7e4f9", padding: "30px " }}
            >
              <div
                className="d-flex gap-2 align-items-center"
                style={{
                  backgroundColor: "transparent",
                  marginBottom: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  gap: "20px",
                }}
              >
                <img
                  src={toungeOut}
                  alt="minemi tip image"
                  loading="lazy"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",

                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "#e7e4f9",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "20px",

                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  👉 “Before applying, analyze your current financial
                  obligations. Use MinEmi’s Credit Management Tool to assess
                  your debt-to-income ratio and ensure your loan application
                  aligns with your credit profile.”
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Benefits of SBI Personal Loans
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Benefit
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Low Interest Rates
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Starting at 10.40% p.a., one of the most competitive rates in
                  the market.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Flexible Repayment Tenure
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Choose from 6 to 72 months to customize your EMIs as per your
                  convenience.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Minimal Documentation
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Streamlined application process with basic document
                  requirements.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Quick Disbursal
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Loan amount credited within 24 hours of approval, ensuring
                  fast access to funds.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  High Loan Limits
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Avail personal loans up to ₹20 lakhs based on eligibility.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Snapshot of Key SBI Loan Parameters
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                    width: "30%",
                  }}
                >
                  Parameter
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age Limit
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  21–58 years (salaried), up to 76 years (pensioners).
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Minimum Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  ₹15,000 per month.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Maximum Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  ₹20 lakhs.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Repayment Tenure
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  6 to 72 months.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Interest Rate
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Starting at 10.40% p.a.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Processing Fee
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  1% of the loan amount + applicable taxes.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p
          style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
          className="mt-4"
        >
          For more information please check{" "}
          <a
            href={"https://sbi.co.in/web/personal-banking/loans/personal-loans"}
            target="_blank"
          >
            SBI Eligibility
          </a>
        </p>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              What is the minimum age for applying for an SBI personal loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The minimum age for salaried individuals is 21 years, while
              pensioners can apply up to the age of 76 years.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Can I apply for an SBI personal loan if I have a low credit score?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, but a low credit score may reduce your chances of approval or
              result in higher interest rates. A score above 700 is ideal for
              securing better terms.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Are pensioners eligible for SBI personal loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, pensioners are eligible provided their pension is credited
              through an SBI account.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Does SBI offer pre-approved personal loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, SBI offers pre-approved personal loans for select account
              holders based on their transaction history and credit profile.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How can I calculate my loan EMIs?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              You can use the{" "}
              <a to={"https://sbi.co.in/"} target="_blank">
                SBI EMI Calculator
              </a>{" "}
              available on the official website to estimate monthly payments.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What is the maximum loan amount I can apply for?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Eligible applicants can avail personal loans up to ₹20 lakhs,
              depending on their income and repayment capacity.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
        </div>

        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: "4%",
            marginBottom: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 24,
                backgroundColor: "transparent",
                marginTop: "3%",
              }}
            >
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#553285",
                  color: "#FFFFFF",
                  width: "18%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBIInterestRate;
