import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const TravelAndAirlineCredit = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Travel and Airline Credit Cards | Maximize Your Rewards on Flights and Travel",
      pageDescription:
        "Explore the best travel and airline credit cards in 2025. Earn rewards, discounts, and travel perks with top credit card offers from leading banks and airlines.",
      pageKeywords:
        "travel credit cards, airline credit cards, best travel credit cards, travel rewards cards, flight rewards, travel perks, airline miles, credit cards for frequent flyers, top travel credit cards, SBI travel credit card, HDFC airline credit card, ICICI travel rewards, earn airline miles, travel card benefits, best credit cards for travel, international travel credit cards",
      canonicalUrl:
        "https://minemi.ai/creditcard/travel-and-airline-credit-cards",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>
          Travel and Airline Credit Cards | Maximize Your Rewards on Flights and
          Travel
        </title>
        <meta
          name="description"
          content="Explore the best travel and airline credit cards in 2025. Earn rewards, discounts, and travel perks with top credit card offers from leading banks and airlines."
        />
        <meta
          name="keywords"
          content="travel credit cards, airline credit cards, best travel credit cards, travel rewards cards, flight rewards, travel perks, airline miles, credit cards for frequent flyers, top travel credit cards, SBI travel credit card, HDFC airline credit card, ICICI travel rewards, earn airline miles, travel card benefits, best credit cards for travel, international travel credit cards"
        />
        {/* <link rel="icon" type="image/png" href="../mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/creditcard"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Credit Cards
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Travel and Airline Credit Cards
            </Link>{" "}
          </p>
        </div>

        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Your Gateway to Exclusive Travel Rewards!
        </h1>

        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "24px",
          }}
        >
          Discover credit cards that transform your journeys with luxurious
          perks, travel rewards, and unparalleled benefits.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
        {/* table data */}
        <div id="types-of-bt">
          <div id="eligibility-criteria">
            <div
              className=""
              style={{
                marginTop: 30,
              }}
            >
              <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                Why Choose a Travel Credit Card?
              </h3>
              <h5
                style={{
                  color: "#1F1F1F",
                  fontSize: 16,
                  fontWeight: 400,
                  marginTop: 16,
                }}
              >
                The Perks of Traveling with the Right Card:
              </h5>
            </div>
          </div>

          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Benefit
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Earn Travel Rewards
                  </td>

                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Accumulate points or miles on flights, hotel bookings, and
                    travel-related expenses.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Complimentary Lounge Access
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Relax and refresh at airport lounges with complimentary
                    passes offered by premium travel cards.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Save on International Fees
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Avoid foreign transaction fees while shopping abroad.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Travel Insurance
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Get covered with travel insurance, including trip
                    cancellations, lost baggage, and medical emergencies.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Exclusive Discounts
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Enjoy partner discounts on hotels, airlines, car rentals,
                    and travel packages.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* table data */}
        <div id="types-of-bt">
          <div id="eligibility-criteria">
            <div
              className=""
              style={{
                marginTop: 40,
              }}
            >
              <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                Featured Travel Credit Cards
              </h3>
              <h5
                style={{
                  color: "#1F1F1F",
                  fontSize: 16,
                  fontWeight: 400,
                  marginTop: 16,
                }}
              >
                Top Cards for Travel Enthusiasts:
              </h5>
            </div>
          </div>

          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Card Name
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Issuer
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Annual Fee
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Key Benefits
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Apply Now
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    HDFC Regalia Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    HDFC Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹2,500
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Access to international and domestic airport lounges.{" "}
                    <br />- Earn 4 reward points for every ₹150 spent
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Apply Now
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    SBI Card PRIME
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    SBI
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹2,999
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Complimentary Priority Pass membership.
                    <br />- Earn 10X rewards on dining, groceries, and movies.
                    <br />- Travel concierge services available.
                  </td>

                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Apply Now
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Axis Magnus Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Axis Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹10,000
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Earn 12X Axis eDGE reward points on travel bookings.
                    <br />
                    - Complimentary domestic flight tickets upon spending
                    milestones.
                    <br />- Lounge access globally.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Apply Now
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    American Express Platinum Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    American Express
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹60,000
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Complimentary access to Centurion Lounges.
                    <br />
                    - 5X points on flights booked via Amex Travel.
                    <br />- Enjoy luxury hotel perks, including free nights.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Apply Now
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Citibank PremierMiles Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Citibank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹3,000
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Earn 10X rewards on airline bookings.
                    <br />
                    - Redeem miles on over 100+ airlines.
                    <br />- Points never expire, ensuring flexibility in
                    redemption.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Apply Now
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 24,
              paddingTop: "20px",
              lineHeight: 1.2,
            }}
          >
            Minemi’s Expert Advice for Travelers
          </h3>
          <h5
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 16,
              lineHeight: "20px",
              marginTop: 14,
              fontWeight: 400,
            }}
          >
            Minemi Tips for Travel Cards:
          </h5>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Leverage Accelerated Rewards:{" "}
                </span>
                Use travel cards like the Citibank PremierMiles Card to book
                flights and earn up to 10X rewards.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Opt for Global Lounges:{" "}
                </span>
                Choose cards with global lounge access, like the HDFC Regalia
                Credit Card, to relax during layovers.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Combine Points and Miles:{" "}
                </span>
                Pair a general travel card with a co-branded airline card for
                maximum mileage accrual and flexibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Pay Attention to Redemption Partners:{" "}
                </span>
                Select cards with diverse redemption options, such as American
                Express Platinum, for luxury travel perks.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Track Spending Categories:{" "}
                </span>
                Identify your frequent travel expenses and pick cards offering
                bonuses in those categories, like dining or flights.
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 24,
              paddingTop: "20px",
              lineHeight: 1.2,
            }}
          >
            Key Perks of Travel Credit Cards
          </h3>
          <h5
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 16,
              lineHeight: "20px",
              marginTop: 14,
              fontWeight: 400,
            }}
          >
            Popular Travel Benefits Explained:
          </h5>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Airport Lounge Access:{" "}
                </span>
                Complimentary access to lounges with Wi-Fi, snacks, and
                relaxation zones.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Airline Miles Programs:{" "}
                </span>
                Accumulate miles to redeem for free or discounted flights,
                upgrades, or other rewards.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Travel Insurance Coverage:{" "}
                </span>
                Get benefits like trip cancellation reimbursement, emergency
                medical assistance, and lost baggage protection.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  No Foreign Transaction Fees:{" "}
                </span>
                Avoid additional costs on purchases made abroad.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Concierge Services:{" "}
                </span>
                Access personal assistants to help with travel bookings,
                restaurant reservations, and more.
              </div>
            </div>
          </div>
        </div>

        {/* table data */}
        <div id="types-of-bt">
          <div id="eligibility-criteria">
            <div
              className=" pb-2 "
              style={{
                marginTop: 30,
              }}
            >
              <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                Exclusive Offers for Minemi Users
              </h3>
              <h5
                style={{
                  color: "#1F1F1F",
                  fontSize: 16,
                  lineHeight: "20px",
                  marginTop: 14,
                  fontWeight: 400,
                }}
              >
                Special Travel Card Offers on Minemi:
              </h5>
            </div>
          </div>

          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Offer
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Eligibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Welcome Bonus Points
                  </td>

                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Earn 20,000 bonus points on spending ₹50,000 in the first 90
                    days.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    HDFC Regalia, Axis Magnus
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Complimentary Domestic Flight Ticket
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Get a free domestic flight ticket on achieving annual spend
                    milestones.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    SBI Card PRIME, Axis Magnus
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Discounted Foreign Exchange Markups
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Enjoy 0% markup fees on all international purchases for the
                    first year.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Citibank PremierMiles, Amex Platinum
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Luxury Hotel Stays
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Avail complimentary stays at partner luxury hotels upon
                    reaching specific spend tiers.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Amex Platinum, HDFC Diners Black
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <h3
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 24,
              lineHeight: 1.2,
              paddingTop: "20px",
            }}
          >
            From Earning to Redeeming Rewards
          </h3>
          <h5
            style={{
              fontFamily: "Archivo",
              color: "#1F1F1F",
              fontSize: 16,
              fontWeight: 400,
              paddingTop: "10px",
            }}
          >
            How Travel Rewards Work:
          </h5>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Earning Points or Miles:{" "}
                </span>
                Get points for every rupee spent. Some cards offer bonuses on
                specific categories like travel, dining, or online bookings.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Redeeming Rewards:{" "}
                </span>
                Redeem points or miles for flights, hotel stays, gift cards, or
                even cashback. Check partner networks for better value.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Maximizing Redemption:{" "}
                </span>
                Use miles during off-peak travel seasons to save costs and get
                the most value.
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              How do I choose the best travel card?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Consider your travel habits. If you fly often, choose a card
              offering airline miles or lounge access.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Are travel credit cards worth the annual fee?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              If you travel frequently, the perks like lounge access, travel
              insurance, and rewards can offset the annual fee.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can I use travel rewards for international trips?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, most travel cards offer global redemption options for
              flights, hotels, and more.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What happens to unused travel rewards?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Some rewards expire after a specific period, while others (like
              Citibank PremierMiles) never expire.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 64,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <Link
                to="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  backgroundColor: "#553285",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelAndAirlineCredit;
