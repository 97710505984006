import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import { useNavigate } from "react-router-dom";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper"; // Assuming Item is a Paper component
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../MetadataContext";

const DigitalLoan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery("(max-width:756px)");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  const [manageData, setManageData] = useState([]);
  const blogs = [
    {
      id: 1,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ManageDL-1737200072300.webp",
      title:
        "How to Manage Your Loan Against Property Digital Loan: Tips for Existing Borrowers",
      writer: "Aditi Malhotra",
      description:
        "A guide for those with loan against property on managing payments, understanding interest rates, and refinancing options.",
      naviLink:
        "/loanpage/digital-loan/how-to-manage-your-loan-against-property-digital-loan",
    },
    {
      id: 2,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/CompareDL-1737200243110.webp",
      title:
        "Loan Against Shares and Mutual Funds: How to Top-Up or Refinance Digitally",
      writer: "Aditi Malhotra",
      description:
        "Advice on refinancing or taking out a top-up loan against shares and mutual funds, focusing on improving loan terms and increasing the loan amount.",
      naviLink: "/loanpage/digital-loan/loan-against-shares-and-mutual-funds",
    },
    {
      id: 3,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/FinancialFlexibilityDL-1737200423548.webp",
      title:
        "Using Your Credit Card Personal Loan for Financial Flexibility: Strategies for Current Borrowers",
      writer: "Vikalp Shukla",
      description:
        "Tips and strategies for current personal loan on credit card holders to make the most of their loans, including interest management and additional borrowing.",
      naviLink:
        "/loanpage/digital-loan/using-your-credit-card-personal-loan-for-financial-flexibility",
    },
    {
      id: 4,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Top-UpLoanDL-1737200561467.webp",
      title:
        "How to Secure a Top-Up Loan Digitally: Process, Benefits, and Eligibility",
      writer: "Aditi Malhotra",
      description:
        "A detailed article for existing borrowers on how they can request a top-up loan digitally, including the benefits and documentation needed.",
      naviLink: "/loanpage/digital-loan/how-to-secure-a-top-up-loan-digitally",
    },
    {
      id: 5,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/RepayingYourDigitalLoans-1737200652597.webp",
      title:
        "Repaying Your Digital Loans: Methods to Reduce Interest and Minimize Costs",
      writer: "Vikalp Shukla",
      description:
        "Focuses on tips for minimizing interest costs and reducing the overall loan amount, including prepayment and consolidation strategies for existing borrowers.",
      naviLink: "/loanpage/digital-loan/repaying-your-digital-loans",
    },
    {
      id: 6,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/DigitalLoanRepayments-1737200811973.webp",
      title:
        "Monitoring Your Digital Loan Repayments: Tools to Track and Manage Your Loan",
      writer: "Vikalp Shukla",
      description:
        "A resourceful article discussing tools and apps that existing borrowers can use to track their loan repayments, stay on top of due dates, and manage their finances.",
      naviLink:
        "/loanpage/digital-loan/monitoring-your-digital-loan-repayments",
    },
  ];

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.latestnews);
        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.manageloan);
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Access Digital Loans Instantly with Minemi",
      pageDescription:
        "Explore digital loans with Minemi! From personal to business and emergency loans, we offer flexible and instant solutions tailored to your needs",
      pageKeywords:
        "digital loan, digital loan minemi, online loans, quick loans, personal loans, business loans, emergency loans, flexible loans, instant loans, digital lending, loan offers",
      canonicalUrl: "https://minemi.ai/loanpage/digital-loan",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Access Digital Loans Instantly with Minemi</title>
        <meta
          name="description"
          content="Explore digital loans with Minemi! From personal to business and emergency loans, we offer flexible and instant solutions tailored to your needs"
        />
        <meta
          name="keywords"
          content="digital loan, digital loan minemi, online loans, quick loans, personal loans, business loans, emergency loans, flexible loans, instant loans, digital lending, loan offers"
        />
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {" "}
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Digital Loan
            </Link>
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Digitals Loans{" "}
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 24,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "30px",
          }}
        >
          Compare digital loan offers to find the perfect loan for personal
          needs, business growth, or managing emergencies—all from the comfort
          of your home.
        </p>
      </div>
      <div
        className="make-wrap"
        style={{
          marginTop: 56,
          marginLeft: "4%",
          marginRight: "4%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 16,
        }}
      >
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 10,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/loanpage/digital-loan/digital-loan-overdraft-facility");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Digital Loan Overdraft{" "}
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Flexible credit for your unexpected needs.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={money}
                alt="Overdraft"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Manage short-term expenses with ease.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/loanpage/digital-loan/loan-against-property");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Loan Against Property (LAP)
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Unlock the value of your property.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Ratio}
                alt="LAP"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Secure funds with attractive interest rates.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/loanpage/digital-loan/personal-loan-on-credit-cards");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Personal Loan on Credit Cards
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Instant cash against your credit limit.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Group}
                alt="Credit Cards"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Simplify finances with a click.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(
              "/loanpage/digital-loan/loan-against-shares-and-mutual-funds"
            );
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Loan Against Shares & Mutual Funds
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Leverage your investments for liquidity.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={creditScore}
                alt="loan against shares"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Stay invested while accessing funds.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/loanpage/digital-loan/top-up-loans");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Digital Loan Top-Up
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Increase your loan limit seamlessly.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Vector}
                alt="top up"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Top-up your current loan for extra funds.
            </p>
          </p>
        </div>
        {/* <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
          onClick={() => {
            navigate("/digital-loan/digital-loan-overdraft-facility");
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
            EMI Calculator
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Calculate your monthly EMI.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Bill}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              {" "}
              Use our car loan EMI calculator to estimate your monthly
              repayments.
            </p>
          </p>
        </div> */}
      </div>
      <div
        style={{
          maginTop: 64,
          marginLeft: "4%",
          marginRight: "4%",
          marginTop: 32,
        }}
      >
        <h2
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1.2,
          }}
        >
          Qualify for a Digital Loan
        </h2>
      </div>
      <div
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
        className="loan-list-container px-0"
      >
        {blogs?.map((blog) => (
          <div
            className="loan-container"
            onClick={() => navigate(blog.naviLink)}
            key={blog.id}
          >
            <a href={blog.link}>
              <img
                src={blog.image}
                alt={blog.heading}
                loading="lazy"
                className="loan-image d-none d-md-block"
              />
              <h3
                className="loan-heading"
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 600,
                  fontSize: 18,
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              >
                {blog.title}
              </h3>
              <p
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  color: "#8a8a8a",
                  fontSize: 14,
                }}
                className="mt-2 mb-1"
              >
                by {blog.writer}
              </p>
              <div
                className="loan-subheading"
                dangerouslySetInnerHTML={{
                  __html: blog.description,
                }}
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#B1B1B1",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              />
            </a>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          Manage your loan
        </h3>
        <div style={{ marginTop: 40, marginBottom: 32 }}>
          <Grid container spacing={5}>
            {manageData
              .filter((d) => d.category === "Digital Loan")
              .slice(0, 1) // Adjust slice range based on your needs
              .map((d) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Item style={{ border: 0, boxShadow: "0px 0px" }}>
                      <Link
                        to={`/loanpage/digital-loan/manageloan_detail/${d.mslug}`}
                        state={{
                          mslug: d?.mslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                          }}
                        >
                          <div style={{ height: "auto" }}>
                            <img
                              src={d.image}
                              alt={d.title}
                              style={{
                                width: "100%",
                                height: isVerySmallScreen ? "100%" : "400px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              loading="lazy"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            <p
                              className="manage_latest_link"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d?.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d?.author}
                            </p>

                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: 1.3,
                                color: "grey",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  d?.description && d.description.length > 100
                                    ? `${d.description.slice(0, 100)}...`
                                    : d?.description || "",
                              }}
                            ></p>
                          </div>
                        </div>
                      </Link>
                    </Item>
                  </Grid>
                );
              })}

            <Grid item xs={12} md={6}>
              <Item
                style={{
                  border: 0,
                  boxShadow: "0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 32,
                  backgroundColor: "#F6F6F6",
                }}
              >
                {manageData
                  .filter((d) => d.category === "Digital Loan")
                  .slice(1)
                  .map((d) => (
                    <div key={d.id}>
                      {d.category === "Digital Loan" && (
                        <Link
                          to={`/loanpage/digital-loan/manageloan_detail/${d?.mslug}`}
                          state={{
                            mslug: d.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 12,
                            }}
                          >
                            <div
                              style={{
                                // width: "100%",
                                minWidth: isVerySmallScreen
                                  ? 80
                                  : isMediumScreen
                                  ? 120
                                  : 150,
                                maxWidth: 190,
                              }}
                            >
                              <img
                                src={d.image}
                                alt={d.title}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  // maxWidth: "250px",
                                  height: isVerySmallScreen
                                    ? "50px"
                                    : isMediumScreen
                                    ? "80px"
                                    : "110px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                  objectPosition: "center",
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 3,
                              }}
                            >
                              <p
                                className="manage_latest_link m-0 pt-0 pt-sm-3"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d.author}
                              </p>
                              {/* <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    d?.description && d.description.length > 90
                                      ? `${d.description.slice(0, 90)}...`
                                      : d?.description || "",
                                }}
                              ></p> */}
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 40 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          The Latest
        </h3>
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
          {newsData
            .filter((item) => item.category === "Digital Loan")
            .map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                {item.category === "Digital Loan" && (
                  <Item style={{ boxShadow: "0px 0px", marginBottom: 24 }}>
                    <Link
                      to={`/loanpage/digital-loan/news_detail/${item.nslug}`}
                      state={{
                        nslug: item.nslug,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 12,
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            // width: "100%",
                            minWidth: isVerySmallScreen
                              ? 80
                              : isMediumScreen
                              ? 120
                              : 150,
                            maxWidth: 190,
                          }}
                        >
                          <img
                            src={item.image}
                            alt={item.title}
                            loading="lazy"
                            style={{
                              width: "100%",
                              // maxWidth: "250px",
                              height: isVerySmallScreen
                                ? "50px"
                                : isMediumScreen
                                ? "80px"
                                : "110px",
                              objectFit: "cover",

                              borderRadius: "5px",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            alignSelf: "center",
                          }}
                        >
                          <p
                            style={{ fontSize: 18, fontWeight: 400 }}
                            className="manage_latest_link m-0"
                          >
                            {item.title}
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "grey",
                            }}
                            className="m-0"
                          >
                            by {item.author}
                          </p>
                          {/* <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: 1.3,
                              color: "grey",
                              display: "-webkit-box",
                              WebkitLineClamp: 2, // Limit to 2 lines
                              WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p> */}
                        </div>
                      </div>
                    </Link>
                  </Item>
                )}
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default DigitalLoan;
