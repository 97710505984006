import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/Header/NavBar";
import Login from "./Pages/LoginSignUp/Login";
import Otp from "./Pages/LoginSignUp/Otp";
import ProvideDetails from "./Pages/LoginSignUp/ProvideDetails";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import FooterUpdated from "./Components/Footer/FooterUpdated";
import HomeAfterLogin from "./Pages/Home/HomeAfterLogin";
import AboutUs from "./Pages/Home/AboutUs";
import ContactUs from "./Pages/Home/ContactUs";
import Confirm from "./Pages/Home/Confirm";
import UpdateProfile from "./Pages/Home/UpdateProfile";
import ConsolidDetail from "./Pages/Home/ConsolidDetail";
import TopUpDetail from "./Pages/Home/TopUpDetail";
import LowerEmiDetail from "./Pages/Home/LowerEmiDetail";
import Form from "./Pages/Home/Form";
import Status from "./Pages/Home/Status";
import Loan from "./Pages/Home/Loan";
import LowerEmiDetailPage from "./Pages/Home/LowerEmiDetailPage";
import Profession from "./Pages/Home/Profession";
import LoanEdit from "./Pages/Home/LoanEdit";
import Goal from "./Pages/Home/Goal";
import Test from "./Pages/Home/Test";
import Form2 from "./Pages/Home/Form2";
import Statuss from "./Pages/Home/Statuss";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import TermsandCondition from "./Pages/Home/TermsandCondition";
import ExperianConsent from "./Pages/Home/ExperianConsent";
import { isMobile } from "mobile-device-detect";
import GrievanceAddressal from "./Pages/Home/GrievanceAddressal";
import BalanceTransfer from "./Pages/Home/BalanceTransfer";
import Consolidate from "./Pages/Home/Consolidate";
import Topup from "./Pages/Home/Topup";
import Blog from "./Pages/Home/Blog";
import BlogDetail from "./Pages/Home/BlogDetail";
import PersonalLoan from "./Pages/StaticPage/PersonalLoan";
import BadCredit from "./Pages/StaticPage/BadCredit";
import Homeloan from "./Pages/StaticPage/Homeloan";
import CreditCard from "./Pages/StaticPage/CreditCard";
import Carloan from "./Pages/StaticPage/Carloan";
import Faq from "./Pages/StaticPage/Faq";
import EligiblityCriteria from "./Pages/StaticPage/EligiblityCriteria";
import HdfcEligiblity from "./Pages/StaticPage/HdfcEligiblity";
import LoanTracking from "./Pages/StaticPage/LoanTracking";
import LoanPage from "./Pages/StaticPage/LoanPage";
import AxisEligiblity from "./Pages/StaticPage/AxisEligiblity";
import SbiEligiblity from "./Pages/StaticPage/SbiEligiblity";
import IciciEligiblity from "./Pages/StaticPage/IciciEligiblity";
import PersonalTopup from "./Pages/StaticPage/PersonalTopup";
import Typesofpersonalloan from "./Pages/StaticPage/Typesofpersonalloan";
import BuildNavbar from "./Components/Header/BuildNavbar";
import IndusindEligibility from "./Pages/StaticPage/IndusiandEligibility";
import HomeLoanEligiblityCriteria from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanEligiblityCriteria";
import HomeLoanHdfcEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanHdfcEligibility";
import TypesOfHomeLoan from "./Pages/StaticPage/HomeLoanEligibilityCriteria/TypesOfHomeLoan";
import HomeLoanTopUp from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanTopUp";
import NRIHomeLoan from "./Pages/StaticPage/HomeLoanEligibilityCriteria/NRIHomeLoan";
import HomeLoanBalanceTransfer from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanBalanceTransfer";
import HomeLoanAxisEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanAxisEligibility";
import HomeLoanICICIEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanICICIEligibility";
import HomeLoanSbiEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanSbiEligibility";
import PersonalLoanNewsDetails from "./Pages/StaticPage/PersonalLoanNewsDetails";
import HLCompareInterestRates from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HLCompareInterestRates";
import DigitalLoan from "./Pages/StaticPage/DigitalLoan";
import PlManageLoanDetails from "./Pages/StaticPage/PlManageLoansDetails";
import CarLoanEligibilityCriteria from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanEligibilityCriteria";
import CarLoanCompareOffers from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanCompareOffers";
import CarLoanTopUp from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanTopUp";
import UsedCarLoan from "./Pages/StaticPage/CarLoanEligibilityCriteria/UsedCarLoan";
import CarLoanStatusTracking from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanStatusTracking";
import DigitalLoanOverdraft from "./Pages/StaticPage/DigitalLoan/DigitalLoanOverdraft";
import GoldLoan from "./Pages/StaticPage/GoldLoan";
import GoldLoanEligibilityCriteria from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanEligibilityCriteria";
import CarLoanHDFCeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanHDFCeligibility";
import CarLoanAXISeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanAXISeligibility";
import CarLoanSBIeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanSBIeligibility";
import CarLoanICICIeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanICICIeligibility";
import ArticleOne from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleOne";
import ArticleTwo from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleTwo";
import ArticleThree from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleThree";
import ArticleFour from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleFour";
import ArticleFive from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleFive";
import ArticleSix from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleSix";
import ArticleSeven from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleSeven";
import DLArticleOne from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleOne";
import DLArticleTwo from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleTwo";
import DLArticleThree from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleThree";
import DLArticleFour from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleFour";
import DLArticleFive from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleFive";
import DLArticleSix from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleSix";
import GoldLoanArticleOne from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleOne";
import GoldLoanArticleTwo from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleTwo";
import GoldLoanArticleThree from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleThree";
import GoldLoanArticleFour from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleFour";
import GoldLoanArticleFive from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleFive";
import GoldLoanArticleSix from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleSix";
import HomeArticleOne from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanArticles/HomeArticleOne";
import LoanArticleOne from "./Pages/StaticPage/LoanPageArticles/LoanArticleOne";
import LoanArticleTwo from "./Pages/StaticPage/LoanPageArticles/LoanArticleTwo";
import LoanArticleThree from "./Pages/StaticPage/LoanPageArticles/LoanArticleThree";
import HomeArticleTwo from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanArticles/HomeArticleTwo";
import LoanArticleFour from "./Pages/StaticPage/LoanPageArticles/LoanArticleFour";
import GoldLoanInterestRate from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanInterestRate";
import GoldLoanProcedure from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanProcedure";
import GoldLoanCompanies from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanCompanies";
import GoldLoanOverdraft from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanOverdraft";
import GoldLoanTopUp from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanTopUp";
import GoldLoanBulletRepayment from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanBulletRepayment";
import GoldLoanBalanceTransfer from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanBalanceTransfer";
import TypesOfGoldLoan from "./Pages/StaticPage/GoldLoanEligibilityCriteria/TypesOfGoldLoan";
import PersonalLoanOnCreditCards from "./Pages/StaticPage/DigitalLoan/PersonalLoanOnCreditCards";
import DigitalLoanAgainstShares from "./Pages/StaticPage/DigitalLoan/DigitalLoanAgainstShares";
import DigitalLoanAgainstProperty from "./Pages/StaticPage/DigitalLoan/DigitalLoanAgainstProperty";
import DigitalLoanTopUp from "./Pages/StaticPage/DigitalLoan/DigitalLoanTopUp";
import PLArticleOne from "./Pages/StaticPage/PersonalLoanArticles/PLArticleOne";
import CreditCardPage from "./Pages/StaticPage/CreditCardPages/CreditCardPage";
import BalanceTransferCreditCards from "./Pages/StaticPage/CreditCardPages/BalanceTransferCreditCards";
import TravelAndAirlineCredit from "./Pages/StaticPage/CreditCardPages/TravelAndAirlineCredit";
import CreditCardRewards from "./Pages/StaticPage/CreditCardPages/CreditCardRewards";
import LoanStatusTracking from "./Pages/StaticPage/CreditCardPages/LoanStatusTracking";
import HDFCInterestRate from "./Pages/StaticPage/PLInterestRate/HDFCInterestRate";
import SBIInterestRate from "./Pages/StaticPage/PLInterestRate/SBIInterestRate";
import ICICIInterestRate from "./Pages/StaticPage/PLInterestRate/ICICIInterestRate";
import AXISInterestRate from "./Pages/StaticPage/PLInterestRate/AXISInterestRate";
import BAJAJInterestRate from "./Pages/StaticPage/PLInterestRate/BAJAJInterestRate";
import CCArticleOne from "./Pages/StaticPage/CreditCardArticles/CCArticleOne";
import GoldLoanNewsDetails from "./Pages/StaticPage/GoldLoanNewsDetails";
import GoldLoanManageLoanDetails from "./Pages/StaticPage/GoldLoanManageLoanDetails";
import CarLoanNewsDetails from "./Pages/StaticPage/CarLoanNewsDetails";
import CarLoanManageLoanDetails from "./Pages/StaticPage/CarLoanManageLoanDetails";
import DigitalLoanNewsDetails from "./Pages/StaticPage/DigitalLoanNewsDetails";
import DigitalLoanManageLoanDetails from "./Pages/StaticPage/DigitalLoanManageLoanDetails";
import LoanNewsDetails from "./Pages/StaticPage/LoanNewsDetails";
import LoanManageLoanDetails from "./Pages/StaticPage/LoanManageLoanDetails";
import CreditCardNewsDetails from "./Pages/StaticPage/CreditCardNewsDetails";
import CreditCardManageLoan from "./Pages/StaticPage/CreditCardManageLoan";
import HomeLoanManageLoan from "./Pages/StaticPage/HomeLoanManageLoan";
import HomeLoanNewsDetails from "./Pages/StaticPage/HomeLoanNewsDetails";
import CCArticleTwo from "./Pages/StaticPage/CreditCardArticles/CCArticleTwo";
import CCArticleThree from "./Pages/StaticPage/CreditCardArticles/CCArticleThree";
import CCArticleFour from "./Pages/StaticPage/CreditCardArticles/CCArticleFour";
import CreditScore from "./Pages/CreditScore/CreditScore";
import LowerEmiStaticPage from "./Pages/LowerEmi/LowerEmiStaticPage";
// import { Helmet } from "react-helmet";

const App = () => {
  const [isMobile, setIsMobile] = useState(true);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null) {
      if (!isMobile) {
        //DESKTOP DETECTED LOGOUT USER
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      } else {
        return () => {};
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div>
      {/* <Helmet>
        <title>Personal Loans: Tailored for Your Financial Needs</title>
        <meta
          name="description"
          content="Discover flexible personal loan options designed to meet your unique financial needs. Get quick approvals, competitive rates, and tailored solutions today!"
        />
        <meta
          name="keywords"
          content="personal loans, financial needs, flexible loans, quick loan approval, competitive loan rates, tailored loan solutions, personal financing, loan options, online loans, customized loans"
        />
      </Helmet> */}
      <Router scrollRestoration={true}>
        <NavBar />
        {/* <BuildNavbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adcampaign" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/confirmloan" element={<LoanEdit />} />
          <Route path="/savingopportunity" element={<LowerEmiDetailPage />} />
          <Route path="/details" element={<LowerEmiDetail />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/dashboard" element={<HomeAfterLogin />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/providedetails" element={<ProvideDetails />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/loanoverview" element={<Loan />} />
          <Route path="/test" element={<Test />} />
          <Route path="/topupdetail" element={<TopUpDetail />} />
          <Route path="/personalloanform" element={<Form />} />
          <Route path="/homeloanform" element={<Form2 />} />
          <Route path="/applicationstatus" element={<Statuss />} />
          <Route path="/statusdetail" element={<Status />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms&condition" element={<TermsandCondition />} />
          <Route path="/experianconsent" element={<ExperianConsent />} />
          <Route path="/GrievanceAddressal" element={<GrievanceAddressal />} />
          <Route path="/balancetransfer" element={<BalanceTransfer />} />
          <Route path="/loanconsolidation" element={<Consolidate />} />
          <Route path="/topup" element={<Topup />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogDetail/:slug" element={<BlogDetail />} />
          <Route path="/loanpage/personalLoan" element={<PersonalLoan />} />

          {/* Personal Loan News and Manage Loan */}
          <Route
            path="/loanpage/personalLoan/manageloan_detail/:mslug"
            element={<PersonalLoanNewsDetails />} //
          />
          <Route
            path="/loanpage/personalLoan/news_detail/:nslug"
            element={<PlManageLoanDetails />}
          />

          {/* Gold Loan News and Manage Loan */}
          <Route
            path="/loanpage/gold-loan/news_detail/:nslug"
            element={<GoldLoanNewsDetails />}
          />
          <Route
            path="/loanpage/gold-loan/manageloan_detail/:mslug"
            element={<GoldLoanManageLoanDetails />}
          />

          {/* Car Loan News and Manage Loan */}
          <Route
            path="/loanpage/carloan/news_detail/:nslug"
            element={<CarLoanNewsDetails />}
          />
          <Route
            path="/loanpage/carloan/manageloan_detail/:mslug"
            element={<CarLoanManageLoanDetails />}
          />

          {/* Home Loan News and Manage Loan  */}
          <Route
            path="/loanpage/homeloan/news_detail/:nslug"
            element={<HomeLoanNewsDetails />}
          />
          <Route
            path="/loanpage/homeloan/manageloan_detail/:mslug"
            element={<HomeLoanManageLoan />}
          />

          {/* Digital Loan News and Manage Loan */}
          <Route
            path="/loanpage/digital-loan/news_detail/:nslug"
            element={<DigitalLoanNewsDetails />}
          />
          <Route
            path="/loanpage/digital-loan/manageloan_detail/:mslug"
            element={<DigitalLoanManageLoanDetails />}
          />

          {/* Loan Page */}
          <Route
            path="/loanpage/news_detail/:nslug"
            element={<LoanNewsDetails />}
          />
          <Route
            path="/loanpage/manageloan_detail/:mslug"
            element={<LoanManageLoanDetails />}
          />

          {/* Credit Card News and Manage Credit */}

          <Route
            path="/creditcard/news_detail/:nslug"
            element={<CreditCardNewsDetails />}
          />
          <Route
            path="/creditcard/manageloan_detail/:mslug"
            element={<CreditCardManageLoan />}
          />

          <Route path="/loanpage/homeloan" element={<Homeloan />} />
          <Route
            path="/loanpage/personalLoan/badcredit"
            element={<BadCredit />}
          />
          <Route path="/creditcard" element={<CreditCard />} />
          <Route path="/loanpage/carloan" element={<Carloan />} />
          <Route path="/loanpage" element={<LoanPage />} />
          <Route path="/faq" element={<Faq />} />
          {/* Loan Articles */}
          <Route
            path="loanpage/loan-get-pre-approved"
            element={<LoanArticleOne />}
          />
          <Route
            path="loanpage/loan-interest-rate-comparison"
            element={<LoanArticleTwo />}
          />
          <Route
            path="loanpage/7-key-factors-lenders-consider-before-approving-your-loan-application-in-india"
            element={<LoanArticleThree />}
          />
          <Route
            path="loanpage/essential-tips-for-securing-online-loans"
            element={<LoanArticleFour />}
          />

          {/* Personal Loan*/}

          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria"
            element={<EligiblityCriteria />}
          />
          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria/hdfc-loan-eligiblity"
            element={<HdfcEligiblity />}
          />
          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria/axis-loan-eligiblity"
            element={<AxisEligiblity />}
          />
          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria/sbi-loan-eligiblity"
            element={<SbiEligiblity />}
          />
          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria/icici-loan-eligiblity"
            element={<IciciEligiblity />}
          />
          <Route
            path="/loanpage/personalLoan/loan-eligibilty-criteria/indusind-loan-eligiblity"
            element={<IndusindEligibility />}
          />
          <Route
            path="/loanpage/personalLoan/loan-tracking"
            element={<LoanTracking />}
          />
          <Route
            path="/loanpage/personalLoan/personal-topup"
            element={<PersonalTopup />}
          />
          <Route
            path="/loanpage/personalLoan/types-of-personalloan"
            element={<Typesofpersonalloan />}
          />

          {/* PL Compare Interest Rate Table */}

          <Route
            path="/loanpage/personalLoan/badcredit/hdfc-bank-personal-loan-eligibility"
            element={<HDFCInterestRate />}
          />
          <Route
            path="/loanpage/personalLoan/badcredit/sbi-personal-loan-eligibility"
            element={<SBIInterestRate />}
          />
          <Route
            path="/loanpage/personalLoan/badcredit/icici-bank-personal-loan-eligibility"
            element={<ICICIInterestRate />}
          />
          <Route
            path="/loanpage/personalLoan/badcredit/axis-bank-personal-loan-eligibility"
            element={<AXISInterestRate />}
          />
          <Route
            path="/loanpage/personalLoan/badcredit/bajaj-finserv-bank-personal-loan-eligibility"
            element={<BAJAJInterestRate />}
          />

          {/* personal loan article */}
          <Route
            path="/loanpage/personalLoan/personal-loan-prepayment-and-foreclosure"
            element={<PLArticleOne />}
          />

          {/* Home Loan  */}

          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria"
            element={<HomeLoanEligiblityCriteria />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-compare-interest-rates"
            element={<HLCompareInterestRates />}
          />
          <Route
            path="/loanpage/homeloan/types-of-home-loan"
            element={<TypesOfHomeLoan />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-topup"
            element={<HomeLoanTopUp />}
          />
          <Route
            path="/loanpage/homeloan/nri-home-loan"
            element={<NRIHomeLoan />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-balance-transfer"
            element={<HomeLoanBalanceTransfer />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/hdfc-home-loan-eligibility"
            element={<HomeLoanHdfcEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/axis-home-loan-eligibility"
            element={<HomeLoanAxisEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/icici-home-loan-eligibility"
            element={<HomeLoanICICIEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/sbi-home-loan-eligibility"
            element={<HomeLoanSbiEligibility />}
          />
          {/* Home Loan Articles */}
          <Route
            path="/loanpage/homeloan/home-loan-documents-required"
            element={<HomeArticleOne />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-comparison-guide-in-india"
            element={<HomeArticleTwo />}
          />

          {/* Car Loan Eligibility */}
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria"
            element={<CarLoanEligibilityCriteria />}
          />
          <Route
            path="/loanpage/carloan/car-loan-compare-offers"
            element={<CarLoanCompareOffers />}
          />
          <Route
            path="/loanpage/carloan/car-loan-top-up"
            element={<CarLoanTopUp />}
          />
          <Route
            path="/loanpage/carloan/used-car-loan"
            element={<UsedCarLoan />}
          />
          <Route
            path="/loanpage/carloan/car-loan-status-tracking"
            element={<CarLoanStatusTracking />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/hdfc-car-loan-eligibility"
            element={<CarLoanHDFCeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/axis-car-loan-eligibility"
            element={<CarLoanAXISeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/sbi-car-loan-eligibility"
            element={<CarLoanSBIeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/icici-car-loan-eligibility"
            element={<CarLoanICICIeligibility />}
          />

          {/* Car Loan Articles */}
          <Route
            path="/loanpage/carloan/10-things-to-know-before-applying-for-a-car-loan"
            element={<ArticleOne />}
          />
          <Route
            path="/loanpage/carloan/should-you-choose-a-fixed-or-floating-interest-rate-for-your-car-loan"
            element={<ArticleTwo />}
          />
          <Route
            path="/loanpage/carloan/down-payment-strategies"
            element={<ArticleThree />}
          />
          <Route
            path="/loanpage/carloan/top-5-factors-that-affect-your-car-loan-eligibility"
            element={<ArticleFour />}
          />
          <Route
            path="/loanpage/carloan/comparing-car-loan-offers"
            element={<ArticleFive />}
          />
          <Route
            path="/loanpage/carloan/understanding-the-impact-of-credit-scores-on-car-loan-approval"
            element={<ArticleSix />}
          />
          <Route
            path="/loanpage/carloan/pre-owned-vs-new-car-loans"
            element={<ArticleSeven />}
          />

          {/* Digital Loan */}
          <Route path="/loanpage/digital-loan" element={<DigitalLoan />} />
          <Route
            path="/loanpage/digital-loan/digital-loan-overdraft-facility"
            element={<DigitalLoanOverdraft />}
          />
          <Route
            path="/loanpage/digital-loan/personal-loan-on-credit-cards"
            element={<PersonalLoanOnCreditCards />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-shares-and-mutual-funds"
            element={<DigitalLoanAgainstShares />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-property"
            element={<DigitalLoanAgainstProperty />}
          />
          <Route
            path="/loanpage/digital-loan/top-up-loans"
            element={<DigitalLoanTopUp />}
          />

          {/* Digital Loan Article */}
          <Route
            path="/loanpage/digital-loan/how-to-manage-your-loan-against-property-digital-loan"
            element={<DLArticleOne />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-shares-and-mutual-funds"
            element={<DLArticleTwo />}
          />
          <Route
            path="/loanpage/digital-loan/using-your-credit-card-personal-loan-for-financial-flexibility"
            element={<DLArticleThree />}
          />
          <Route
            path="/loanpage/digital-loan/how-to-secure-a-top-up-loan-digitally"
            element={<DLArticleFour />}
          />
          <Route
            path="/loanpage/digital-loan/repaying-your-digital-loans"
            element={<DLArticleFive />}
          />
          <Route
            path="/loanpage/digital-loan/monitoring-your-digital-loan-repayments"
            element={<DLArticleSix />}
          />

          {/* Gold Loan */}
          <Route path="/loanpage/gold-loan" element={<GoldLoan />} />
          <Route
            path="/loanpage/gold-loan/gold-loan-eligibility-criteria"
            element={<GoldLoanEligibilityCriteria />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-interest-rates"
            element={<GoldLoanInterestRate />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-procedure"
            element={<GoldLoanProcedure />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-providers-in-india"
            element={<GoldLoanCompanies />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan"
            element={<TypesOfGoldLoan />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-overdraft"
            element={<GoldLoanOverdraft />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-top-up"
            element={<GoldLoanTopUp />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-bullet-repayment"
            element={<GoldLoanBulletRepayment />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-balance-transfer"
            element={<GoldLoanBalanceTransfer />}
          />

          {/* Gold Loan Articles */}
          <Route
            path="/loanpage/gold-loan/gold-loan-balance-transfer"
            element={<GoldLoanArticleOne />}
          />
          <Route
            path="/loanpage/gold-loan/how-to-top-up-your-gold-loan-for-additional-financial-needs"
            element={<GoldLoanArticleTwo />}
          />
          <Route
            path="/loanpage/gold-loan/prepayment-and-foreclosure-of-gold-loans"
            element={<GoldLoanArticleThree />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-renewal-process"
            element={<GoldLoanArticleFour />}
          />
          <Route
            path="/loanpage/gold-loan/managing-gold-loan-emis"
            element={<GoldLoanArticleFive />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-closure-process"
            element={<GoldLoanArticleSix />}
          />

          {/* Credit Card */}
          <Route
            path="/creditcard/credit-card-eligibility"
            element={<CreditCardPage />}
          />
          <Route
            path="/creditcard/balance-transfer-credit-cards"
            element={<BalanceTransferCreditCards />}
          />
          <Route
            path="/creditcard/travel-and-airline-credit-cards"
            element={<TravelAndAirlineCredit />}
          />
          <Route
            path="/creditcard/credit-card-rewards-and-cashback"
            element={<CreditCardRewards />}
          />
          <Route
            path="/creditcard/loan-status-tracking"
            element={<LoanStatusTracking />}
          />

          {/* Credit Card Article */}
          <Route
            path="/creditcard/how-to-maximize-credit-card-benefits"
            element={<CCArticleOne />}
          />
          <Route
            path="/creditcard/credit-card-annual-fees"
            element={<CCArticleTwo />}
          />
          <Route
            path="/creditcard/why-is-my-credit-card-application-getting-rejected"
            element={<CCArticleThree />}
          />
          <Route
            path="/creditcard/a-complete-guide-to-balance-transfer-credit-cards"
            element={<CCArticleFour />}
          />
          <Route path="/credit-score" element={<CreditScore />} />
          <Route path="/lower-emi-page" element={<LowerEmiStaticPage />} />
        </Routes>

        {/* <Footer /> */}
        <FooterUpdated />
      </Router>
    </div>
  );
};

export default App;
