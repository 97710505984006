import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { LuDot } from "react-icons/lu";
import tongueOut from "../../../../Assets/Images/tongue-out.png";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MetadataContext from "../../../MetadataContext";

const GoldLoanArticleThree = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Prepayment and Foreclosure of Gold Loans: A Guide to Save on Interest",
      pageDescription:
        "Guides borrowers on how to minimize costs when repaying their gold loan early.",
      pageKeywords:
        "prepayment, foreclosure, gold loans, save on interest, gold loan prepayment, loan foreclosure guide, gold loan interest savings, gold loan tips, personal finance, loan repayment, reducing interest on loans",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/prepayment-and-foreclosure-of-gold-loans",
    });
  }, [setMetadata]);
  return (
    <>
      <Helmet>
        <title>
          Prepayment and Foreclosure of Gold Loans: A Guide to Save on Interest
        </title>
        <meta
          name="description"
          content="Guides borrowers on how to minimize costs when repaying their gold loan early."
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpages"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/gold-loan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Gold Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Prepayment and Foreclosure of Gold Loans
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Prepayment and Foreclosure of Gold Loans: A Guide to Save on Interest
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Guides borrowers on how to minimize costs when repaying their gold
          loan early.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          When you take out a gold loan, it&#39;s not just about securing the
          necessary funds; it&#39;s also about understanding how to manage your
          loan repayment to minimize costs. Prepayment and foreclosure of gold
          loans are two strategies that can help you save on interest payments,
          allowing you to reduce your overall financial burden. If you&#39;re
          considering repaying your gold loan early, here&#39;s a comprehensive
          guide on how to go about it and the advantages of doing so.
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            1. What is Prepayment and Foreclosure?
          </p>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Prepayment:{" "}
                </span>
                This refers to the act of making partial or full early payments
                toward your gold loan before the due date. It allows you to
                reduce the outstanding balance of your loan, thus decreasing the
                interest burden over time. Some lenders allow part payments
                (prepayments), while others may require full settlement.
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Foreclosure:{" "}
                </span>
                This is the full repayment of the loan amount before the end of
                the agreed- upon tenure. In this case, the borrower clears the
                remaining balance and closes the loan entirely. Most lenders
                offer this option after the borrower has paid a certain
                percentage of the loan amount or after a specific period.
              </div>
            </div>{" "}
          </div>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            2. How Does Prepayment and Foreclosure Help Save on Interest?
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            The primary benefit of prepaying or foreclosing your gold loan is
            the potential to reduce the total interest paid over the life of the
            loan.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            a. Interest Calculation on Gold Loans
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Gold loans generally have daily interest calculations, meaning the
            interest is charged daily on the outstanding balance. When you make
            early payments, either partial or full, it reduces the loan balance,
            which, in turn, reduces the amount of interest accrued daily. This
            can save you significant amounts over time.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            b. Reduced Loan Tenure
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            If you make partial prepayments or foreclose your gold loan early,
            you can reduce the overall loan tenure. As a result, the total
            interest paid on the loan will be lower, as the principal is paid
            off quicker, and interest is calculated over a shorter period.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            c. Avoiding Interest on Accrued Amounts
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            In many cases, the longer you take to repay your loan, the higher
            the interest you end up paying, especially if you opt for a long
            tenure with low EMIs. By choosing prepayment or foreclosure, you
            minimize this accumulated interest and lower the overall financial
            burden.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            3. Consider Prepayment or Lump-Sum Payments
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Here’s a breakdown of the key advantages of opting for prepayment or
            foreclosure:
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            a. Saving on Interest Costs
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            One of the most immediate benefits of prepaying or foreclosing a
            gold loan is the reduction in interest costs. The earlier you clear
            the loan, the less interest you will incur. In cases where interest
            is calculated on a daily or monthly basis, a shorter loan tenure
            means less money spent on interest.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            b. Improved Credit Score
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            A quick repayment of a gold loan can have a positive effect on your
            credit score. By demonstrating your ability to pay off debt early,
            you showcase your financial discipline. This could benefit you in
            the future when applying for other types of loans or credit.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            b. Improved Credit Score
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            A quick repayment of a gold loan can have a positive effect on your
            credit score. By demonstrating your ability to pay off debt early,
            you showcase your financial discipline. This could benefit you in
            the future when applying for other types of loans or credit.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            c. Flexibility and Financial Freedom
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Paying off your gold loan early provides you with the freedom to
            allocate funds for other financial priorities. It relieves you of
            the burden of monthly EMIs, leaving more room for other investments
            or expenditures.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            d. Reduced Financial Stress
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Knowing that your gold loan is fully paid off can provide peace of
            mind, freeing you from any worries regarding ongoing repayments and
            future interest liabilities.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            4. Steps to Prepay or Foreclose Your Gold Loan
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            If you&#39;re considering prepaying or foreclosing your gold loan,
            here are the steps to follow:
          </p>

          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 1: Check Your Loan Agreement
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Before proceeding with prepayment or foreclosure, review your loan
            agreement carefully. This document outlines the lender’s prepayment
            policies, penalties, and any conditions related to early repayment.
            Some lenders may impose a fee or charge a penalty for early closure,
            while others may offer flexibility with no charges.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 2: Determine the Amount You Want to Prepay
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            If you plan to make a partial prepayment, determine the amount you’d
            like to pay. This could be a portion of the principal loan amount or
            the entire outstanding balance. For foreclosure, you will need to
            clear the full loan amount, including principal and any accrued
            interest.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 3: Confirm the Prepayment/Foreclosure Terms with Your Lender
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Contact your lender to confirm the process and whether any charges
            apply for early settlement. Make sure to inquire about how your
            prepayment or foreclosure will affect your loan account, and if
            there are any benefits such as waiving off future interest on the
            remaining principal.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 4: Make the Payment
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Once the lender has confirmed the terms, you can make the prepayment
            or foreclosure payment through the available options, such as:
          </p>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Online payment{" "}
                </span>
                via the lender’s portal
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Cheque or bank transfer{" "}
                </span>
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  In-person payments{" "}
                </span>
                at the branch
              </div>
            </div>{" "}
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Ensure that the payment is processed successfully and that you
            receive an updated statement showing the reduced loan balance or
            loan closure.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 5: Get a No-Objection Certificate (NOC)
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            After successfully prepaying or foreclosing your loan, request a
            No-Objection Certificate (NOC) from your lender. This document
            officially confirms that your loan has been fully settled and that
            there are no outstanding dues.
          </p>
        </div>

        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            5. Things to Keep in Mind Before Opting for Prepayment or
            Foreclosure
          </p>

          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            While prepayment and foreclosure are excellent ways to save on
            interest, there are some key considerations to keep in mind:
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            a. Prepayment Penalties
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Some lenders may charge a penalty for prepayment or foreclosure,
            especially if you decide to pay off the loan before the agreed-upon
            tenure. Always check for any such charges in the terms and
            conditions before proceeding.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            b. Impact on Loan Terms
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Ensure that your prepayment does not affect any benefits you may
            have gained from the loan, such as lower interest rates or discounts
            for long-term borrowers. It’s advisable to consult with your lender
            to clarify any possible consequences.
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            c. Maintaining a Balanced Financial Plan{" "}
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            While it’s beneficial to clear your debt early, ensure that your
            decision to prepay or foreclose your loan doesn’t disrupt your
            overall financial stability. It’s important to maintain a balance
            between repaying loans and managing your savings or investments.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            6. Examples of Lenders’ Prepayment and Foreclosure Policies
          </p>

          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Here are some examples of prepayment and foreclosure policies
            offered by leading gold loan lenders:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  SBI Gold Loan:{" "}
                </span>
                Offers partial prepayment options with no penalty, allowing you
                to reduce your principal amount. Foreclosure can be done at any
                time without fees.
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  HDFC Gold Loan:{" "}
                </span>
                Allows both part and full prepayments with minimal or no
                charges. They also offer flexible EMI options to reduce the loan
                tenure.
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Muthoot Finance:{" "}
                </span>
                Offers prepayment facilities, allowing you to reduce the loan
                amount or complete the foreclosure with no extra charges.
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  ICICI Bank Gold Loan:{" "}
                </span>
                Allows you to prepay the loan amount in full or part with no
                foreclosure penalty. They provide online tools to assist with
                loan balance calculations.
              </div>
            </div>{" "}
          </div>
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Conclusion
        </div>
        <p
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px",
          }}
        >
          Prepayment and foreclosure of your gold loan can be a strategic way to
          reduce your financial liabilities and save on interest. By paying off
          your loan early, you not only minimize the total amount paid but also
          gain financial freedom. However, always consider the terms and
          conditions associated with early repayment and ensure it aligns with
          your overall financial goals. A thoughtful approach to repaying your
          gold loan can help you achieve greater financial stability and peace
          of mind.
        </p>
      </div>
    </>
  );
};

export default GoldLoanArticleThree;
