import React from "react";

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col items-center text-center p-6 bg-[#EBE8FC] rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <div className="mb-4 w-16 h-16 rounded-full bg-gradient-to-br from-purple-400 to-purple-600 flex items-center justify-center text-white">
        {icon}
      </div>
      <h3
        className="text-xl font-semibold mb-2 bg-[#EBE8FC] "
        style={{
          fontSize: "20px",
          lineHeight: "28px",
          fontWeight: "600",
          color: "#59266D",
        }}
      >
        {title}
      </h3>
      <p
        className="text-gray-600 text-center bg-[#EBE8FC] "
        style={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
          color: "#4B5563",
        }}
      >
        {description}
      </p>
    </div>
  );
};

export default FeatureCard;
