import { useState } from "react";
// import { ChevronDown, ChevronUp } from "lucide-react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="mb-6 w-100 " style={{ border: "1px solid #eae4f1" }}>
      <div
        className="flex items-center  w-100 py-3 px-4  bg-white justify-between cursor-pointer"
        onClick={toggleAnswer}
      >
        <h3
          className="text-xl font-semibold bg-white text-[#59266d] hover:bg-[#8e4fae]"
          style={{
            color: "#59266D",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
          }}
        >
          {question}
        </h3>

        {isOpen ? (
          <FaAngleUp
            className=" text-[#59266d]  bg-white"
            style={{
              fontSize: "16px",
              color: "#59266D",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          />
        ) : (
          <FaAngleDown
            className=" text-[#59266d]  bg-white"
            style={{
              fontSize: "16px",
              color: "#59266D",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          />
        )}
      </div>
      {isOpen && <p className="pt-2 text-gray-600  bg-white px-3"> {answer}</p>}
    </div>
  );
}

export default FAQItem;
