import React, { useState } from "react";
import otpimage from "../../Assets/Images/otp.webp";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Otp.css";

const Otp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || {};
  const mobile = localStorage.getItem("mobile");
  const stg1 = localStorage.getItem("stageone");
  const stg2 = localStorage.getItem("stagetwo");

  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);
    return timeLeft > 0 ? timeLeft : 30;
  });
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Only allow numeric input and limit to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };

  const hid = localStorage.getItem("id");
  console.log("HID", hid);

  const OtpValidate = () => {
    if (otp.length !== 6) {
      toast("Please enter a valid 6-digit OTP.");
      return;
    }

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
      headers: { Authorization: `Bearer ${from}` },
      data: {
        stgTwoHitId: stg2,
        stgOneHitId: stg1,
        otp: otp,
        type: "NORMAL",
        mobileNo: mobile,
      },
    };

    axios
      .request(options)
      .then((response) => {
        toast(response.data.msg);
        if (response.data.status === 1) {
          localStorage.setItem("from", from);
          localStorage.setItem("name", response.data.data.name);
          if ((hid && hid === "1") || hid === "2" || hid === "3") {
            navigate("/providedetails");
          } else {
            navigate("/goal");
          }
          // navigate("/goal");
        } else {
          setOtp("");
        }
      })
      .catch((error) => {
        toast("Something went wrong!");
        console.error(error);
      });
  };

  const ResendOtp = () => {
    if (isResendDisabled) return;
    const name = localStorage.getItem("fname");
    const lastname = localStorage.getItem("lname");
    const mobile = localStorage.getItem("mobile");
    const email = localStorage.getItem("email");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/login`,
      data: {
        fname: name,
        mobile: mobile,
        email: email,
        lname: lastname,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data.status === 1) {
          localStorage.setItem(
            "stageone",
            response.data.data.expData.stgOneHitId
          );
          localStorage.setItem(
            "stagetwo",
            response.data.data.expData.stgTwoHitId
          );
          setIsResendDisabled(true);
          setTimeLeft(30);
          localStorage.setItem("otpEndTime", new Date().getTime() + 30000);
          setOtp("");
          toast(
            <div style={{ backgroundColor: "white" }}>{response.data.msg}</div>
          );
        } else {
          console.error("Failed to resend OTP");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  React.useEffect(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);

    if (timeLeft <= 0) {
      setIsResendDisabled(false);
    } else {
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, []);

  React.useEffect(() => {
    const endTime = new Date().getTime() + timeLeft * 1000;
    localStorage.setItem("otpEndTime", endTime);
  }, []);

  React.useEffect(() => {
    if (timeLeft === 0) {
      setIsResendDisabled(false);
    }
  }, [timeLeft]);

  return (
    <div>
      <ToastContainer />
      <LoadingBar color="#59266d" progress={100} />
      <div className="otpFrame">
        <div className="otpContainer">
          <div className="otpImage">
            <img src={otpimage} alt="OTP Illustration" />
          </div>
          <div className="otpFieldContainer">
            <div className="otpTitle">Enter OTP</div>
            <div className="otpDescription">
              Please enter the one-time password (OTP) sent to {mobile}.
            </div>
            <div style={{ width: "92%" }}>
              <input
                style={{ width: "100%" }}
                type="text"
                placeholder=""
                value={otp}
                onChange={handleOtpChange}
                className="otpInput"
                maxLength={6}
              />
            </div>
            <button
              className={`otpVerifyButton ${
                otp.length === 6 ? "enabled" : "disabled"
              }`}
              style={{
                backgroundColor: "Get OTP",
                height: 56,
                cursor: otp.length === 6 ? "pointer" : "not-allowed",
              }}
              onClick={OtpValidate}
              disabled={otp.length !== 6}
            >
              Verify
            </button>

            <div
              style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
              }}
            >
              {formatTime(timeLeft)}
            </div>
            <div
              style={{
                fontWeight: 500,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                paddingBottom: "12px",
                cursor: isResendDisabled ? "not-allowed" : "pointer",
                opacity: isResendDisabled ? "0.5" : "1",
              }}
              onClick={ResendOtp}
            >
              Resend OTP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
