import React from "react";
// import { FileText, Scale, ArrowLeftRight, PiggyBank } from "react-icons";
import {
  LuFileText,
  LuScale,
  LuArrowLeftRight,
  LuPiggyBank,
} from "react-icons/lu";
import FlowchartStep from "./FlowchartStep";

function BalanceTransferSection() {
  return (
    <section className="py-16 bg-[#EBE8FC]" style={{ width: "100%" }}>
      <h2
        className="text-3xl font-bold  text-center mb-12 bg-[#EBE8FC]"
        style={{ color: "#5b2371", fontSize: "30px", lineHeight: "36px" }}
      >
        How MinEMI Helps Lower Your EMI with Balance Transfer
      </h2>
      <div className="container-fluid mx-auto px-4  w-100 bg-[#EBE8FC]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center bg-[#EBE8FC]">
          <div className="space-y-6 bg-[#EBE8FC]">
            <FlowchartStep
              icon={
                <LuFileText
                  style={{
                    backgroundColor: "transparent",
                    color: "FFFFFF",
                    fontSize: "25px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    borderRadius: "10px",
                  }}
                />
              }
              step={1}
              text="Share your current loan details"
            />
            <FlowchartStep
              icon={
                <LuScale
                  style={{
                    backgroundColor: "transparent",
                    color: "FFFFFF",
                    fontSize: "25px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    borderRadius: "10px",
                  }}
                />
              }
              step={2}
              text="MinEMI compares options and recommends the best lender with lower interest rates"
            />
            <FlowchartStep
              icon={
                <LuArrowLeftRight
                  style={{
                    backgroundColor: "transparent",
                    color: "FFFFFF",
                    fontSize: "25px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    borderRadius: "10px",
                  }}
                />
              }
              step={3}
              text="Transfer your outstanding balance to the new lender"
            />
            <FlowchartStep
              icon={
                <LuPiggyBank
                  style={{
                    backgroundColor: "transparent",
                    color: "FFFFFF",
                    fontSize: "25px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    borderRadius: "10px",
                  }}
                />
              }
              step={4}
              text="Enjoy reduced EMIs and monthly savings"
            />
          </div>
          <div className="bg-[#EBE8FC]">
            <p
              className="text-lg mb-6 bg-[#EBE8FC]"
              style={{
                fontSize: "19px",
                lineHeight: "28px",
                color: "#59266D",
                fontWeight: "initial",
              }}
            >
              A balance transfer helps you shift your existing loan to a lender
              offering lower interest rates. By reducing the rate, your monthly
              EMIs go down, saving you money over time. It's simple, fast, and
              stress-free with MinEMI!
            </p>
            <button className="bg-[#59266d] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors w-full md:w-auto">
              Check Your Savings
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BalanceTransferSection;
