import React, { useEffect, useState, useContext } from "react";
import { LuDot } from "react-icons/lu";
import "./EligiblityCriteria.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Breadcrumbs from "./Breadcrumbs";
import axios from "axios";
import { Helmet } from "react-helmet";
import MetadataContext from "../MetadataContext";

const EligiblityCriteria = () => {
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState("");
  const [isMoreCriteria, setIsMoreCriteria] = useState(false);
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery("(max-width:1080px)");
  const isMoreMediumScreen = useMediaQuery("(max-width:842px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px)");

  // currency formate
  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const calculateLoanEligibility = () => {
    const P = monthlyIncome;
    const r = annualInterestRate;
    const n = loanTenure;

    // Parse inputs
    const foir = 0.5;
    const affordableEMI = P * foir - existingObligations;
    if (affordableEMI <= 0) {
      setEligibility("Not Eligible for Loan");
      return;
    }

    const monthlyRate = r / 12 / 100;

    const loanEligibility =
      (affordableEMI * (Math.pow(1 + monthlyRate, n) - 1)) /
      (monthlyRate * Math.pow(1 + monthlyRate, n));

    setEligibility(
      isNaN(loanEligibility) || loanEligibility <= 0
        ? "Not Eligible for Loan"
        : Math.min(Math.round(loanEligibility), 5000000)
    );
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN");
  };

  const handleAmountChange = (e, setter) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = Number(value); // Convert to number
      if (numericValue <= 5000000) {
        // Restrict to maximum 50,00,000
        setter(numericValue);
      }
    }
  };

  const handleLoadMore = () => {
    setIsMoreCriteria((prev) => !prev);
  };

  const mainContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    // justifyContent: "space-between",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "30px" : "90px",
  };
  const tableContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen
      ? "16px"
      : isMoreMediumScreen
      ? "0"
      : isLargeScreen
      ? "20px"
      : "55px",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check Your Personal Loan Eligibility Instantly",
      pageDescription:
        "Check your borrowing options from top lenders without impacting your credit score. Use our loan eligibility calculator for personalized insights.",
      pageKeywords:
        "personal loan eligibility, check loan eligibility, instant loan check, loan eligibility calculator, personal loans, instant approval loans, loan qualification, online loan eligibility, quick loan assessment, loan eligibility criteria",

      canonicalUrl:
        "https://minemi.ai/loanpage/personalLoan/loan-eligibilty-criteria",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Check Your Personal Loan Eligibility Instantly</title>
        <meta
          name="description"
          content="Check your borrowing options from top lenders without impacting your credit score. Use our loan eligibility calculator for personalized insights."
        />
        <meta
          name="keywords"
          content="personal loan eligibility, check loan eligibility, instant loan check, loan eligibility calculator, personal loans, instant approval loans, loan qualification, online loan eligibility, quick loan assessment, loan eligibility criteria"
        />

        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            <Link
              to={"/loanpage/personalloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Personal Loan Check Eligibility
            </Link>{" "}
          </p>
        </div>
        <h1
          className="loanstatus-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",

            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Check Your Personal Loan Eligibility Instantly
        </h1>
        <p
          className="loanstatus-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "24px",

            color: "#74589B",
            backgroundColor: "#E7E4F9",
          }}
        >
          Find out how much you can borrow from top lenders without impacting
          your credit score. Use our personal loan eligibility calculator and
          get personalized insights based on your income, credit score, and
          other factors.{" "}
        </p>
      </div>
      <div
        className="tracking-status"
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
      >
        <h2
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Personal Loan Eligibility Criteria
        </h2>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
          }}
        >
          <table
            id="customers"
            style={{
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
              width: "100%",
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                  }}
                >
                  Eligibility Factor
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Minimum Requirement
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    borderTopRightRadius: 18,
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "16px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Impact on Loan Eligibility
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  ₹25,000/month (salaried individuals)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Higher income increases eligibility and the amount you can
                  borrow.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  700+ (recommended)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  A higher score may result in better interest rates and loan
                  terms.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Employment Status
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Salaried individuals with a minimum of 1 year at the current
                  job
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Stable employment enhances approval chances.
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  21 to 60 years
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Applicants within this age range are eligible.
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    border: "1px solid #ddd",
                    borderBottomLeftRadius: 18,
                  }}
                >
                  Fixed Obligations to Income Ratio (FOIR)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  40%-50%
                </td>
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                    border: "1px solid #ddd",
                    borderBottomRightRadius: 18,
                  }}
                >
                  A lower FOIR indicates fewer existing EMIs and improves loan
                  eligibility.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className="make-it-center increase-margin "
          style={{
            marginTop: "4%",
            marginBottom: "2%",
            padding: 24,
            background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
            borderRadius: 24,
          }}
        >
          <div
            style={{
              marginLeft: "4%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              gap: 24,
              borderRadius: 24,
              alignSelf: "center",
            }}
          >
            <p
              className="eligiblity-criteria"
              style={{
                fontFamily: "Archivo",
                color: "#59266D",
                fontSize: 40,
                backgroundColor: "transparent",
              }}
            >
              <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                Check Your Loan Eligibility Without{" "}
                <br className="brake-remove" /> Impacting Your Credit Score
              </i>
            </p>
            <a
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="eligiblity-buttons"
              style={{
                backgroundColor: "#553285",
                color: "#FFFFFF",
                width: "39%",
                borderRadius: 8,
                fontFamily: "Archivo",
                fontWeight: 500,
                fontSize: 18,
                paddingTop: 8,
                paddingBottom: 8,
                textAlign: "center",
              }}
            >
              Check Eligibility
            </a>
          </div>
          <div
            style={{
              width: 200,
              height: 195,
              backgroundColor: "transparent",
              marginRight: "4%",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              }}
              loading="lazy"
              alt="Check Eligibility"
            />
          </div>
        </div>

        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop: "4%",
          }}
        >
          Eligibility Criteria by Lender
        </p>

        <div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 24,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 14,
              marginLeft: "1%",
              paddingBottom: 0,
            }}
          >
            Loan Criteria
          </p>
          <div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/image%2048-1736403678444.webp"
                    alt="HDFC"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                  />
                </div>
                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      {" "}
                      Up to 5 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/personalLoan/loan-eligibilty-criteria/hdfc-loan-eligiblity"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isMoreMediumScreen ? 275 : 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                HDFC Loan Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/axis-1736403836726.webp"
                    alt="AXIS"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      720
                    </p>
                  </div>

                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹20,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 7 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/personalLoan/loan-eligibilty-criteria/axis-loan-eligiblity"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isMoreMediumScreen ? 275 : 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                AXIS Loan Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/sbi-1736403898225.webp"
                    alt="Axix Loan Eligibility"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      up to 5 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/personalLoan/loan-eligibilty-criteria/sbi-loan-eligiblity"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isMoreMediumScreen ? 275 : 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                SBI Loan Eligibility
              </div>
            </div>
            <div
              className="main-box"
              style={{
                backgroundColor: "#FFFFFF",
                // borderBottom: "1px solid #D9D9D9",
                // paddingBottom: 15,
              }}
            >
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/icici-1736403726941.webp"
                    alt="ICICI Personal Loan Eligibility"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      680
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 6 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/personalLoan/loan-eligibilty-criteria/icici-loan-eligiblity"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isMoreMediumScreen ? 275 : 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                ICICI Loan Eligibility
              </div>
            </div>
            <div
              className={`main-box ${
                isMoreCriteria === true ? "d-flex" : "d-none"
              } `}
              style={{
                backgroundColor: "#FFFFFF",
              }}
            >
              <div className="" style={mainContent}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/indusind-1736421921310.webp"
                    alt="IndusInd"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    loading="lazy"
                  />
                </div>

                <div className="" style={tableContent}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Minimum Credit Score
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      680
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      ₹25,000/month
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      backgroundColor: "#FFFFFF",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      className="main-h"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#8A8A8A",
                        alignSelf: "center",
                      }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      Up to 6 years
                    </p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(
                    "/loanpage/personalLoan/loan-eligibilty-criteria/indusind-loan-eligiblity"
                  )
                }
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: isMoreMediumScreen ? 275 : 256,
                  fontFamily: "Archivo",
                  color: "#FFFFFF",
                  fontWeight: 500,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                IndusInd Loan Eligibility
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                padding: 5,
                paddingTop: 16,
                fontFamily: "Archivo",
                fontWeight: 600,
                borderTop: "1px solid #D9D9D9",
                color: "#1F1F1F",
                fontSize: 18,
              }}
              onClick={() => {
                handleLoadMore();
              }}
            >
              {isMoreCriteria === true ? "Hide" : "Load More..."}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Understanding Your Eligibility for a Personal Loan{" "}
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Personal loan eligibility is determined by a range of factors,
          including your income, credit score, existing debt, and employment
          status. Lenders assess these criteria to ensure you can repay the loan
          comfortably without over-leveraging yourself. By checking your
          eligibility beforehand, you can avoid the risk of rejection and
          increase your chances of securing the loan amount you need.{" "}
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Income:&nbsp;&nbsp;
              </span>
              Lenders typically require a minimum monthly income of ₹25,000 for
              salaried individuals. The higher your income, the more likely you
              are to qualify for a larger loan.{" "}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Credit Score:&nbsp;&nbsp;
              </span>
              Your credit score is a key factor in determining loan approval and
              interest rates. A score above 700 generally leads to faster
              approval and more favorable loan terms.{" "}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                {" "}
                Fixed Obligations to Income Ratio (FOIR):&nbsp;&nbsp;
              </span>
              This ratio compares your fixed monthly obligations (existing EMIs,
              rent, etc.) to your income. A lower FOIR means fewer financial
              commitments, making you more eligible for a higher loan amount.{" "}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Employment Status:&nbsp;&nbsp;
              </span>
              Both salaried and self-employed individuals can apply for personal
              loans. However, salaried applicants generally have an easier time
              qualifying, while self-employed individuals may need to provide
              more documentation proving business stability.{" "}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Age:&nbsp;&nbsp;
              </span>
              This ratio compares your fixed monthly obligations (existing EMIs,
              rent, etc.) to your income. A lower FOIR means fewer financial
              commitments, making you more eligible for a higher loan amount.{" "}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Use Our Eligibility Calculator to Get Personalized Results
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Using our personal loan eligibility calculator, you can instantly
          assess how much you can borrow based on your financial profile. The
          calculator considers your income, current EMIs, credit score, and more
          to give you a personalized result. This helps you understand your
          borrowing capacity before submitting an official loan application.{" "}
        </div>

        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          If you don’t meet the eligibility criteria or want to increase your
          chances of approval, here are some tips:
        </div>
        <div
          className="main-inner-box-eligiblity increase-margin "
          style={{
            marginTop: "2%",
            backgroundColor: "white",
            borderRadius: 24,
            border: "2px solid #846CA6",
            borderRight: 0,
          }}
        >
          <div
            className=" loan-criteria-box "
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // flexWrap: "wrap",
              backgroundColor: "white",
              borderRadius: 24,
              gap: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                backgroundColor: "white",
                margin: 32,
                width: "40%",
              }}
              className="box-eligiblity-input-field"
            >
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  ₹
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Monthly Income"
                  style={{
                    width: "100%",
                    border: "0px solid white",
                    padding: "0 0 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={formatCurrency(monthlyIncome)} // Display value with commas
                  onChange={(e) => handleAmountChange(e, setMonthlyIncome)}
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  position: "relative",
                  backgroundColor: "#FFFFFF",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  ₹
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Existing Monthly Obligations"
                  style={{
                    width: "100%",
                    border: "0px solid white",
                    padding: "0 0 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={formatCurrency(existingObligations)} // Display value with commas
                  onChange={(e) =>
                    handleAmountChange(e, setExistingObligations)
                  }
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#FFFFFF",
                  position: "relative",
                  padding: "15px",
                }}
              >
                <input
                  className="input-fiedl-inner"
                  placeholder="Desired Loan Tenure"
                  style={{
                    width: "90%",
                    border: "0px solid white",
                    padding: "0 15px 0 15px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={loanTenure}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (
                      !isNaN(value) &&
                      Number.isInteger(+value) &&
                      value >= 0
                    ) {
                      setLoanTenure(value);
                    }
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  months
                </span>
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#FFFFFF",
                  position: "relative",
                  padding: "15px",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    left: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "white",
                    color: "#846CA6",
                    fontSize: "18px",
                  }}
                >
                  %
                </span>
                <input
                  className="input-fiedl-inner"
                  placeholder="Interest Rate"
                  style={{
                    width: "90%",
                    border: "0px solid white",
                    padding: "0 0 0 20px",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "white",
                  }}
                  value={annualInterestRate}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNaN(value) && value >= 0 && value <= 99) {
                      setAnnualInterestRate(value);
                    } else if (value > 99) {
                      setAnnualInterestRate(99); // Optionally set it to 99 if the user exceeds the limit
                    } else if (value === 0) {
                      setAnnualInterestRate(0); // Optionally set it to 99 if the user exceeds the limit
                    }
                  }}
                />
              </div>
              <div
                className="input-fiedl"
                style={{
                  width: "85%",
                  border: "1px solid #846CA6",
                  borderRadius: 8,
                  backgroundColor: "#59266D",
                  color: "white",
                  height: 48,
                  fontFamily: "Archivo",
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "20px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => calculateLoanEligibility()}
              >
                Submit
              </div>
            </div>
            <div
              className="box-eligiblity-loan-amount"
              style={{
                width: "50%",
                padding: 62,
                backgroundColor: "#846CA6",
                margin: 0,
                borderTopRightRadius: 24,
                borderBottomRightRadius: 24,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    color: "white",
                    fontFamily: "Archivo",
                    fontWeight: 700,
                    fontSize: 32,
                  }}
                >
                  Eligible Loan Amount
                </div>
                <div
                  className="eligible-loan-amount-box-button"
                  style={{
                    width: 400,
                    height: 80,
                    borderRadius: 16,
                    color: "#59266D",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontWeight: 700,
                    fontSize: 40,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isNaN(eligibility) || eligibility === "₹0" ? (
                    <span
                      style={{
                        fontSize: "24px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Not Eligible for Loan
                    </span>
                  ) : (
                    currencyFormatter.format(eligibility)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Improve Your Loan Eligibility
        </div>
        <div
          style={{
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          If you don’t meet the eligibility criteria or want to increase your
          chances of approval, here are some tips:
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingLeft: "10px",
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
              1. Improve Your Credit Score:
            </span>{" "}
            Pay down existing debt, avoid late payments, and limit new credit
            applications to improve your score.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
              2. Consolidate Existing Loans:
            </span>{" "}
            If your current debt is too high, consider consolidating your loans
            to reduce your EMI burden.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
              3. Add a Co-Applicant:
            </span>{" "}
            Adding a co-applicant, such as a spouse, with a higher income or
            better credit score can increase your eligibility.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
              4. Opt for a Longer Tenure:
            </span>{" "}
            Choosing a longer repayment tenure can reduce your monthly EMI,
            making it easier to qualify for a larger loan amount.
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              What are the general eligibility criteria for a Personal Loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The general eligibility criteria for a Personal Loan typically
              include factors such as age (usually 21 to 60 years), a stable
              income source, a minimum work experience of 1-2 years, a good
              credit score, and a residence within the lender's serviceable
              area.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              How does the lender determine my eligibility for a Personal Loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Each lender has their own set of criteria, but common factors they
              assess include your credit score, monthly income, current debt
              obligations, employment status, and loan repayment history. Some
              lenders may also consider your relationship with them and existing
              accounts.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What is the minimum income required to be eligible for a Personal
              Loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The minimum income requirement varies by lender but generally
              falls between ₹15,000 to ₹30,000 per month, depending on the type
              of loan, your employment, and your location. Lenders may have
              different criteria for salaried and self-employed individuals.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can I apply for a Personal Loan if I have an existing loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, you can still apply for a Personal Loan even if you have an
              existing loan. However, lenders will assess your debt-to-income
              ratio to ensure you can comfortably manage the additional
              financial responsibility. You may also be eligible for a Top-Up
              Loan to consolidate your existing loans.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What is the minimum income required to be eligible for a Personal
              Loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The minimum income requirement varies by lender but generally
              falls between ₹15,000 to ₹30,000 per month, depending on the type
              of loan, your employment, and your location. Lenders may have
              different criteria for salaried and self-employed individuals.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Are there any age restrictions for personal loan eligibility?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, lenders usually set age limits for Personal Loan eligibility.
              Generally, you should be at least 21 years old at the time of
              applying and not older than 60 years at the time of loan maturity.
              Some lenders may have flexibility with these age requirements
              based on other factors.
            </AccordionDetails>
            {/* <AccordionActions></AccordionActions> */}
          </Accordion>
        </div>

        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: "4%",
            marginBottom: "4%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 24,
                backgroundColor: "transparent",
                marginTop: "3%",
              }}
            >
              <a
                underline="none"
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#553285",
                  color: "#FFFFFF",
                  width: "18%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EligiblityCriteria;
