import React, { useEffect, useRef } from "react";
import "./DownloadApp.css";
import Mobile from "../../Assets/Images/4.png";
import mLogo from "../../Assets/Images/mLogo.webp";
import appleButton from "../../Assets/Images/appleButton.webp";
import googleButton from "../../Assets/Images/googleButton.webp";
import { Link } from "react-router-dom";

const DownloadApp = () => {
  const downloadAppRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#DownloadApp" && downloadAppRef.current) {
      downloadAppRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div ref={downloadAppRef} className="DownloadContainer" id="DownloadApp">
      {/* <div className="DownloadHeading">
        <p>Download App</p>
        <h1>All Your Financial Solutions in One Place!</h1>
      </div> */}

      <div
        className="d-flex w-100 justify-content-center "
        style={{ backgroundColor: "transparent" }}
      >
        <div className="DownloadTitle">
          <h3 className="DownloadHeading ">
            All Your Financial Solutions in One Place!
          </h3>
          <p className="DownloandSubHeading py-0">
            Discover smart financial solutions tailored to your needs. Whether
            it's balance transfers, loan consolidation, or flexible top-ups,
            we've got you covered.
          </p>
        </div>
      </div>
      {/* <div className="DownloadSection mt-4">
        <div className="MobileImage">
          <img
            src={
              "https://minemiwebsite.s3.ap-south-1.amazonaws.com/mobile.webp"
            }
            alt="Download Our App"
          />
        </div>
        <div className="downloadSubText">
          <p>
            Download Our App
            <img src={mLogo} alt="" />{" "}
            <p
              style={{
                fontWeight: 700,
                color: "rgba(89, 38, 109, 1)",
                paddingRight: "2px",
              }}
            >
              miniEMI
            </p>
            {"  "}
            for Lower EMIs and Enhanced
          </p>
          <p>Financial Services All in One Place!</p>

          <div
            style={{
              gap: "32px",
              display: "flex",
              backgroundColor: "transparent",
              marginTop: "26px",
            }}
          >
            <img
              src={googleButton}
              alt=""
              style={{
                width: "129px",
                height: "40px",
                mixBlendMode: "color-burn",
              }}
            />
            <Link
              to="https://apps.apple.com/in/app/minemi/id6477753054"
              style={{ backgroundColor: "transparent" }}
            >
              {" "}
              <img
                src={appleButton}
                alt=""
                style={{
                  width: "129px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
              />
            </Link>
          </div>
        </div>
      </div> */}

      {/* <div className="DownloadSectionMobile">
         <div style={{ backgroundColor: "transparent" }}>
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "rgba(132, 108, 166, 1)",
              fontWeight: 500,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Download Our App
            <img
              src={mLogo}
              alt=""
              style={{
                width: "28px",
                height: "28px",
                backgroundColor: "transparent",
              }}
            />
            miniEMI for Lower
          </p>
          <p
            style={{
              color: "rgba(132, 108, 166, 1)",
              fontWeight: 500,
              fontSize: "16px",
              backgroundColor: "transparent",
            }}
          >
            Minemi brings enhanced financial services to help you save on EMIs
            and manage loans efficiently.
          </p>
        </div> 

         <div className="MobileImage">
          <img src={Mobile} alt="" />
        </div> 
         <div
          style={{
            gap: "32px",
            display: "flex",
            backgroundColor: "transparent",
            marginTop: "26px",
            marginBottom: "23px",
          }}
        >
          <img
            src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/b7bbd6e0-c695-4087-b707-1b6a5ee35d0f-1736579172402.jpg"
            alt=""
            style={{
              width: "129px",
              height: "40px",
              backgroundColor: "transparent",
            }}
          />
          <Link
            // to="https://apps.apple.com/in/app/minemi/id6477753054"
            style={{ backgroundColor: "transparent" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/0d55762b-e096-4a03-8f97-f63e5dfb3ada-1736579071308.jpg"
              alt=""
              style={{
                width: "129px",
                height: "40px",
                backgroundColor: "transparent",
              }}
            />
          </Link>
        </div> 
       <div
          style={{
            gap: "32px",
            display: "flex",
            backgroundColor: "transparent",
            marginTop: "26px",
            marginBottom: "23px",
          }}
        >
          <div>
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/0d55762b-e096-4a03-8f97-f63e5dfb3ada-1736579071308.jpg"
              alt=""
              style={{
                width: "129px",
                height: "40px",
                backgroundColor: "transparent",
              }}
            />

            <Link
              // to="https://apps.apple.com/in/app/minemi/id6477753054"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/b7bbd6e0-c695-4087-b707-1b6a5ee35d0f-1736579172402.jpg"
                alt=""
                style={{
                  width: "129px",
                  height: "40px",
                  backgroundColor: "transparent",
                }}
              />
            </Link>
          </div>
        </div> 
      </div> */}

      <section className="mobile-app-showcase mt-0 mt-sm-3">
        <div className="container">
          <div className="content">
            <h3 className="title">Experience Minemi on Your Mobile</h3>
            <p className="description">
              Manage loans, check credit scores, and access financial tools on
              the go. Our app puts financial freedom at your fingertips.
            </p>
            <div className="" style={{ background: "transparent" }}>
              {/* <a href="#" className="app-button">
                <img
                  src="/placeholder.svg?height=20&width=20"
                  alt="Google Play"
                />
                Google Play
              </a> */}
              {/* <a href="#" className="app-button">
                <img
                  src="/placeholder.svg?height=20&width=20"
                  alt="App Store"
                />
                App Store
              </a> */}

              <div className=" app-buttons ">
                <Link
                  // to="https://apps.apple.com/in/app/minemi/id6477753054"
                  style={{ backgroundColor: "transparent" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/google-play-1736947090944.webp"
                    alt="Android"
                    loading="lazy"
                    style={{
                      width: "129px",
                      height: "40px",
                      backgroundColor: "transparent",
                    }}
                  />
                </Link>

                <Link
                  // to="https://apps.apple.com/in/app/minemi/id6477753054"
                  style={{ backgroundColor: "transparent" }}
                >
                  <img
                    src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/google-play-two-1736947157870.webp"
                    alt="Apple"
                    loading="lazy"
                    style={{
                      width: "129px",
                      height: "40px",
                      backgroundColor: "transparent",
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="mobile-screen p-0">
            {/* <div className="phone-frame">
              <div className="screen ">
                <img
                  src={Mobile}
                  alt="Minemi App Screenshot"
                  style={{ objectFit: "contain" }}
                />
              </div>
            </div> */}

            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/download-mobile-app-min-1736927755126.png"
              alt="Downloand App"
              loading="lazy"
              style={{
                width: "19rem",
                height: "35rem",
                backgroundColor: "transparent",
              }}
            />
          </div>
        </div>
        {/* <div className="floating-element"></div>
        <div className="floating-element"></div> */}
      </section>
    </div>
  );
};

export default DownloadApp;
