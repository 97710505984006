import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { LuDot } from "react-icons/lu";
import { Link } from "react-router-dom";
import MetadataContext from "../../../MetadataContext";

const DLArticleFour = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "How to Secure a Top-Up Loan Digitally",
      pageDescription:
        "A detailed article for existing borrowers on how they can request a top-up loan digitally, including the benefits and documentation needed.",
      pageKeywords:
        "top-up loan, secure loan online, digital loans, online loan application, loan refinancing, instant loans, digital banking, loan approval process, financial tips, secure loans, top-up loan guide, online borrowing, digital finance solutions, top-up loan process",
      canonicalUrl:
        "https://minemi.ai/loanpage/digital-loan/how-to-secure-a-top-up-loan-digitally",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>How to Secure a Top-Up Loan Digitally</title>
        <meta
          name="description"
          content="A detailed article for existing borrowers on how they can request a top-up loan digitally, including the benefits and documentation needed."
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/digital-loan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Digital Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              How to Secure a Top-Up Loan Digitally
            </Link>
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          How to Secure a Top-Up Loan Digitally: Process, Benefits, and
          Eligibility
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          A detailed article for existing borrowers on how they can request a
          top-up loan digitally, including the benefits and documentation
          needed.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          A Top-Up Loan is an additional loan that allows existing borrowers to
          borrow extra funds on their current loan. It can be a great option for
          those looking to meet their evolving financial needs without going
          through the hassle of applying for a new loan. With the rise of
          digital lending platforms, securing a top-up loan has become faster
          and more convenient. Here’s a detailed guide for existing borrowers on
          how to request a top-up loan digitally, including the benefits,
          eligibility criteria, and required documentation.
        </div>

        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            1. What is a Top-Up Loan?
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            A Top-Up Loan is an additional loan amount that you can borrow on
            top of your existing loan, whether it’s a personal loan, home loan,
            or loan against property. The loan amount is typically based on the
            current outstanding balance of your original loan, and the
            additional amount is added to your current EMIs.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Unlike a new loan, a top-up loan doesn&#39;t require you to provide
            new collateral or go through the lengthy loan application process
            again. This makes it an ideal option for borrowers looking for quick
            access to funds.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            2. Benefits of a Top-Up Loan
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Quick Access to Funds:{" "}
                </span>
                Since you already have an existing loan, the process to get a
                top-up loan is much faster compared to applying for a new loan.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Lower Interest Rates:{" "}
                </span>
                Top-up loans often come with lower interest rates than unsecured
                loans, as they are linked to your existing loan. This can make
                it more affordable than other forms of borrowing.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  No Need for Additional Collateral:{" "}
                </span>
                The top-up loan is generally secured against the existing
                collateral or the value of your property, so you don’t need to
                pledge anything new.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Flexible Loan Amount:{" "}
                </span>
                You can borrow additional funds as per your eligibility and the
                value of your current loan, giving you the flexibility to meet
                your financial requirements.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Convenient Repayments:{" "}
                </span>
                The top-up loan is typically integrated with your existing loan,
                meaning your EMIs will be adjusted to include the new loan
                amount, streamlining your repayment schedule.
              </div>
            </div>{" "}
          </div>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            3. Eligibility Criteria for a Top-Up Loan
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            While the exact eligibility criteria for a top-up loan may vary from
            lender to lender, some common requirements include:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Existing Loan Account:{" "}
                </span>
                You must already have an active loan with the lender (personal
                loan, home loan, or loan against property).
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Good Repayment Track Record:{" "}
                </span>
                Lenders typically assess whether you have a history of timely
                repayments on your existing loan. A good repayment track record
                increases your chances of getting approved for a top-up loan.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Score:{" "}
                </span>
                A higher credit score indicates that you are a responsible
                borrower and increases your eligibility for a top-up loan. While
                some lenders may approve loans with lower scores, a higher score
                can help you secure better terms.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan-to-Value Ratio:{" "}
                </span>
                For secured loans (such as a loan against property), lenders
                assess the loan-to-value (LTV) ratio to determine how much
                additional loan you can borrow based on the value of your
                collateral.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income Stability:{" "}
                </span>
                Lenders may also require proof of income to ensure that you can
                handle the increased loan burden. This is especially important
                if you are opting for a top-up loan for personal or emergency
                purposes.
              </div>
            </div>{" "}
          </div>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            4. The Process to Apply for a Top-Up Loan Digitally
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            The digital process for applying for a top-up loan is simple and
            user-friendly. Here’s a step-by-step breakdown:
          </p>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 1: Log in to Your Lender’s Platform
          </p>

          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Most lenders offer a digital loan application platform through their
            website or mobile app. You’ll need to log in to your account using
            your credentials (such as loan account number or customer ID).
          </p>
        </div>
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 2: Check Eligibility
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Once logged in, most digital platforms allow you to check your
            eligibility for a top-up loan. You’ll be asked to provide some basic
            details like:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan account number{" "}
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Outstanding loan balance
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Monthly income
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment status
                </span>
              </div>
            </div>{" "}
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            The platform will then calculate whether you qualify for a top-up
            loan based on your existing loan details and income.
          </p>
        </div>

        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 3: Submit Documents
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            After checking your eligibility, you’ll need to upload the required
            documents. Common documents needed include:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Identity Proof:{" "}
                </span>
                Passport, Aadhaar card, or voter ID.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Address Proof:{" "}
                </span>
                Utility bills or rental agreements.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income Proof:{" "}
                </span>
                Salary slips, bank statements, or income tax returns (for
                self-employed individuals).
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Property Documents (for secured loans):{" "}
                </span>
                Valuation documents, title deed, etc.
              </div>
            </div>{" "}
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            These documents can be uploaded directly via the lender’s digital
            platform, making the process seamless.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 4: Loan Approval and Offer
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Once your application is submitted, the lender will review your
            details and documents. In many cases, digital platforms provide
            instant approval or approval within a few hours or days. You will
            receive an offer letter that includes the following:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan amount{" "}
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Interest rate{" "}
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Repayment terms{" "}
                </span>
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className=""
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  EMI schedule{" "}
                </span>
              </div>
            </div>{" "}
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Review the offer carefully before proceeding.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 5: Sign the Agreement
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            After reviewing the loan offer, you’ll need to sign the loan
            agreement. Most lenders offer e- signature options, meaning you can
            sign the document electronically, avoiding the need for physical
            paperwork.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
            Step 6: Disbursement of Funds
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Once the agreement is signed, the loan amount will be disbursed to
            your account. This can happen within a few hours or a couple of
            days, depending on the lender&#39;s policies.
          </p>
        </div>
        <div>
          <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
            5. Required Documents for a Top-Up Loan
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            The required documentation can vary depending on the lender, but
            here’s a general list of what you’ll need:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  KYC Documents:{" "}
                </span>{" "}
                Proof of identity, address, and date of birth (Aadhaar card, PAN
                card, etc.).
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Proof of Income:{" "}
                </span>{" "}
                Latest salary slip, bank statement, or ITR.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Statement:{" "}
                </span>{" "}
                A statement of your existing loan account showing the
                outstanding balance.
              </div>
            </div>{" "}
            <div
              style={{ display: "flex", flexDirection: "row", gap: 4 }}
              className="mt-3"
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Collateral Documents (if applicable):{" "}
                </span>{" "}
                For secured loans, you’ll need to provide property- related
                documents, including a property title deed and valuation
                certificate.
              </div>
            </div>{" "}
          </div>
        </div>
        <div>
          <p
            style={{ fontSize: "24px", fontWeight: 600 }}
            className="mt-4 pt-1"
          >
            Conclusion
          </p>
          <p
            style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
            className=""
          >
            Securing a top-up loan digitally is a straightforward process that
            offers convenience, speed, and flexibility. By following the steps
            outlined above and ensuring you meet the eligibility requirements,
            you can access additional funds quickly to meet your financial
            needs. Top-up loans come with numerous benefits, including lower
            interest rates and no need for additional collateral, making them an
            attractive option for existing borrowers. Always ensure you
            understand the terms and conditions of the loan before proceeding,
            and make your repayments on time to maintain a good credit score.
          </p>
        </div>
      </div>
    </>
  );
};

export default DLArticleFour;
