// MetadataContext.js
import React, { createContext, useEffect, useState } from "react";

const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
  const [metadata, setMetadata] = useState({
    pageTitle: "Find Out Your Loan Eligibility Today | Minemi",
    pageDescription:
      "Don’t wait—use Minemi’s loan eligibility calculator to know your borrowing capacity instantly. Start planning smarter with our easy-to-use tool.",
    pageKeywords: "loan eligibility,loan eligibility calculator",
    canonicalUrl: "https://minemi.com/", // Add default canonical URL here
  });

  useEffect(() => {
    // Set the page title

    document.title = metadata.pageTitle;

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", metadata.pageDescription);

    // Update meta keywords
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement("meta");
      metaKeywords.setAttribute("name", "keywords");
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute("content", metadata.pageKeywords);

    // Update canonical link
    let linkCanonical = document.querySelector('link[rel="canonical"]');
    if (!linkCanonical) {
      linkCanonical = document.createElement("link");
      linkCanonical.setAttribute("rel", "canonical");
      document.head.appendChild(linkCanonical);
    }
    linkCanonical.setAttribute("href", metadata.canonicalUrl);
  }, [metadata]);

  return (
    <MetadataContext.Provider value={{ metadata, setMetadata }}>
      {children}
    </MetadataContext.Provider>
  );
};

export default MetadataContext;
