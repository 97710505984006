import React from "react";
import HomeLogo from "../../Assets/Images//HomeLogo.webp";
import Bank from "../../Assets/Images/Bank.webp";
import "./ConsolidDetail.css";
import { Link } from "react-router-dom";

function ConsolidDetail() {
  return (
    <div className="container">
      <div style={{ textAlign: "center" }}>
        <div
          className="congratulation"
          style={{
            fontSize: "48px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "60px",
          }}
        >
          Congratulations!
        </div>

        <div
          className="Saving"
          style={{
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "38px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "25px",
          }}
        >
          Your are saving{" "}
        </div>
        <div
          className="price-detail"
          style={{
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "58px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "10px",
          }}
        >
          ₹60,000
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "25px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(70, 115, 110, 1)",
            marginTop: "20px",
          }}
        >
          This is 30% of Outstanding Amount
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "24px",
        }}
      >
        <div
          className="boxLoan"
          style={{
            width: "358px",
            height: "160px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "50px",
              paddingBottom: "10px",
              backgroundColor: "transparent",
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
                backgroundColor: "transparent",
              }}
            >
              <div
                style={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "27px",
                  backgroundColor: "rgba(85, 50, 133, 0.1)",
                  textAlign: "center",
                }}
              >
                <img
                  src={HomeLogo}
                  alt="Home Logo"
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: "transparent",
                    position: "relative",
                    top: "7px",
                  }}
                />
              </div>
              <div style={{ backgroundColor: "transparent" }}>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(31, 31, 31, 1)",
                    paddingTop: "10px",
                    backgroundColor: "transparent",
                  }}
                >
                  Home Loan
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    lineheight: "13px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(138, 138, 138, 1)",
                    paddingTop: "5px",
                    backgroundColor: "transparent",
                  }}
                >
                  SBI
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "transparent", paddingTop: "15px" }}>
              <img
                src={Bank}
                alt="Bank"
                style={{
                  width: "81.6px",
                  height: "24px",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: " rgba(31, 31, 31, 1)",
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              Outstanding
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              ₹40,00,000
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: " rgba(31, 31, 31, 1)",
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              Tenure
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              5 Years
            </div>
          </div>
        </div>
        <table
          style={{
            width: "358px",
            height: "160px",
            borderRadius: "10px",
            background: "#FFFFFF",
          }}
        >
          <tr style={{ backgroundColor: "transparent" }}>
            <th
              style={{
                padding: "7px 26px",
                backgroundColor: "transparent",
                width: "119px",
                height: "40px",
                borderTopLeftRadius: "10px",
                borderLeft: "1px solid #E0E0E0",
                borderTop: "1px solid #E0E0E0",
              }}
            ></th>
            <th
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#000000",
                width: "119px",
                height: "34px",
                border: "1px solid #E0E0E0",
                borderRight: "none",
                borderTop: "none",
                borderTop: "1px solid #E0E0E0",
                borderBottom: "none",
              }}
            >
              Current
            </th>
            <th
              style={{
                borderTopRightRadius: "4px",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#FFFFFF",
                textAlign:'center',
                backgroundColor: "#3A868B",
                width: "119.47px",
                height: "40.49px",
              }}
            >
              New
            </th>
          </tr>
          <tr style={{ border: "1px solid #E0E0E0" }}>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                border: "1px solid #E0E0E0",
                borderLeft: "none",
                borderRight: "none",
                borderLeft: "1px solid #E0E0E0",
              }}
            >
              Interest
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                borderTop: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              10%
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                border: "1px solid #3A868B ",
              }}
            >
              9%
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                backgroundColor: " #FFFFFF",
                border: "1px solid #E0E0E0",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "1px solid #E0E0E0",
              }}
            >
              EMI
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                backgroundColor: " #FFFFFF",
                border: "1px solid #E0E0E0",
                borderTop: "none",
              }}
            >
              ₹47,539
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                backgroundColor: " #FFFFFF",
                border: "1px solid #3A868B ",
                borderTop: "none",
              }}
            >
              ₹44,986
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                border: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",

                borderBottom: "1px solid #E0E0E0",
                borderRight: "none",
                borderBottomLeftRadius: "10px",
              }}
            >
              Total Savings
            </td>
            <td
              style={{
                border: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",
                borderBottom: "none",
                borderTop: "none",
                borderBottom: "1px solid #E0E0E0",
              }}
            ></td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                border: "1px solid #3A868B ",
                borderTop: "none",
                borderBottomRightRadius: "10px",
              }}
            >
              ₹54,986
            </td>
          </tr>
        </table>
      </div>
      <div style={{ textAlign: "center", marginTop: "60px" }}>
        <button
          className="Consolidate-btn"
          style={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            paddingTop: "19px",
            paddingLeft: "146px",
            paddingBottom: "19px",
            paddingRight: "146px",
            borderRadius: "10px",
            backgroundColor: "rgba(89, 38, 109, 1)",
            color: "rgba(255, 255, 255, 1)",
            border: "none",
            cursor: "pointer",
          }}
        >
          Initiate Consolidate Process
        </button>
      </div>
    </div>
  );
}

export default ConsolidDetail;
