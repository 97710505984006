import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { LuDot } from "react-icons/lu";
import { Link } from "react-router-dom";
import MetadataContext from "../../../MetadataContext";

const GoldLoanArticleOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Gold Loan Balance Transfer: Save on Interest Rates and EMI Payments",
      pageDescription:
        "Discusses the benefits and steps involved in transferring an existing gold loan to another lender.",
      pageKeywords:
        "gold loan, balance transfer, save on interest rates, reduce EMI payments, gold loan transfer, lower gold loan interest, EMI reduction, gold-backed loan, financial solutions, gold loan refinancing, loan transfer benefits, EMI savings, gold loan options",

      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/gold-loan-balance-transfer",
    });
  }, [setMetadata]);
  return (
    <>
      <Helmet>
        <title>
          Gold Loan Balance Transfer: Save on Interest Rates and EMI Payments
        </title>
        <meta
          name="description"
          content="Discusses the benefits and steps involved in transferring an existing gold loan to another lender."
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpages"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/gold-loan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Gold Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Gold Loan Balance Transfer
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Gold Loan Balance Transfer: Save on Interest Rates and EMI Payments
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Discusses the benefits and steps involved in transferring an existing
          gold loan to another lender.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
        <div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Introduction:
          </div>

          <p
            style={{
              color: "#1F1F1F",
              fontWeight: 500,
              fontSize: 16,
              lineHeight: "24px",
            }}
            className="mt-2"
          >
            A Loan Against Property (LAP) is a secured loan where your property
            acts as collateral. While it offers a substantial borrowing limit
            and lower interest rates compared to unsecured loans, managing the
            loan effectively is crucial to avoid default and financial strain.
            Here are some practical tips to help existing borrowers manage their
            Loan Against Property (LAP).
          </p>

          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              1. What is a Gold Loan Balance Transfer?
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              A gold loan balance transfer involves transferring the outstanding
              balance of your gold loan from your current lender to a new lender
              offering a better deal, such as a lower interest rate or better
              terms. By transferring the loan, you could potentially reduce the
              overall cost of your loan, shorten your loan tenure, or decrease
              your EMI payments.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              This process works similarly to a balance transfer on credit
              cards, where the existing outstanding amount is paid off by the
              new lender, and you continue repaying the loan under the new
              terms.
            </p>
          </div>
          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              2. Why Should You Consider a Gold Loan Balance Transfer?
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              There are several compelling reasons why borrowers opt for a gold
              loan balance transfer:
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              a. Lower Interest Rates
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              One of the primary reasons for transferring a gold loan is to
              secure a lower interest rate. Different lenders offer varying gold
              loan rates, and your current lender might be charging higher
              interest than other financial institutions. By transferring your
              gold loan to a lender with better rates, you can save
              significantly on interest payments.
            </p>
            <p style={{ fontSize: "16px", fontWeight: 600 }} className="mt-4">
              For example:
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>Muthoot Finance Gold Loan Interest Rate</strong> may
                  be higher compared to <strong>SBI Gold Loan</strong>, and
                  transferring your loan to <strong>SBI</strong> could result in
                  a lower rate, reducing your monthly EMI.
                </div>
              </div>
            </div>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              b. Reduced EMI Payments
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              A balance transfer can also help reduce your EMI payments. By
              negotiating a longer loan tenure or securing a lower interest
              rate, you can decrease the amount you need to pay each month. This
              can ease your financial burden and improve your monthly cash flow.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              c. Better Loan Terms
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Some lenders may offer more favorable loan terms, such as flexible
              repayment options, lower processing fees, or no prepayment
              penalties. Transferring your loan could grant you access to these
              enhanced benefits, helping you manage your loan more effectively.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              d. Simplify Loan Management
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              If you have multiple gold loans with different lenders,
              transferring them to one lender can simplify your repayment
              process. You’ll only have to deal with a single lender, making it
              easier to track your payments and avoid missing deadlines.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              e. Shorter Loan Tenure
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              By transferring your loan to a lender with a lower interest rate,
              you could potentially shorten your loan tenure while keeping the
              EMI the same or even lower. This will help you pay off your loan
              faster and save money on interest in the long run.
            </p>
          </div>

          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              3. How to Transfer Your Gold Loan Balance
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              The process of transferring a gold loan balance is fairly
              straightforward. Here are the steps involved:
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 1: Check the Terms of Your Existing Loan
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Before transferring your loan, it&#39;s essential to review the
              terms of your current gold loan. Check the interest rate,
              outstanding loan amount, EMI structure, and loan tenure. This will
              help you compare the transfer options available and understand the
              potential savings.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 2: Compare Offers from Different Lenders
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Research various lenders, including banks and non-banking
              financial companies (NBFCs), to find the best balance transfer
              offer. Compare interest rates, loan tenures, processing fees, and
              additional charges. For example:
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>SBI Gold Loan</strong> may offer a lower interest rate
                  than <strong>Manappuram Gold Loan</strong>.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}
                >
                  <strong> HDFC Gold Loan</strong> might have more flexible
                  terms compared to <strong>ICICI Gold Loan</strong>.
                </div>
              </div>
            </div>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 3: Check Eligibility for Balance Transfer
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Each lender has its own eligibility criteria for a balance
              transfer. Ensure that you meet the requirements, such as a minimum
              loan amount, gold purity, and creditworthiness. Some lenders may
              also require you to pay an upfront fee for the transfer.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 4: Apply for the Transfer
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Once you&#39;ve selected a lender, you can apply for the gold loan
              balance transfer. You’ll need to provide the following documents:
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Your <strong>KYC (Know Your Customer) documents</strong>{" "}
                  (e.g., Aadhaar, PAN card)
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong> Gold assessment certificate </strong>for the pledged
                  gold
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Details of the existing loan (loan account number, outstanding
                  balance, EMI schedule)
                </div>
              </div>
            </div>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 5: Pay the Processing Fees and Complete the Formalities
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Lenders typically charge processing fees for transferring a gold
              loan, though this fee is generally lower than the one charged
              during the initial loan application. Once you’ve paid the
              processing fee and submitted the required documents, the lender
              will assess your eligibility and process the transfer.
            </p>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              Step 6: Transfer the Loan Amount
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Upon approval, the new lender will pay off your existing loan and
              take over the balance. Your previous lender will release the
              pledged gold, and you will begin repaying the loan under the new
              lender’s terms.
            </p>
          </div>
          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              4. Things to Keep in Mind When Considering a Gold Loan Balance
              Transfer
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              While a gold loan balance transfer can be beneficial, there are
              some important factors to consider before proceeding:
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fees:{" "}
                  </span>
                  Make sure to account for any processing fees charged by the
                  new lender. While the balance transfer could save you on
                  interest, processing fees could offset some of the savings.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>Loan Tenure: </strong> Be cautious about extending
                  your loan tenure to reduce EMI payments. While lower EMIs can
                  ease your monthly budget, a longer tenure could increase the
                  overall interest paid on the loan.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>Gold Revaluation: </strong> The new lender may revalue
                  the gold pledged for the loan. If the gold&#39;s market value
                  has changed since you took the loan, this could affect the
                  loan amount or interest rate offered.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>Prepayment Penalties: </strong>Ensure that the new
                  lender doesn’t impose prepayment penalties if you wish to pay
                  off the loan early.
                </div>
              </div>
            </div>
          </div>
          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              5. Examples of Gold Loan Balance Transfer Offers from Top Lenders
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Here’s an overview of the potential interest rates and offers for
              gold loan balance transfer from major lenders:
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    SBI Gold Loan Balance Transfer:{" "}
                  </span>
                  Transfer your existing gold loan to SBI and enjoy lower
                  interest rates starting at 9.50% per annum.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>ICICI Gold Loan Balance Transfer: </strong>ICICI
                  offers competitive rates starting at 9% per annum, making it
                  an attractive option for reducing your gold loan EMI.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>Muthoot Finance Gold Loan Transfer: </strong>Muthoot
                  Finance offers a streamlined balance transfer process with
                  flexible repayment options and competitive interest rates
                  starting at 11%.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <strong>HDFC Gold Loan Transfer: </strong>HDFC’s gold loan
                  balance transfer could result in reduced EMIs and interest
                  rates starting at 10.50% per annum.
                </div>
              </div>
            </div>
          </div>

          <div>
            <p style={{ fontSize: "24px", fontWeight: 600 }} className="mt-4">
              Conclusion{" "}
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              A gold loan balance transfer can be a smart financial move to
              reduce your loan’s interest burden and EMI payments. By
              transferring your loan to a lender offering better terms, you can
              enjoy the benefits of lower rates, flexible repayment options, and
              even a shorter tenure. However, it’s important to compare offers
              from different lenders, check for any hidden charges, and
              understand the overall cost of transferring the loan before making
              a decision.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Evaluate your current loan’s terms and consider a balance transfer
              as a way to manage your gold loan more efficiently and save on
              interest.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldLoanArticleOne;
