import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import tongueOut from "../../../Assets/Images/tongue-out.png";
import MetadataContext from "../../MetadataContext";

const LoanArticleFour = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Essential Tips for Securing Online Loans in India | Minemi",
      pageDescription:
        "Explore online loans in India – from payday loans to overdraft facilities. Compare rates from ICICI, HDFC, SBI & KreditBee. Secure the best deals today!",
      pageKeywords:
        "Online loans in India, Instant loans online, Payday loans in India ,Personal loan against credit card , Loan against shares India, Online secured loans, ICICI Bank loan against shares, SBI personal loan online, HDFC instant loan, KreditBee loans, Bajaj Finserv overdraft loan, Best online loans in India, Compare online personal loans, Instant approval loans, Digital loans in India, Short-term loans online, How to apply for online loans, Low-interest online loans, Loan disbursement process, Emergency loans online, RBI guidelines for online loans",
      canonicalUrl:
        "https://minemi.ai/loanpage/essential-tips-for-securing-online-loans",
    });
  }, [setMetadata]);
  return (
    <>
      <Helmet>
        <title>
          Essential Tips for Securing Online Loans in India | Minemi
        </title>
        <meta
          name="description"
          content="Explore online loans in India – from payday loans to overdraft facilities. Compare rates from ICICI, HDFC, SBI & KreditBee. Secure the best deals today!"
        />
        <meta
          name="keywords"
          content="Online loans in India, Instant loans online, Payday loans in India ,Personal loan against credit card , Loan against shares India, Online secured loans, ICICI Bank loan against shares, SBI personal loan online, HDFC instant loan, KreditBee loans, Bajaj Finserv overdraft loan, Best online loans in India, Compare online personal loans, Instant approval loans, Digital loans in India, Short-term loans online, How to apply for online loans, Low-interest online loans, Loan disbursement process, Emergency loans online, RBI guidelines for online loans"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>

      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Essential Tips for Securing Online Loans
            </Link>
          </p>
        </div>
        <div
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          <h1 style={{ fontSize: 40, background: "transparent" }}>
            Essential Tips for Securing Online Loans in India
          </h1>
        </div>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "24px",
          }}
        >
          Discover essential tips for securing online loans in India, including
          eligibility criteria, document requirements, and smart practices to
          ensure a safe and hassle-free borrowing experience.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontFamily: "'Archivo',sans-serif",
          }}
        >
          <h1 style={{ fontSize: 24 }}>
            Introduction: Essential Tips for Securing Online Loans in India
          </h1>
        </div>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Online loans have transformed the borrowing landscape in India, oering
          speed, convenience, and accessibility that traditional bank loans
          often lack. Whether you’re applying for a payday loan, a personal loan
          against your credit card, or secured loans like Loan Against Shares
          (LAS), knowing the ins and outs of online lending is crucial to making
          informed nancial decisions.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          With platforms like{" "}
          <strong>
            Fibe (formerly Early Salary), KreditBee, Bajaj Finserv,
          </strong>{" "}
          and banks like <strong>ICICI Bank and SBI </strong>oering instant loan
          products, customers have plenty of options. But how do you ensure you
          get the best deal without falling into debt traps or scams?
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          This guide will help you navigate the online loan market, understand
          the types of loans available, and choose the right product for your
          nancial needs.
        </div>

        <div
          style={{
            marginTop: 20,
            color: "#1F1F1F",
            fontFamily: "'Archivo',sans-serif",
          }}
        >
          <h1 style={{ fontSize: 24, padding: 0 }}>
            {" "}
            Understanding Online Loans
          </h1>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Archivo",
              paddingTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Online loans are nancial products oered through digital platforms by
            banks, NBFCs (Non-Banking Financial Companies), or ntech companies.
            They provide quick approvals, minimal documentation, and instant
            disbursal, making them ideal for emergencies or short-term needs.
          </p>
        </div>
        <div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            Unlike traditional loans, which often require physical visits and
            lengthy processing times, online loans can be applied for and
            approved from the comfort of your home.
          </p>
        </div>

        <div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <h1
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 24,
              }}
            >
              Types of Online Loans in India
            </h1>
            <h3
              style={{
                fontSize: 20,
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              1. Payday Loans / Short-Term Personal Loans
            </h3>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Lenders:{" "}
                </span>
                Fibe (EarlySalary), KreditBee, MoneyTap
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Amount:{" "}
                </span>
                ₹5,000 to ₹5 Lakh
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Tenure:{" "}
                </span>
                7 days to 24 months
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Interest Rate:{" "}
                </span>
                18% to 36% p.a.
              </div>
            </div>
          </div>
          <div className="pt-3">
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Use Case:{" "}
              </strong>
              Ideal for emergencies like medical bills or utility payments.
              These loans have high interest but quick disbursal.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Example:{" "}
              </strong>
              Amit used Fibe to cover his rent shortfall. He borrowed ₹30,000
              for a month at a 24% p.a. interest rate. The repayment was
              deducted directly from his next salary.
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <h3
              style={{
                fontSize: 20,
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              2. Insta Personal Loans (Bank-Led)
            </h3>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Lenders:{" "}
                </span>
                ICICI Bank Insta Loan, SBI PAPL (Pre-Approved Personal Loan),
                HDFC Bank
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Amount:{" "}
                </span>
                ₹50,000 to ₹40 Lakh
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Tenure:{" "}
                </span>
                12 to 60 months
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Interest Rate:{" "}
                </span>
                10% to 18% p.a.
              </div>
            </div>
          </div>
          <div className="pt-3">
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Use Case:{" "}
              </strong>
              Suitable for weddings, travel, and home renovations. Pre-approved
              based on your credit profile.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Example:{" "}
              </strong>
              Priya received a pre-approved loan oer from ICICI directly through
              her mobile app for ₹3 Lakh. She accepted and received the amount
              within 30 minutes.
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <h3
              style={{
                fontSize: 20,
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              3. Secured Loans (Loan Against Shares/Mutual Funds/Gold)
            </h3>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Lenders:{" "}
                </span>
                ICICI Bank, HDFC Bank, Bajaj Finserv
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Amount:{" "}
                </span>
                ₹50,000 to ₹20 Cr (based on security value)
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Tenure:{" "}
                </span>
                12 months (renewable annually)
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Interest Rate:{" "}
                </span>
                8% to 12% p.a.
              </div>
            </div>
          </div>
          <div className="pt-3">
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Use Case:{" "}
              </strong>
              Great for leveraging investments without liquidating assets. Lower
              interest rates than personal loans.
            </p>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h3
                style={{
                  fontSize: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                ICICI LAS Example:
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  Up to ₹20 Lakh for shares, ₹1 Cr for mutual funds
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  1 year, renewable annually
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    LTV Ratio:{" "}
                  </span>
                  50% for shares, 80% for mutual funds
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  Up to 2%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <h3
              style={{
                fontSize: 20,
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              4. Overdraft (OD) Loans
            </h3>
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Lenders:{" "}
                </span>
                Bajaj Finserv, HDFC Bank, SBI
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Amount:{" "}
                </span>
                ₹1 Lakh to ₹25 Lakh
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Tenure:{" "}
                </span>
                Flexible
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Interest Rate:{" "}
                </span>
                9% to 14% p.a.
              </div>
            </div>
          </div>
          <div className="pt-3">
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Use Case:{" "}
              </strong>
              Ideal for uncertain nancial needs. Pay interest only on the amount
              utilized.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong style={{ paddingRight: 5, fontSize: 18 }}>
                Example:{" "}
              </strong>
              Rajesh opted for Bajaj Finserv’s Flexi Loan to manage uctuating
              business expenses. His OD allowed him to borrow when needed and
              repay at his convenience.
            </p>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Steps to Apply for an Online Loan
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Check Your Credit Score:{" "}
              </span>
              A score above 750 improves approval chances. Use platforms like
              CIBIL or Experian to check your score.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Compare Loan Oers:{" "}
              </span>
              Use aggregators like Minemi to compare multiple lenders. Look for
              the lowest APR (Annual Percentage Rate).
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Review Loan Terms:{" "}
              </span>
              Pay attention to processing fees, late payment charges, and
              foreclosure penalties.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Apply Through Secure Channels:{" "}
              </span>
              Apply via ocial apps or websites. Avoid unsolicited calls or
              emails.
            </div>
          </div>
        </div>

        {/* minemi tip section */}
        {/* <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
            <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="d-flex gap-2 align-items-center "
              >
                <img
                  src="./images/tongue-out.png"
                  alt="minemi tip image"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "transparent",
                  }}
                >
                  MinEmi Tip
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  💡 Borrowers with long-standing relationships at their primary
                  bank are more likely to receive pre-approved oers. Maintaining
                  a clean repayment history and stable income improves
                  eligibility.
                </Typography>
              </div>
            </div>
          </div>
        </div> */}

        <div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Dos and Don’ts of Online Borrowing
          </div>
          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Dos:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Do choose reputed platforms:{" "}
                </span>
                Stick to RBI-regulated NBFCs or bank-backed lenders.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Do read the ne print:{" "}
                </span>
                sure transparency on fees and charges.
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Do use EMI calculators:{" "}
                </span>
                Plan your repayments before borrowing.
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Don’ts:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Don’t borrow beyond your means:{" "}
                </span>
                Stick to essentials and emergencies.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Don’t ignore repayment schedules:{" "}
                </span>
                Late payments hurt credit scores.
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Don’t fall for guaranteed approval scams:{" "}
                </span>
                Authentic lenders conduct credit checks.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h1 style={{ fontSize: 24 }}>
            Comparison Table: Interest Rates Across Loan Types
          </h1>
          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Loan Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Interest Rate (p.a.)
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Loan Amount
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Tenure
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                      borderTopRightRadius: 18,
                    }}
                  >
                    Top Lenders
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Payday Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    18% – 36%
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹5K – ₹5 Lakh
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    7 days – 24 months
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Fibe, KreditBee, MoneyTap
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Insta Personal Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    10% – 18%
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹50K – ₹40 Lakh
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    1 – 5 years
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ICICI, HDFC, SBI, Axis
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Loan Against Shares (LAS)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    8% – 12%
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹50K – ₹20 Cr
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    1 year (renewable)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ICICI, Bajaj Finserv, HDFC
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    OD Loans
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    9% – 14%
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹1 Lakh – ₹25 Lakh
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Flexible
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                    }}
                  >
                    Bajaj Finserv, SBI, HDFC
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            className="pt-5"
            style={{
              fontSize: 16,
              fontFamily: "'Archivo',sans-serif",
              lineHeight: "24px",
            }}
          >
            This table provides a quick overview of the dierent types of online
            loans available in India, helping you compare interest rates, loan
            amounts, tenures, and the top lenders oering these products.
          </p>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 22,
          }}
        >
          Regulatory Guidance and Trusted Quotes
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              fontWeight: 400,
              marginTop: "10px",
            }}
          >
            According to the Reserve Bank of India (RBI), borrowers should
            ensure that digital lending platforms are linked to regulated
            entities. This protects against fraud and ensures ethical practices.
          </p>
        </div>

        {/* minemi tip section */}
        <div
          className="tips mt-5 mb-3"
          style={{
            backgroundColor: "#e7e4f9",
            border: "1px solid #8a8a8a",
            borderTop: "0px",
          }}
        >
          <div
            id="border_line"
            style={{
              width: "100%",
              height: "5px",
              backgroundColor: "#59266d",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
            }}
          ></div>
          <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
            <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="d-flex gap-2 align-items-center "
              >
                <img
                  src={tongueOut}
                  alt="minemi tip image"
                  loading="lazy"
                  style={{ width: 30, height: 30, backgroundColor: "#e7e4f9" }}
                />
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#59266D",
                    // color: "yellow",
                    fontFamily: "'Archivo', sans-serif",
                    backgroundColor: "transparent",
                  }}
                >
                  Expert Quote
                </Typography>
              </div>
              <div className="mt-2 ">
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: "24px",
                    letterSpacing: "0.08px",
                    color: "#1F1F1F",
                    // color: "#F6F6F6",
                    backgroundColor: "#e7e4f9",

                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  <em style={{ background: "transparent" }}>
                    “Borrow responsibly, and ensure full transparency on terms.
                    A well-informed borrower minimizes nancial risks.” –
                  </em>
                  <strong style={{ background: "transparent" }}>
                    Ahil Shetty, CEO of BankBazaar
                  </strong>
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Conclusion: Choose Wisely
          </div>
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              marginTop: "10px",
              lineHeight: "24px",
            }}
          >
            Online loans are a convenient tool but require careful
            consideration. Assess your needs, compare rates, and borrow only
            from trusted sources. Platforms like Minemi help borrowers nd the
            best rates and products suited to their needs.
          </p>
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              marginTop: "10px",
              lineHeight: "24px",
            }}
          >
            <strong>Apply smart, borrow wise.</strong>
          </p>
        </div>

        {/* <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              How long does it take for my car loan application to be approved?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Typically, car loan approval can take anywhere from 24 to 72 hours
              depending on the lender. Some banks offer quicker processing,
              especially if you apply through online portals.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Can I track the loan status if I applied offline?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, even if you applied offline, you can track your loan status
              by calling the bank’s customer service or using their mobile app.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What should I do if my loan application is delayed?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              If there is a delay in the loan process, contact the bank’s
              customer support for assistance. They will provide you with the
              reasons for the delay and the estimated time of approval.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How can I track my EMI payments for my car loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              You can track your EMI payments via the bank’s mobile app, online
              portal, or by checking your monthly bank statements.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div> */}
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 64,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  backgroundColor: "#553285",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanArticleFour;
