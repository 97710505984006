import React from "react";
import "./OurServices.css";
import OurServiceLower from "../../Assets/Images/OurServiceLower.webp";
import OurServiceConsolidate from "../../Assets/Images/OurServiceConsolidate.webp";
import OurServiceTopUp from "../../Assets/Images/OurServiceTopUp.webp";

import arrow from "../../Assets/Images/arrow.svg";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink as RouterHashLink } from "react-router-hash-link";
import { Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const OurServices = () => {
  const navigate = useNavigate();
  return (
    <div className="our_service_container">
      <div className="d-flex w-100 justify-content-center">
        <div className="ServicesTitle">
          <h3 className="ServiceHeading ">
            How would you like to save your money?
          </h3>
          <p className="SubHeading ">
            Discover smart financial solutions tailored to your needs. Whether
            it's balance transfers, loan consolidation, or flexible top-ups,
            we've got you covered.
          </p>
        </div>
      </div>
      {/* <div className="Cards">
        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1>Balance transfer</h1>
            <p>
              Take control of your finances with our smart solutions. Enjoy the
              freedom of lower monthly payments and save more with our optimized
              payment plans.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Lowest Interest Rates</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Boost Savings</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Flexible Terms</p>
            </div>
          </div>
          <RouterHashLink to="#DownloadApp" className="LowerEmiCta">
            <p>Get Started</p>
            <RouterHashLink
              to="#DownloadApp"
              style={{ backgroundColor: "transparent" }}
            >
              <img src={arrow} alt="" />
            </RouterHashLink>
          </RouterHashLink>
          <div className="OurServiceLower">
            <img src={OurServiceLower} alt="" />
          </div>
        </div>
        <div className="TwoCard">
          <div className="CardOne">
            <div style={{ backgroundColor: "transparent", width: "340px" }}>
              <div className="ConsolidateCardHeading">
                <h1>Loan Consolidation</h1>
                <p>
                  Streamline your finances with our one-stop EMI solution.
                  Experience hassle-free management of your loans with a single
                  monthly payment.
                </p>
              </div>
              <div className="ConsolidatePoints">
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Single EMI</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Savings Growth</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Flexible Options</p>
                </div>
              </div>
              <RouterHashLink className="LowerEmiCta" to="#DownloadApp">
                <p>Get Started</p>
                <RouterHashLink
                  to="#DownloadApp"
                  style={{ backgroundColor: "transparent" }}
                >
                  <img src={arrow} alt="" />
                </RouterHashLink>
              </RouterHashLink>
            </div>

            <div className="OurServiceConsolidate">
              <img src={OurServiceConsolidate} alt="" />
            </div>
          </div>

          <div className="CardTwo">
            <div style={{ backgroundColor: "transparent", width: "340px" }}>
              <div className="ConsolidateCardHeading">
                <h1>Top-up Running Loan</h1>
                <p>
                  Unlock the full potential of your financial resources with our
                  easy loan top-up service. Increase your loan amount
                  effortlessly and enjoy greater financial flexibility.
                </p>
              </div>
              <div className="ConsolidatePoints">
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Single EMI</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Savings Growth</p>
                </div>
                <div className="PointOne">
                  <FaCheckCircle
                    color="rgba(255, 255, 255, 1)"
                    style={{ backgroundColor: "transparent" }}
                    size={20}
                  />
                  <p>Flexible Options</p>
                </div>
              </div>
              <RouterHashLink to="#DownloadApp" className="LowerEmiCta">
                <p style={{ marginTop: "-7%" }}>Get Started</p>
                <RouterHashLink
                  to="#DownloadApp"
                  style={{ backgroundColor: "transparent", marginTop: "-7%" }}
                >
                  <img src={arrow} alt="" />
                </RouterHashLink>
              </RouterHashLink>
            </div>

            <div className="OurServiceTopUp">
              <img src={OurServiceTopUp} alt="" />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="CardMobile">
        <div className="d-flex flex-column flex-md-row gap-4">
          <div className="  overflow-hidden bg-[#8B5CF6] text-white">
            <div className="LowerCardHeading">
              <h1 style={{ marginBottom: "2%" }}>Balance transfer</h1>
              <p>
                Take control of your finances with our smart solutions. Enjoy
                the freedom of lower monthly payments and save more with our
                optimized payment plans.
              </p>
            </div>
            <div className="lowerKeyPoints">
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Lowest Interest Rates</p>
              </div>
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Boost Savings</p>
              </div>
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Flexible Terms</p>
              </div>
            </div>
            <Link
              to="/login"
              className="LowerEmiCta"
              onClick={() => {
                console.log("Link clicked");
                window.scrollTo(0, 0);
              }}
            >
              <p>Get Started</p>
              <Link
                to="/login"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  console.log("Link clicked");
                  window.scrollTo(0, 0);
                }}
              >
                <img src={arrow} alt="" />
              </Link>
            </Link>
           
          </div>
          <div className="LowerCard">
            <div className="LowerCardHeading">
              <h1 style={{ marginBottom: "2%", lineHeight: "40px" }}>
                Loan Consolidation
              </h1>
              <p>
                Streamline your finances with our one-stop EMI solution.
                Experience hassle-free management of your loans with a single
                monthly payment.
              </p>
            </div>
            <div className="lowerKeyPoints">
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Single EMI</p>
              </div>
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Savings Growth</p>
              </div>
              <div className="PointOne">
                <FaCheckCircle
                  color="rgba(255, 255, 255, 1)"
                  style={{ backgroundColor: "transparent" }}
                  size={20}
                />
                <p>Flexible Options</p>
              </div>
            </div>
            <Link
              to="/login"
              className="LowerEmiCta"
              onClick={() => {
                console.log("Link clicked");
                window.scrollTo(0, 0);
              }}
            >
              <p>Get Started</p>
              <Link
                to="/login"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  console.log("Link clicked");
                  window.scrollTo(0, 0);
                }}
              >
                <img src={arrow} alt="" />
              </Link>
            </Link>
            
          </div>
        </div>

        <div className="LowerCard">
          <div className="LowerCardHeading">
            <h1 style={{ marginBottom: "2%" }}>Top-up Running Loan</h1>
            <p>
              Unlock the full potential of your financial resources with our
              easy loan top-up service. Increase your loan amount effortlessly
              and enjoy greater financial flexibility.
            </p>
          </div>
          <div className="lowerKeyPoints">
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>East access to additional funds</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Maximize Savings</p>
            </div>
            <div className="PointOne">
              <FaCheckCircle
                color="rgba(255, 255, 255, 1)"
                style={{ backgroundColor: "transparent" }}
                size={20}
              />
              <p>Diverse Financial Solutions</p>
            </div>
          </div>
          <Link
            to="/login"
            className="LowerEmiCta"
            onClick={() => {
              console.log("Link clicked");
              window.scrollTo(0, 0);
            }}
          >
            <p>Get Started</p>
            <Link
              to="/login"
              style={{ backgroundColor: "transparent" }}
              onClick={() => {
                console.log("Link clicked");
                window.scrollTo(0, 0);
              }}
            >
              <img src={arrow} alt="" />
            </Link>
          </Link>
        
        </div>
      </div> */}

      <div className="container features-grid mt-4 mt-sm-5">
        <div className="row">
          {/* Balance Transfer Card */}
          <div className="col-md-6 col-lg-4 box">
            <div
              className="feature-card primary"
              // style={{ backgroundColor: "transparent" }}
            >
              <div
                className="feature-content"
                style={{ backgroundColor: "transparent" }}
              >
                <h4
                  className="feature-title"
                  style={{ backgroundColor: "transparent" }}
                >
                  Balance transfer
                </h4>
                <p
                  className="feature-description"
                  style={{ backgroundColor: "transparent" }}
                >
                  Take control of your finances with our smart solutions. Enjoy
                  the freedom of lower monthly payments and save more with our
                  optimized payment plans.
                </p>
                <ul
                  className="feature-list"
                  style={{ backgroundColor: "transparent" }}
                >
                  {[
                    "Lowest Interest Rates",
                    "Boost Savings",
                    "Flexible Terms",
                  ].map((feature) => (
                    <li
                      key={feature}
                      className="feature-item"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <CheckCircle style={{ backgroundColor: "transparent" }} />
                      <span style={{ backgroundColor: "transparent" }}>
                        {feature}
                      </span>
                    </li>
                  ))}
                </ul>
                {/* <button
                  className="get-started-btn"
                  style={{ backgroundColor: "transparent" }}
                >
                  Get Started →
                </button> */}

                <Button
                  to="/login"
                  className="LowerEmiCta"
                  sx={{
                    color: "#1f1f1f", // Text color
                    backgroundColor: "#ffffff", // Background color
                    border: "1px solid #1f1f1f", // Optional border
                    padding: "10px 20px", // Padding for size
                    fontSize: "16px", // Font size
                    borderRadius: "5px", // Optional rounded corners
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    console.log("Link clicked");
                    navigate("/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  <p>Get Started</p>
                  <Link
                    to="/login"
                    onClick={() => {
                      console.log("Link clicked");
                      window.scrollTo(0, 0);
                    }}
                    style={{ zIndex: 1000 }}
                  >
                    <img src={arrow} alt="Get Started" loading="lazy" />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          {/* Loan Consolidation Card */}
          <div className="col-md-6 col-lg-4 mt-4 mt-md-0">
            <div className="feature-card secondary">
              <div
                className="feature-content"
                style={{ backgroundColor: "transparent" }}
              >
                <h4
                  className="feature-title"
                  style={{ backgroundColor: "transparent" }}
                >
                  Loan Consolidation
                </h4>
                <p
                  className="feature-description"
                  style={{ backgroundColor: "transparent" }}
                >
                  Streamline your finances with our one-stop EMI solution.
                  Experience hassle-free management of your loans with a single
                  monthly payment.
                </p>
                <br />
                <ul
                  className="feature-list"
                  style={{ backgroundColor: "transparent" }}
                >
                  {["Single EMI", "Savings Growth", "Flexible Options"].map(
                    (feature) => (
                      <li
                        key={feature}
                        className="feature-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <CheckCircle
                          style={{ backgroundColor: "transparent" }}
                        />
                        <span style={{ backgroundColor: "transparent" }}>
                          {feature}
                        </span>
                      </li>
                    )
                  )}
                </ul>
                <Button
                  to="/login"
                  className="LowerEmiCta"
                  sx={{
                    color: "#1f1f1f", // Text color
                    backgroundColor: "#ffffff", // Background color
                    border: "1px solid #1f1f1f", // Optional border
                    padding: "10px 20px", // Padding for size
                    fontSize: "16px", // Font size
                    borderRadius: "5px", // Optional rounded corners
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    console.log("Link clicked");
                    navigate("/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  <p>Get Started</p>
                  <Link
                    to="/login"
                    onClick={() => {
                      console.log("Link clicked");
                      window.scrollTo(0, 0);
                    }}
                    style={{ zIndex: 1000 }}
                  >
                    <img src={arrow} alt="Get Started" loading="lazy" />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          {/* Top-up Running Loan Card */}
          <div className="col-lg-4  mt-4 mt-lg-0">
            <div className="feature-card tertiary">
              <div
                className="feature-content"
                style={{ backgroundColor: "transparent" }}
              >
                <h4
                  className="feature-title"
                  style={{ backgroundColor: "transparent" }}
                >
                  Top-up Running Loan
                </h4>
                <p
                  className="feature-description"
                  style={{ backgroundColor: "transparent" }}
                >
                  Unlock the full potential of your financial resources with our
                  easy loan top-up service. Increase your loan amount
                  effortlessly and enjoy greater financial flexibility.
                </p>
                <ul
                  className="feature-list"
                  style={{ backgroundColor: "transparent" }}
                >
                  {["Single EMI", "Savings Growth", "Flexible Options"].map(
                    (feature) => (
                      <li
                        key={feature}
                        className="feature-item"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <CheckCircle
                          style={{ backgroundColor: "transparent" }}
                        />
                        <span style={{ backgroundColor: "transparent" }}>
                          {feature}
                        </span>
                      </li>
                    )
                  )}
                </ul>
                <Button
                  to="/login"
                  className="LowerEmiCta"
                  sx={{
                    color: "#1f1f1f", // Text color
                    backgroundColor: "#ffffff", // Background color
                    border: "1px solid #1f1f1f", // Optional border
                    padding: "10px 20px", // Padding for size
                    fontSize: "16px", // Font size
                    borderRadius: "5px", // Optional rounded corners
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    console.log("Link clicked");
                    navigate("/login");
                    window.scrollTo(0, 0);
                  }}
                >
                  <p>Get Started</p>
                  <Link
                    to="/login"
                    onClick={() => {
                      console.log("Link clicked");
                      window.scrollTo(0, 0);
                    }}
                    style={{ zIndex: 1000 }}
                  >
                    <img src={arrow} alt="Get Started" loading="lazy" />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
