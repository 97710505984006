import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../MetadataContext";

const LoanPage = () => {
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery("(max-width:770px)");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  // console.log(newsData);
  const [manageData, setManageData] = useState([]);
  const blogs = [
    {
      id: 1,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Pre-Approval-Loan-1737196070693.webp",
      title: "How to Get Pre-Approved for a Loan: A Step-by-Step Guide",
      writer: "Vikalp Shukla",
      description:
        "Learn how to secure pre-approval for a loan with this step-by-step guide, helping you streamline the borrowing process and improve your chances of approval.",
      naviLink: "/loanpage/loan-get-pre-approved",
    },
    {
      id: 2,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/LoanInterestRate-1737196371555.webp",
      title:
        "Loan Interest Rate Comparison: How to Choose the Right Loan for Your Needs",
      writer: "Vikalp Shukla",
      description:
        "Compare loan interest rates from different lenders to find the best option for your needs. Learn how to evaluate loan terms, EMI, and total repayment to make an informed financial decision.",
      naviLink: "/loanpage/loan-interest-rate-comparison",
    },

    {
      id: 3,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/FactorsHL-1737196543887.webp",
      title:
        "7 Key Factors Lenders Consider Before Approving Your Loan Application in India",
      writer: "Vikalp Shukla",
      description:
        "Learn the 7 critical factors Indian lenders evaluate for home, car, and personal loans.Improve your chances by managing your credit score, income, debt-to-income ratio, and collateral.",
      naviLink:
        "/loanpage/7-key-factors-lenders-consider-before-approving-your-loan-application-in-india",
    },
    {
      id: 4,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Essential%20tips-1737196769322.webp",
      title: "Essential Tips for Securing Online Loans in India",
      writer: "Aditi Malhotra",
      description:
        "Discover essential tips for securing online loans in India, including eligibility criteria, document requirements, and smart practices to ensure a safe and hassle-free borrowing experience.",
      naviLink: "/loanpage/essential-tips-for-securing-online-loans",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.manageloan);
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  // For Meta tag  Keywords

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Loan",
      pageDescription:
        "Explore a wide range of loans tailored to your needs: Personal Loans, Home Loans, Car Loans, Digital Loans, and Gold Loans. Quick approvals, low interest rates, and flexible repayment options await!",
      pageKeywords:
        "loans, personal loan, home loan, car loan, digital loan, gold loan, low interest loans, flexible repayment loans, quick loan approval, best loans online, loan options, affordable loans, easy loans",

      canonicalUrl: "https://minemi.ai/loanpage/",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Loan</title>
        <meta
          name="description"
          content="Explore a wide range of loans tailored to your needs: Personal Loans, Home Loans, Car Loans, Digital Loans, and Gold Loans. Quick approvals, low interest rates, and flexible repayment options await!"
        />
        <meta
          name="keywords"
          content="loans, personal loan, home loan, car loan, digital loan, gold loan, low interest loans, flexible repayment loans, quick loan approval, best loans online, loan options, affordable loans, easy loans"
        />
        <link rel="canonical" href="https://www.minemi.ai/loanpage" />
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Loans
            </Link>
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "46px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Loans
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 24,

            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "30px",
          }}
        >
          Explore a range of tailored loan options to meet your needs. Choose
          from Personal Loans, Home Loans, or Car Loans and start your journey
          towards financial freedom.
        </p>
      </div>
      <div
        className="make-wrap"
        style={{
          marginTop: 56,
          marginLeft: "4%",
          marginRight: "4%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 16,
        }}
      >
        <div
          onClick={() => navigate("/loanpage/personalloan")}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 10,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Personal Loan
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Compare & Choose the Best Personal Loans.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={money}
                alt="Personal Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 13,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Easily find personal loans from top banks and NBFCs.
            </p>
          </p>
        </div>
        <div
          onClick={() => navigate("/loanpage/carloan")}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
            cursor: "pointer",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Car Loan
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Car Loan Simplified – Drive Home Faster
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Ratio}
                alt="Car Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Get tailored car loan offers from multiple lenders.
            </p>
          </p>
        </div>
        <div
          onClick={() => navigate("/loanpage/homeloan")}
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Home Loan
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Best Home Loan Deals Made Easy
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Group}
                alt="Home Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Compare home loans and secure low-interest rates easily.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          onClick={() => navigate("/loanpage/digital-loan")}
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Digital Loans
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            Apply Now for All Digital Loan Options!
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={creditScore}
                alt="Digital Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Access digital loans from multiple providers instantly.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          onClick={() => {
            navigate("/loanpage/gold-loan");
          }}
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 16,
            }}
          >
            Gold Loan
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 13,
            }}
          >
            {" "}
            Best Gold Loan Offers – Compare & Apply
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Vector}
                alt="Gold Loan"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Check gold loan options across top banks and lenders.
            </p>
          </p>
        </div>
      </div>
      <div
        style={{
          maginTop: 64,
          marginLeft: "4%",
          marginRight: "4%",
          marginTop: 32,
        }}
      >
        <h2
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          Qualify for a Loan
        </h2>
      </div>
      <div
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
        className="loan-list-container px-0"
      >
        {blogs?.map((blog) => (
          <div
            className="loan-container"
            onClick={() => navigate(blog.naviLink)}
            key={blog.id}
          >
            <a href={blog.link}>
              <img
                src={blog.image}
                alt={blog.heading}
                loading="lazy"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                className="loan-image d-none d-md-block"
              />
              <h3
                className="loan-heading"
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 600,
                  fontSize: 18,
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              >
                {blog.title}
              </h3>
              <p
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  color: "#8a8a8a",
                  fontSize: 14,
                }}
                className="mt-2 mb-1"
              >
                by {blog.writer}
              </p>
              <div
                className="loan-subheading"
                dangerouslySetInnerHTML={{
                  __html: blog.description,
                }}
                style={{
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 18,
                  color: "#B1B1B1",
                  display: "-webkit-box",
                  WebkitLineClamp: 2, // Limit to 2 lines
                  WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                  overflow: "hidden", // Hide overflowing text
                  textOverflow: "ellipsis", // Add ellipsis
                }}
              />
            </a>
          </div>
        ))}
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          Manage your loan
        </h3>
        <div style={{ marginTop: 40, marginBottom: 32 }}>
          <Grid container spacing={5}>
            {manageData
              .filter((d) => d.category === "Loan")
              .slice(0, 1) // Adjust slice range based on your needs
              .map((d) => {
                return (
                  <Grid item xs={12} md={6}>
                    <Item style={{ border: 0, boxShadow: "0px 0px" }}>
                      <Link to={`/loanpage/manageloan_detail/${d.mslug}`}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                          }}
                        >
                          <div style={{ height: "auto" }}>
                            <img
                              src={d.image}
                              alt={d?.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: isVerySmallScreen ? "100%" : "400px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            <p
                              className="manage_latest_link"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d?.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d?.author}
                            </p>

                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: 1.3,
                                color: "grey",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  d?.description && d.description.length > 100
                                    ? `${d.description.slice(0, 100)}...`
                                    : d?.description || "",
                              }}
                            ></p>
                          </div>
                        </div>
                      </Link>
                    </Item>
                  </Grid>
                );
              })}

            <Grid item xs={12} md={6}>
              <Item
                style={{
                  border: 0,
                  boxShadow: "0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 32,
                  backgroundColor: "#F6F6F6",
                }}
              >
                {manageData
                  .filter((d) => d.category === "Loan")
                  .slice(1)
                  .map((d) => (
                    <div key={d.id}>
                      {d.category === "Loan" && (
                        <Link
                          to={`/loanpage/manageloan_detail/${d.mslug}`}
                          state={{
                            mslug: d.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 12,
                            }}
                          >
                            <div
                              style={{
                                // width: "100%",
                                minWidth: isVerySmallScreen
                                  ? 80
                                  : isMediumScreen
                                  ? 120
                                  : 150,
                                maxWidth: 190,
                              }}
                            >
                              <img
                                src={d.image}
                                alt={d.title}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  // maxWidth: "250px",
                                  height: isVerySmallScreen
                                    ? "50px"
                                    : isMediumScreen
                                    ? "80px"
                                    : "110px",
                                  objectFit: "cover",

                                  borderRadius: "5px",
                                  objectPosition: "center",
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 3,
                              }}
                            >
                              <p
                                className="manage_latest_link m-0 pt-0 pt-sm-3"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d.author}
                              </p>
                              {/* <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  d?.description && d.description.length > 90
                                    ? `${d.description.slice(0, 90)}...`
                                    : d?.description || "",
                              }}
                            ></p> */}
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 40 }}>
        <h3
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          The Latest
        </h3>
        <Grid container spacing={4} style={{ marginTop: "2%" }}>
          {newsData
            .filter((item) => item.category === "Loan")
            .map((item) => (
              <Grid item xs={12} sm={6} key={item.id}>
                {item.category === "Loan" && (
                  <Item style={{ boxShadow: "0px 0px", marginBottom: 10 }}>
                    <Link
                      to={`/loanpage/news_detail/${item.nslug}`}
                      state={{
                        nslug: item.nslug,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 12,
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            // width: "100%",
                            minWidth: isVerySmallScreen
                              ? 80
                              : isMediumScreen
                              ? 120
                              : 150,
                            maxWidth: 190,
                          }}
                        >
                          <img
                            src={item.image}
                            alt={item.title}
                            loading="lazy"
                            style={{
                              width: "100%",
                              // maxWidth: "250px",
                              height: isVerySmallScreen
                                ? "50px"
                                : isMediumScreen
                                ? "80px"
                                : "110px",
                              objectFit: "cover",

                              borderRadius: "5px",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 5,
                            alignSelf: "center",
                          }}
                        >
                          <p
                            style={{ fontSize: 18, fontWeight: 400 }}
                            className=" manage_latest_link m-0"
                          >
                            {item.title}
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "grey",
                            }}
                            className="m-0"
                          >
                            by {item.author}
                          </p>
                          {/* <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: 1.3,
                              color: "grey",
                              display: "-webkit-box",
                              WebkitLineClamp: 2, // Limit to 2 lines
                              WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p> */}
                        </div>
                      </div>
                    </Link>
                  </Item>
                )}
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
};

export default LoanPage;
