import React from "react";

function BenefitCard({ icon, title, description }) {
  return (
    <div className="flex flex-col items-center text-center p-6 bg-[#EBE8FC] rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <div className="mb-4 text-[#59266d] bg-[#EBE8FC]">{icon}</div>
      <h3
        className="text-xl text-[#59266d]  font-semibold bg-[#EBE8FC] mb-2"
        style={{ fontSize: "20px", fontWeight: "600" }}
      >
        {title}
      </h3>
      <p
        className=" text-center mt-2 bg-[#EBE8FC]"
        style={{
          fontSize: "16px",
          fontWeight: "lighter",
          lineHeight: "17px",
          color: "#4B5563",
        }}
      >
        {description}
      </p>
    </div>
  );
}

export default BenefitCard;
